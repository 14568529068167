import { getStream } from 'api/streamApi';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import { useParams } from 'react-router-dom';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { isEmpty } from 'lodash';

export type StreamDataContextValue = {
  enabledAnalytics: AnalyticsTypes[] | null;
  vpaStreamId: string;
  selectedAnalytics: AnalyticsTypes | null;
  setSelectedAnalytics: Dispatch<SetStateAction<AnalyticsTypes | null>>;
  analyticsLoading: boolean;
};

export type StreamDataProviderProps = {
  children: ReactNode;
};

const defaultValue: StreamDataContextValue = {
  enabledAnalytics: [],
  vpaStreamId: '',
  selectedAnalytics: null,
  setSelectedAnalytics: () => {},
  analyticsLoading: true,
};

const StreamDataContext = createContext(defaultValue);

const StreamDataProvider: FC<StreamDataProviderProps> = (
  props: StreamDataProviderProps
) => {
  const { streamId } = useParams<{ streamId: string }>();
  const [enabledAnalytics, setEnabledAnalytics] = useState(null);
  const [vpaStreamId, setVpaStreamId] = useState(defaultValue.vpaStreamId);
  const [selectedAnalytics, setSelectedAnalytics] = useState(
    defaultValue.selectedAnalytics
  );
  const [analyticsLoading, setAnalyticsLoading] = useState(
    defaultValue.analyticsLoading
  );

  useEffect(() => {
    if (localStorage.getItem('analyticsPerStream')) return;

    localStorage.setItem('analyticsPerStream', JSON.stringify({}));
  }, []);

  useEffect(() => {
    if (!streamId) return;

    getStream(streamId).then((data) => {
      setEnabledAnalytics(data.enabledAnalytics);
      setVpaStreamId(data.vpaStreamId);
    });
  }, [streamId]);

  useEffect(() => {
    if (!streamId) return;

    if (isEmpty(enabledAnalytics)) {
      setSelectedAnalytics(null);
      setAnalyticsLoading(false);
      return;
    }

    const savedAnalytics = localStorage.getItem('analyticsPerStream');
    if (!savedAnalytics && enabledAnalytics) {
      setSelectedAnalytics(enabledAnalytics[0]);
      setAnalyticsLoading(false);
      return;
    }
    const parsed = savedAnalytics && JSON.parse(savedAnalytics);

    if (parsed[streamId]) {
      setSelectedAnalytics(parsed[streamId]);
    } else if (enabledAnalytics) {
      setSelectedAnalytics(enabledAnalytics[0]);
    }
    setAnalyticsLoading(false);
  }, [enabledAnalytics, streamId]);

  return (
    <StreamDataContext.Provider
      value={{
        enabledAnalytics,
        vpaStreamId,
        selectedAnalytics,
        setSelectedAnalytics,
        analyticsLoading,
      }}
      {...props}
    />
  );
};

const useStreamData = () => useContext(StreamDataContext);
export { StreamDataProvider, StreamDataContext, useStreamData };
