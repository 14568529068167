import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { FallDetectionMetaData } from 'views/StreamDetails/Preview/Analytics/FallDetection/FallDetectionMetaData';
import { FireSmokeMetaData } from 'views/StreamDetails/Preview/Analytics/FireSmoke/FireSmokeMetaData';
import { AnalyticsMetaData } from 'types/analyticsTypes';
import { FireSmokeStreamData } from 'types/analytics/fire/FireSmoke';
import { FallStreamData } from 'types/analytics/FallDetection';
import { PPEStreamData, PPESettings } from 'types/analytics/PPE';
import { PPEMetadata } from 'views/StreamDetails/Preview/Analytics/PPE/PPEMetadata';
import { FlareInspectionMetaData } from 'views/StreamDetails/Preview/Analytics/FlareInspection/FlareInspectionMetaData';
import { useAnalyticsSettings } from 'context/providers/AnalyticsSettingsProvider';
import { FlareInspectionStreamData } from 'types/analytics/fire/FlareInspection';
import { IntruderStreamData } from 'types/analytics/IntruderDetection';
import { useStreamData } from 'context/providers/StreamDataProvider';
import { IntruderDetectionMetaData } from 'views/StreamDetails/Preview/Analytics/IntruderDetection/IntruderDetectionMetaData';

type AnalyticsDataProps = {
  metaData: AnalyticsMetaData | undefined;
};

export const AnalyticsData = ({ metaData }: AnalyticsDataProps) => {
  const { selectedAnalytics } = useStreamData();
  const { analyticsSettings } = useAnalyticsSettings();

  const renderSwitch = () => {
    switch (selectedAnalytics) {
      case AnalyticsTypes.FallDetection:
        const fallData = metaData as FallStreamData;
        return <FallDetectionMetaData metaData={fallData} />;
      case AnalyticsTypes.FireSmokeTunnel:
      case AnalyticsTypes.FireSmoke:
        const fireData = metaData as FireSmokeStreamData;
        return <FireSmokeMetaData metaData={fireData} />;
      case AnalyticsTypes.PPE:
        const ppeData = metaData as PPEStreamData;
        const ppeSettings = analyticsSettings as PPESettings;
        return <PPEMetadata metaData={ppeData} settings={ppeSettings} />;
      case AnalyticsTypes.FlareInspection:
        const flareInspectionData = metaData as FlareInspectionStreamData;
        return <FlareInspectionMetaData metaData={flareInspectionData} />;
      case AnalyticsTypes.IntruderDetection:
        const intruderDetectionMetaData = metaData as IntruderStreamData;
        return (
          <IntruderDetectionMetaData metaData={intruderDetectionMetaData} />
        );
    }
  };

  return <>{renderSwitch()}</>;
};
