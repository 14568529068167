import { makeStyles, TableCell, TableCellProps } from '@material-ui/core';
import clsx from 'clsx';

type HeadCellProps = TableCellProps & {
  sortable?: boolean;
};

const HeadCell = ({ className, ...props }: HeadCellProps) => {
  const classes = useStyles();

  return <TableCell className={clsx(className, classes.headCell)} {...props} />;
};

const useStyles = makeStyles((theme) => ({
  headCell: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16px',
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(1, 1, 1, 2),
    height: '50px',
    color: theme.palette.background.default,

    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '&:first-child': {
      borderRadius: '5px 0px 0px 5px',
    },
    '&:last-child': {
      borderRadius: '0px 5px 5px 0px',
    },
  },
}));

export default HeadCell;
