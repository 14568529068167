import { TextField, makeStyles } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';

type TextFieldBaseProps = TextFieldProps & {
  maxLength?: number;
};

const TextFieldBase = ({
  helperText,
  id,
  maxLength,
  ...props
}: TextFieldBaseProps) => {
  const classes = useStyles();

  return (
    <TextField
      autoComplete="off"
      fullWidth
      id={id}
      classes={{
        root: classes.textField,
      }}
      inputProps={{ maxLength: maxLength, className: classes.input }}
      InputLabelProps={{
        classes: {
          root: classes.label,
          focused: 'focused',
        },
      }}
      helperText={helperText}
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: theme.palette.background.default,
    height: '48px',

    '& .MuiInput-underline:before': {
      borderBottomColor: theme.palette.primary.light,
    },
    '& .MuiInput-underline:not(.Mui-disabled):hover::before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
    '& .MuiInputBase-root.Mui-disabled:before': {
      borderBottom: '1px solid',
    },
    '@media (max-width:810px)': {
      height: '56px',
    },
  },
  label: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    '&.Mui-disabled': {
      color: theme.palette.grey[600],
    },
    '&.focused:not(.Mui-error)': {
      color: theme.palette.primary.main,
    },
  },
  input: {
    fontWeight: 400,
    color: theme.palette.text.primary,

    '&:disabled': {
      color: theme.palette.grey[200],
    },
  },
}));

export default TextFieldBase;
