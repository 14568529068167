import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ResetButton } from 'components/KonvaShapes/Polygon/ResetButton';
import { Polygon } from 'components/KonvaShapes/Polygon/PolygonZones';
import { KonvaRectangle } from 'components/KonvaShapes/FreeFormRectangle/FreeFormRectangle';

type ResetOptionsProps = {
  handleRemoveAll: () => void;
  handleRemoveSelected: () => void;
  zones: Polygon[] | KonvaRectangle[];
  selectedZones: number[];
};

export const ResetOptions = ({
  handleRemoveAll,
  handleRemoveSelected,
  zones,
  selectedZones,
}: ResetOptionsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <ResetButton
        onClick={handleRemoveSelected}
        label={t('stream-settings.remove-selected')}
        disabled={isEmpty(selectedZones)}
        id="remove-selected-zones"
      />
      <ResetButton
        onClick={handleRemoveAll}
        label={t('stream-settings.remove-all')}
        disabled={isEmpty(zones)}
        id="remove-all-zones"
      />
    </>
  );
};
