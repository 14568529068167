import AuthenticatedApp from 'AuthenticatedApp';
import NotAuthenticatedApp from 'NotAuthenticatedApp';
import { useAuth } from 'context/providers/AuthProvider';

const Root = () => {
  const { loggedInUser, fetchingUser } = useAuth();

  if (fetchingUser) return null;

  return loggedInUser ? <AuthenticatedApp /> : <NotAuthenticatedApp />;
};

export default Root;
