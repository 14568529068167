import { Trans, useTranslation } from 'react-i18next';
import { Typography, makeStyles } from '@material-ui/core';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import { CameraZone } from 'types/settingsTypes';

type ConfirmDeleteDialogProps = {
  open: boolean;
  onClose: () => void;
  zone: CameraZone;
  handleDelete: (zoneId: string) => void;
  loading: boolean;
};

export const ConfirmDeleteDialog = ({
  open,
  onClose,
  zone,
  handleDelete,
  loading,
}: ConfirmDeleteDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      message={
        <Trans
          i18nKey="settings.zones.delete-confirm"
          components={{ italic: <i /> }}
          values={{ zoneName: zone.name }}
        />
      }
      content={
        <>
          <Typography variant="body2" className={classes.descriptioon}>
            {t('settings.zones.assigned-streams')}
          </Typography>
          {zone.taggedStreams.map((stream) => (
            <Typography variant="body2" key={stream.streamId}>
              {stream.streamName}
            </Typography>
          ))}
        </>
      }
      confirmAction={() => handleDelete(zone.id)}
      loading={loading}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  descriptioon: {
    marginTop: theme.spacing(2),
  },
}));
