import { ReactNode } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type SettingsWrapperProps = {
  children: ReactNode;
  title: string;
  button?: ReactNode;
};

export const SettingsWrapper = ({
  children,
  title,
  button,
}: SettingsWrapperProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.settingsWrapper}>
      <div className={classes.header}>
        <Typography variant="h3" className={classes.title} id="settings-header">
          {t(`settings.${title}`)}
        </Typography>
        {button}
      </div>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  settingsWrapper: {
    marginTop: theme.spacing(4),
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    margin: theme.spacing(0, 0, 2, 1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
