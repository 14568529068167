import { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import DialogBase from 'components/Dialog/Dialog';
import Toast, { AlertSeverity } from 'components/Alert/Toast';
import { changePassword } from 'api/authApi';
import { useAuth } from 'context/providers/AuthProvider';
import { passChangeValidationSchema } from 'validation/passChangeValidationSchema';
import { ChangePassForm } from 'views/PassChange/ChangePassForm';
import { useAnalytics } from 'context/providers/AnalyticsProvider';

type ChangePassDialogProps = {
  open: boolean;
};

export type ChangePassFormValues = {
  newPassword: string;
  passwordRepeat: string;
};

export const ChangePassDialog = ({ open }: ChangePassDialogProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { login } = useAuth();
  const { fetchAvailableAnalytics } = useAnalytics();
  const [feedback, setFeedback] = useState<{
    open: boolean;
    status: AlertSeverity;
    msg: string;
  }>({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const initialValues = {
    newPassword: '',
    passwordRepeat: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      changePassword({ newPassword: values.newPassword })
        .then((user) => {
          if (user) {
            login(user);
            fetchAvailableAnalytics();
          }
          setLoading(false);
          formik.resetForm();
        })
        .catch((error) => {
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description
              ? error.response.data.description
              : 'general-error',
          });
          setLoading(false);
        });
    },
    validationSchema: passChangeValidationSchema(password),
  });

  return (
    <DialogBase
      open={open}
      title={t('change-password.header')}
      className={classes.dialog}
      closeable={false}
      disabled={loading}
    >
      <Typography variant="body2" className={classes.subtitle}>
        {t('change-password.set-new-password')}
      </Typography>
      <ChangePassForm
        formik={formik}
        loading={loading}
        setPassword={setPassword}
      />
      {feedback.open && (
        <Toast
          setState={setFeedback}
          severity={feedback.status}
          msg={feedback.msg}
        />
      )}
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: '430px',
  },
  subtitle: {
    marginTop: theme.spacing(2),
  },
}));
