import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'translations/locales/translationEN.json';
import translationPL from 'translations/locales/translationPL.json';
import translationES from 'translations/locales/translationES.json';
import translationPT from 'translations/locales/translationPT.json';
import detector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(detector)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      pl: {
        translation: translationPL,
      },
      es: {
        translation: translationES,
      },
      pt: {
        translation: translationPT,
      },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    nsSeparator: '|',
  });

export default i18n;
