import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import {
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import HeadCell from 'components/Table/HeadCell';
import TableBodyRow from 'components/Table/TableBodyRow';
import DataCell from 'components/Table/DataCell';
import TablePagination from 'components/Table/TablePagination';
import UsersTableRow from 'views/UsersView/UsersTable/UsersTableRow';
import { User } from 'types/userTypes';
import { AlertSeverity } from 'components/Alert/Toast';
import { removeDotsAts } from 'utils/helpers';

type UsersTableProps = {
  users: User[];
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
  fetchUsers: () => void;
};

export const UsersTable = ({
  users,
  setFeedback,
  fetchUsers,
}: UsersTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);

  const rowsPerPage = 20;
  const columnLabels = ['users.name', 'email', 'users.role', 'actions'];

  const currPage = users.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    if (isEmpty(currPage) && page > 0) {
      setPage(page - 1);
    }
    // eslint-disable-next-line
  }, [currPage]);

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader id="users-table">
        <TableHead>
          <TableRow>
            {columnLabels.map((label) => (
              <HeadCell
                id={`${label}-head-cell`}
                key={label}
                className={classes.headCell}
              >
                {t(label)}
              </HeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {users && !isEmpty(users) ? (
            currPage.map((user: User) => {
              return (
                <UsersTableRow
                  key={removeDotsAts(user.email)}
                  user={user}
                  setFeedback={setFeedback}
                  fetchUsers={fetchUsers}
                />
              );
            })
          ) : (
            <TableBodyRow>
              <DataCell
                id="info-cell"
                colSpan={4}
                className={classes.centeredCell}
              >
                {users && isEmpty(users) && t('no-results')}
              </DataCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalRecords={users?.length || 0}
      />
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
  },
  centeredCell: {
    textAlign: 'center',
    height: '350px',
  },
  container: {
    marginTop: theme.spacing(3),
  },
  headCell: {
    '&:last-child': {
      textAlign: 'center',
      padding: theme.spacing(1),
    },
  },
}));
