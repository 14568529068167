import { ChangeEvent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { JPGPreview } from 'views/StreamDetails/Preview/JPGPreview';
import { getAnalyticsMetaData } from 'api/analyticsApi';
import { AnalyticsMetaData } from 'types/analyticsTypes';
import { AnalyticsData } from 'views/StreamDetails/Preview/AnalyticsData';
import { checkIfDetected } from 'views/StreamDetails/Preview/helpers';
import { intervals } from 'views/StreamDetails/Preview/constants';
import SelectBase from 'components/Input/Select/SelectBase';
import { useTranslation } from 'react-i18next';
import { getStreamPreview } from 'api/streamApi';
import { useVideoWall } from 'context/providers/VideoWallProvider';
import { useAnalyticsSettings } from 'context/providers/AnalyticsSettingsProvider';
import { useStreamData } from 'context/providers/StreamDataProvider';

const StreamPreview = () => {
  const classes = useStyles();
  const { streamId } = useParams<{ streamId: string }>();
  const { selectedAnalytics, analyticsLoading, vpaStreamId } = useStreamData();
  const { refreshIntervals, setRefreshIntervals } = useVideoWall();
  const { t } = useTranslation();
  const [metaData, setMetaData] = useState<AnalyticsMetaData | undefined>(
    undefined
  );
  const [frame, setFrame] = useState('');
  const [loading, setLoading] = useState(true);
  const hasAssignedInterval = streamId && refreshIntervals[streamId];
  const [refreshInterval, setRefreshInterval] = useState(
    hasAssignedInterval ? refreshIntervals[streamId] : intervals[2].value
  );
  const { analyticsSettings } = useAnalyticsSettings();

  const handleIntervalChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (streamId) {
      setRefreshInterval(event.target.value as number);
      setRefreshIntervals({
        ...refreshIntervals,
        [streamId]: event.target.value as number,
      });
    }
  };

  useEffect(() => {
    if (!vpaStreamId || analyticsLoading) return;
    getStreamPreview(vpaStreamId, selectedAnalytics, analyticsSettings)
      .then((res) => {
        setFrame(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setFrame('');
      });
  }, [selectedAnalytics, vpaStreamId, analyticsSettings, analyticsLoading]);

  useEffect(() => {
    let continueRequests = true;

    const fetchPreview = () => {
      if (!vpaStreamId || !continueRequests || analyticsLoading) {
        return;
      }
      const startTimestamp = Date.now();
      getStreamPreview(vpaStreamId, selectedAnalytics, analyticsSettings, false)
        .then((res) => {
          setFrame(res);
        })
        .catch(() => {
          setFrame('');
        })
        .finally(() => {
          const currentTimestamp = Date.now();
          const timeout = Math.max(
            0,
            refreshInterval - (currentTimestamp - startTimestamp)
          );
          window.setTimeout(fetchPreview, timeout);
        });
    };

    const fetchPreviewAndMetadata = () => {
      if (
        !streamId ||
        !vpaStreamId ||
        !continueRequests ||
        !selectedAnalytics
      ) {
        return;
      }
      const startTimestamp = Date.now();
      Promise.allSettled([
        getAnalyticsMetaData(streamId, selectedAnalytics),
        getStreamPreview(vpaStreamId, selectedAnalytics, analyticsSettings),
      ])
        .then((responses) => {
          const metadata = responses[0];
          const preview = responses[1];

          if (metadata.status === 'fulfilled') {
            setMetaData(metadata.value);
          }
          if (preview.status === 'fulfilled') {
            setFrame(preview.value);
          }
        })
        .catch(() => {})
        .finally(() => {
          const currentTimestamp = Date.now();
          const timeout = Math.max(
            0,
            refreshInterval - (currentTimestamp - startTimestamp)
          );
          window.setTimeout(fetchPreviewAndMetadata, timeout);
        });
    };

    if (selectedAnalytics) {
      fetchPreviewAndMetadata();
    } else {
      fetchPreview();
    }

    return () => {
      continueRequests = false;
    };
  }, [
    streamId,
    vpaStreamId,
    selectedAnalytics,
    refreshInterval,
    analyticsSettings,
    analyticsLoading,
  ]);

  const isDetected = selectedAnalytics
    ? checkIfDetected(selectedAnalytics, metaData)
    : false;

  return (
    <div className={classes.container}>
      <div
        className={clsx(classes.previewContainer, {
          [classes.detected]: isDetected,
        })}
      >
        <JPGPreview
          maxWidth="1600px"
          minWidth="500px"
          isDetected={isDetected}
          hasBorder
          frame={frame}
          loading={loading}
        />
        <AnalyticsData metaData={metaData} />
      </div>
      <SelectBase
        value={refreshInterval}
        items={intervals}
        label={t('stream-preview.refresh-interval')}
        onChange={handleIntervalChange}
        className={classes.select}
        id="refresh-interval"
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(7),
    display: 'flex',
    width: '100%',
    minWidth: '600px',
  },
  previewContainer: {
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    maxWidth: '1600px',
  },
  detected: {
    boxShadow: theme.shadows[4],
  },
  select: {
    maxWidth: '150px',
    marginLeft: theme.spacing(4),
  },
}));

export default StreamPreview;
