import { Dispatch, SetStateAction, MutableRefObject } from 'react';
import Konva from 'konva';
import {
  normalizeValue01,
  normalizeValuePX,
} from 'components/KonvaShapes/helpers';
import { Polygon } from 'components/KonvaShapes/Polygon/PolygonZones';
import { Zone } from 'types/analyticsTypes';
import {
  checkIntersections,
  isMouseOverStartPoint,
  isMouseOverCurrentShape,
} from 'components/KonvaShapes/Polygon/utils';

export const convertToPX = (
  exclusionAreas: Zone[],
  width: number,
  height: number,
  scaleX: number,
  scaleY: number
) => {
  const formatted: Polygon[] = [];

  exclusionAreas.forEach((area) => {
    const normalized = area.points.map((point) => {
      const result = normalizeValuePX(point, width, height, scaleX, scaleY);
      return [result.x, result.y];
    });

    formatted.push({ points: normalized, isComplete: true });
  });

  return formatted;
};

export const convertTo01 = (
  polygons: Polygon[],
  width: number,
  height: number,
  scaleX: number,
  scaleY: number
) => {
  const apiExclusionAreas: Zone[] = [];

  polygons.forEach((polygon) => {
    const formatted = polygon.points.map((point) => {
      return { x: point[0], y: point[1] };
    });

    const normalized = formatted.map((node) => {
      return normalizeValue01(node, width, height, scaleX, scaleY);
    });

    apiExclusionAreas.push({ points: normalized });
  });

  return apiExclusionAreas;
};

export const drawPolygons = (
  stageRef: MutableRefObject<Konva.Stage | null>,
  allPolygons: Polygon[],
  setAllPolygons: Dispatch<SetStateAction<Polygon[]>>,
  polygonInProgress: Polygon,
  setPolygonInProgress: Dispatch<SetStateAction<Polygon>>,
  mousePos: number[],
  setIntersectingSegments: Dispatch<SetStateAction<number[][]>>
) => {
  const mouseOverCurrentShape = isMouseOverCurrentShape(stageRef);
  const mouseOverStartingPoint = isMouseOverStartPoint(stageRef);

  if (mouseOverCurrentShape) return;

  const isIntersection = checkIntersections(
    [...polygonInProgress.points, mousePos],
    setIntersectingSegments
  );

  if (isIntersection && !mouseOverStartingPoint) {
    return;
  }

  if (mouseOverStartingPoint && polygonInProgress.points.length >= 3) {
    setAllPolygons([
      ...allPolygons,
      { points: polygonInProgress.points, isComplete: true },
    ]);
    setPolygonInProgress({ points: [], isComplete: false });
  } else {
    setPolygonInProgress({
      ...polygonInProgress,
      points: [...polygonInProgress.points, mousePos],
    });

    if ([...polygonInProgress.points, mousePos].length === 14) {
      setAllPolygons([
        ...allPolygons,
        { points: [...polygonInProgress.points, mousePos], isComplete: true },
      ]);
      setPolygonInProgress({ points: [], isComplete: false });
    }
  }
};
