import jwtDecode from 'jwt-decode';
import { JwtToken } from 'types/authTypes';

export const decodeToken = (token: string) => {
  const decodedToken: JwtToken = jwtDecode(token);
  const tokenExpiration = decodedToken.exp;
  if (isTokenValid(tokenExpiration)) {
    return {
      sub: decodedToken.sub,
      role: decodedToken.role,
      mustChangePassword: decodedToken.mustChangePassword,
    };
  }
  return null;
};

const isTokenValid = (tokenExpiration: number) => {
  const today = Date.now();
  const currentDate = new Date(today);
  // timestamp in miliseconds
  const expirationDate = new Date(tokenExpiration * 1000);
  return expirationDate > currentDate;
};
