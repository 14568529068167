import { ButtonProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import ButtonPrimary from 'components/Button/ButtonPrimary';

type SubmitButtonProps = ButtonProps & {
  isLoading?: boolean;
};

const SubmitButton = ({ disabled, ...props }: SubmitButtonProps) => {
  const classes = useStyles();

  return (
    <ButtonPrimary
      disabled={disabled}
      startIcon={
        <CheckIcon
          className={clsx(classes.iconComponent, {
            [classes.disabled]: disabled,
          })}
        />
      }
      {...props}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  iconComponent: {
    width: '20px',
    height: '20px',
  },
  disabled: {
    fill: theme.palette.text.disabled,
  },
}));

export default SubmitButton;
