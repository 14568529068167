export enum UserRole {
  ADMINISTRATOR = 'ADMINISTRATOR',
  GUEST = 'GUEST',
}

export type JWTUser = {
  sub: string;
  role: UserRole;
  mustChangePassword: boolean;
};

export type JwtToken = {
  sub: string;
  role: UserRole;
  mustChangePassword: boolean;
  iat: string;
  exp: number;
};
