import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import IconButton from 'components/Button/IconButton';
import { AlertSeverity } from 'components/Alert/Toast';
import { ResetPasswordDialog } from 'views/UsersView/ResetPassword/ResetPasswordDialog';
import { passChangeValidationSchema } from 'validation/passChangeValidationSchema';
import { resetPassword } from 'api/usersApi';
import { User } from 'types/userTypes';
import { removeDotsAts } from 'utils/helpers';

type ResetPasswordProps = {
  user: User;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export type ResetValues = {
  newPassword: string;
  passwordRepeat: string;
  mustChangePassword: boolean;
};

export const ResetPassword = ({ user, setFeedback }: ResetPasswordProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const initialValues: ResetValues = {
    newPassword: '',
    passwordRepeat: '',
    mustChangePassword: false,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: ResetValues) => {
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      const apiValues = {
        newPassword: values.newPassword,
        email: user.email,
        mustChangePassword: values.mustChangePassword,
      };
      resetPassword(apiValues)
        .then(() => {
          setLoading(false);
          setResetDialogOpen(false);
          formik.resetForm();
          setFeedback({
            open: true,
            status: AlertSeverity.SUCCESS,
            msg: 'reset-password-success',
          });
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description
              ? error.response.data.description
              : 'general-error',
          });
        });
    },
    validationSchema: passChangeValidationSchema(password),
  });

  return (
    <>
      <Tooltip title={t('users.reset-password')}>
        <IconButton
          className={classes.actionsButton}
          onClick={() => setResetDialogOpen(true)}
          id={`${removeDotsAts(user.email)}-pass-reset-button`}
        >
          <LockOpenOutlinedIcon />
        </IconButton>
      </Tooltip>
      <ResetPasswordDialog
        open={resetDialogOpen}
        onClose={() => setResetDialogOpen(false)}
        formik={formik}
        loading={loading}
        setPassword={setPassword}
        user={user}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsButton: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  },
}));
