import { apiOrigin } from 'api/config/interceptors';
import { LoginUserDTO, ChangePassDTO } from 'api/DTOs';
import { decodeToken } from 'utils/decodeJWT';

export const userLogin = (loginData: LoginUserDTO) => {
  return apiOrigin.post('/user/auth/login', loginData).then((res) => {
    const user = decodeToken(res.headers.authorization);

    return user;
  });
};

export const changePassword = (data: ChangePassDTO) => {
  return apiOrigin.post('/user/auth/changePassword', data).then((res) => {
    const user = decodeToken(res.headers.authorization);

    return user;
  });
};

export const getLicenseInfo = () => {
  return apiOrigin.get(`/license`).then((res) => {
    return res.data;
  });
};
