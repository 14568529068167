import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetectionBox } from 'views/StreamDetails/Preview/components/DetectionBox';
import { ReactComponent as Intruder } from 'icons/intruder.svg';
import { ReactComponent as People } from 'icons/people.svg';
import { IntruderStreamData } from 'types/analytics/IntruderDetection';

type IntruderDetectionMetaDataProps = {
  metaData: IntruderStreamData;
};

export const IntruderDetectionMetaData = ({
  metaData,
}: IntruderDetectionMetaDataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const intrusionDetected = metaData?.isIntrusionDetected;

  return (
    <div className={classes.detections}>
      <DetectionBox
        label={
          intrusionDetected
            ? t('stream-preview.intruder.intrusion-detected')
            : t('stream-preview.intruder.intrusion-not-detected')
        }
        Icon={Intruder}
        isDetected={intrusionDetected}
      />
      <DetectionBox
        label={t('stream-preview.intruder.people-in-frame')}
        Icon={People}
        withValue
        value={metaData?.numberOfPeopleOnFrame}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  detections: {
    display: 'flex',
  },
}));
