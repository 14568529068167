import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { getZones } from 'api/settingsApi';
import { CameraZone } from 'types/settingsTypes';

export type CameraZonesContextValue = {
  cameraZones: CameraZone[];
  loading: boolean;
  fetchCameraZones: () => void;
};

export type CameraZonesProviderProps = {
  children?: ReactNode;
};

const defaultValue: CameraZonesContextValue = {
  cameraZones: [],
  loading: true,
  fetchCameraZones: () => {},
};

const CameraZonesContext = createContext(defaultValue);

const CameraZonesProvider: FC<CameraZonesProviderProps> = (
  props: CameraZonesProviderProps
) => {
  const [cameraZones, setCameraZones] = useState<CameraZone[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCameraZones();
  }, []);

  const fetchCameraZones = () => {
    getZones()
      .then((res) => {
        setCameraZones(res.tags);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <CameraZonesContext.Provider
      value={{
        cameraZones,
        loading,
        fetchCameraZones,
      }}
      {...props}
    />
  );
};

const useCameraZones = () => useContext(CameraZonesContext);
export { CameraZonesProvider, CameraZonesContext, useCameraZones };
