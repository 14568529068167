import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormikContextType } from 'formik';
import { useTranslation } from 'react-i18next';
import SubmitButton from 'components/Button/SubmitButton';
import { DoublePassInput } from 'views/PassChange/DoublePassInput';
import { ChangePassFormValues } from 'views/PassChange/ChangePassDialog';

type ChangePassFormProps = {
  loading: boolean;
  setPassword: Dispatch<SetStateAction<string>>;
  formik: FormikContextType<ChangePassFormValues>;
};

export const ChangePassForm = ({
  formik,
  loading,
  setPassword,
}: ChangePassFormProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <form onSubmit={formik.handleSubmit}>
      <DoublePassInput
        formik={formik}
        setPassword={setPassword}
        firstInputClass={classes.firstInput}
        secondInputClass={classes.secondInput}
      />
      <div className={classes.actions}>
        <SubmitButton
          disabled={!formik.dirty || !formik.isValid || loading}
          type="submit"
          id="submit-button"
          isLoading={loading}
          className={classes.submitButton}
        >
          {t('submit')}
        </SubmitButton>
      </div>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    minWidth: '113px',
  },
  firstInput: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  secondInput: {
    marginBottom: theme.spacing(5),
  },
}));
