import { FocusEvent, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { FormikContextType } from 'formik';
import { useTranslation } from 'react-i18next';
import PasswordInputIcon from 'components/Input/TextField/PasswordInputIcon';
import { handleBlurAndTrim } from 'utils/helpers';
import { ChangePassFormValues } from 'views/PassChange/ChangePassDialog';
import { ResetValues } from 'views/UsersView/ResetPassword/ResetPassword';

type DoublePassInputProps = {
  formik:
    | FormikContextType<ChangePassFormValues>
    | FormikContextType<ResetValues>;
  setPassword: Dispatch<SetStateAction<string>>;
  firstInputClass: string;
  secondInputClass: string;
};

export const DoublePassInput = ({
  formik,
  setPassword,
  firstInputClass,
  secondInputClass,
}: DoublePassInputProps) => {
  const { t } = useTranslation();

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    formik.handleChange(event);
  };

  return (
    <>
      <PasswordInputIcon
        name="newPassword"
        label={t('change-password.new-password')}
        maxLength={255}
        className={firstInputClass}
        value={formik.values.newPassword}
        onChange={handlePasswordChange}
        onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) =>
          handleBlurAndTrim(event, formik)
        }
        error={Boolean(formik.errors.newPassword && formik.touched.newPassword)}
        helperText={
          formik.errors.newPassword && formik.touched.newPassword
            ? formik.errors.newPassword
            : t('validation.invalid-password')
        }
      />
      <PasswordInputIcon
        name="passwordRepeat"
        label={t('repeat-password')}
        maxLength={255}
        className={secondInputClass}
        value={formik.values.passwordRepeat}
        onChange={formik.handleChange}
        onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) =>
          handleBlurAndTrim(event, formik)
        }
        error={Boolean(
          formik.errors.passwordRepeat && formik.touched.passwordRepeat
        )}
        helperText={
          formik.errors.passwordRepeat && formik.touched.passwordRepeat
            ? formik.errors.passwordRepeat
            : null
        }
      />
    </>
  );
};
