import { ReactNode } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import DialogBase from 'components/Dialog/Dialog';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import ButtonBase from 'components/Button/ButtonBase';
import CircularLoader from 'components/Progress/CircularLoader';

type ConfirmDialogProps = {
  open: boolean;
  onClose: () => void;
  message: ReactNode | string;
  content?: ReactNode;
  confirmAction: () => void;
  loading: boolean;
};

const ConfirmDialog = ({
  open,
  onClose,
  message,
  content,
  confirmAction,
  loading,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleConfirm = () => {
    confirmAction();
  };

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      className={classes.dialog}
      id="confirm-dialog"
      disabled={loading}
    >
      <Typography className={classes.text}>{message}</Typography>
      {content}
      <div className={classes.actions}>
        <ButtonBase
          className={clsx(classes.button, classes.cancel)}
          onClick={onClose}
          id="deny-button"
          disabled={loading}
        >
          {t('no')}
        </ButtonBase>
        <ButtonPrimary
          className={classes.button}
          onClick={handleConfirm}
          id="confirm-button"
          disabled={loading}
        >
          {loading ? <CircularLoader /> : t('yes')}
        </ButtonPrimary>
      </div>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(3, 0, 0, 0),
  },
  button: {
    width: '70px',
    height: '44px',
  },
  dialog: {
    width: '300px',
    minWidth: '270px',
  },
  text: {
    width: '200px',
    textAlign: 'center',
    margin: '0 auto',
  },
  cancel: {
    marginRight: theme.spacing(3),
  },
}));

export default ConfirmDialog;
