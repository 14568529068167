import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from 'components/Button/IconButton';
import { Stream, EditStreamDTO } from 'types/streamTypes';
import { AlertSeverity } from 'components/Alert/Toast';
import { EditStreamDialog } from 'views/StreamsView/EditStream/EditStreamDialog';
import { editStreamValidationSchema } from 'validation/editStreamValidationSchema';
import { updateStream } from 'api/streamApi';
import { useVideoWall } from 'context/providers/VideoWallProvider';
import { isEmpty } from 'lodash';
import { useCameraZones } from 'context/providers/CameraZonesProvider';

type EditStreamProps = {
  stream: Stream;
  fetchStreams: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export type EditValues = {
  name: string;
  url: string;
  username: string;
  password: string | null;
  deletePassword?: boolean;
  changePassword?: boolean;
  passwordExists?: boolean;
  assignedTags?: string;
  removedTagsIds: string[];
  newTagsIds: string[];
};

export const EditStream = ({
  stream,
  setFeedback,
  fetchStreams,
}: EditStreamProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { fetchCameraZones } = useCameraZones();
  const { videoWallStreams, saveVideoWallStreams } = useVideoWall();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues: EditValues = {
    name: stream.name,
    url: stream.url,
    username: stream.username,
    password: '',
    deletePassword: false,
    changePassword: false,
    passwordExists: stream.passwordExists,
    assignedTags: !isEmpty(stream.assignedTags)
      ? stream.assignedTags[0].id
      : '',
    removedTagsIds: [],
    newTagsIds: [],
  };

  const formatValues = (values: EditValues): EditStreamDTO => {
    const valuesCopy = { ...values };

    const removedTags = stream.assignedTags.map((tag) => tag.id);
    const addedTags = values.assignedTags ? [values.assignedTags] : [];

    valuesCopy.removedTagsIds = removedTags;
    valuesCopy.newTagsIds = addedTags;

    if (values.passwordExists) {
      if (!values.changePassword && !values.deletePassword) {
        valuesCopy.password = null;
      }
    } else {
      if (!values.password) {
        valuesCopy.password = null;
      }
    }

    delete valuesCopy.assignedTags;
    delete valuesCopy.deletePassword;
    delete valuesCopy.changePassword;
    delete valuesCopy.passwordExists;

    return valuesCopy;
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: EditStreamDTO) => {
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      const apiValues = formatValues(values);
      updateStream(stream.id, apiValues)
        .then(() => {
          const videoWallIds = videoWallStreams.map(
            (videoWallStream) => videoWallStream.id
          );
          const isInVideoWall = videoWallIds.find((id) => id === stream.id);
          if (isInVideoWall) {
            const index = videoWallIds.findIndex((id) => id === stream.id);
            const newStreams = [...videoWallStreams];
            newStreams.splice(index, 1, {
              id: stream.id,
              vpaStreamId: stream.vpaStreamId,
              name: values.name,
              enabledAnalytics: stream.enabledAnalytics,
            });
            saveVideoWallStreams(newStreams);
          }
          fetchStreams();
          fetchCameraZones();
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.SUCCESS,
            msg: 'stream-edit-success',
          });
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description
              ? error.response.data.description
              : 'general-error',
          });
        });
    },
    validationSchema: editStreamValidationSchema,
  });

  return (
    <>
      <Tooltip title={t('streams.edit')}>
        <IconButton
          className={classes.actionsButton}
          onClick={() => setEditDialogOpen(true)}
          id={`${stream.id}-edit-button`}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <EditStreamDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        formik={formik}
        loading={loading}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsButton: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  },
}));
