import { alarmingOrigin } from 'api/config/interceptors';
import { UserUpdateAlarmDTO } from 'types/alarmTypes';
import { UpdateAlarmingSettingsDTO } from 'types/settingsTypes';

export const updateAlarmEvent = (
  alarmingEventId: string,
  payload: UserUpdateAlarmDTO
) => {
  return alarmingOrigin
    .patch(`/alarms/${alarmingEventId}`, payload)
    .then((res) => {
      return res.data;
    });
};

export const getAlarmingSettings = () => {
  return alarmingOrigin.get(`/settings`).then((res) => {
    return res.data;
  });
};

export const updateAlarmingSettings = (
  updateSettingsDTO: UpdateAlarmingSettingsDTO
) => {
  return alarmingOrigin.put(`/settings`, updateSettingsDTO).then((res) => {
    return res.data;
  });
};
