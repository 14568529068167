import { Input, makeStyles } from '@material-ui/core';
import SliderContinous from './SliderContinuous';
import { ChangeEvent } from 'react';
import { useAuth } from 'context/providers/AuthProvider';

type SliderContWithInputProps = {
  name: string;
  label: string;
  value: number | string;
  min: number;
  max: number;
  valueFormatter: (value: number) => string;
  setValue: (value: number | string) => void;
  onChangeCommitted: (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[]
  ) => void;
  description: string;
  id: string;
};

const SliderContWithInput = ({
  name,
  label,
  value,
  min,
  max,
  valueFormatter,
  setValue,
  onChangeCommitted,
  ...props
}: SliderContWithInputProps) => {
  const classes = useStyles();
  const { isGuest } = useAuth();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    let boundedValue = value;
    if (typeof value !== 'number' || value < 1) {
      boundedValue = 1;
    } else if (value > max) {
      boundedValue = max;
    }
    setValue(boundedValue);
    onChangeCommitted(undefined, boundedValue as number);
  };

  return (
    <div className={classes.sliderContainer}>
      <SliderContinous
        name={name}
        value={typeof value === 'number' ? value : 1}
        customValueLabel
        min={min}
        max={max ? max : 1}
        setValue={setValue}
        label={label}
        valueFormatter={valueFormatter}
        onChangeCommitted={onChangeCommitted}
        {...props}
      />
      <Input
        className={classes.input}
        value={value}
        margin="dense"
        onChange={handleInputChange}
        onBlur={handleBlur}
        disabled={isGuest}
        inputProps={{
          step: 1,
          min: min,
          max: max,
          type: 'number',
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
  },
  input: {
    minWidth: '45px',
    maxWidth: '45px',
    marginLeft: theme.spacing(4),
    maxHeight: '40px',
    alignSelf: 'end',
    marginBottom: theme.spacing(2),
  },
}));

export default SliderContWithInput;
