import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import DialogBase from 'components/Dialog/Dialog';
import { createStream } from 'api/streamApi';
import { addStreamValidationSchema } from 'validation/addStreamValidationSchema';
import { CreateStreamDTO } from 'types/streamTypes';
import { AlertSeverity } from 'components/Alert/Toast';
import { AddStreamForm } from 'views/StreamsView/AddStream/AddStreamForm';
import {
  AnalyticsTypes,
  useAnalytics,
} from 'context/providers/AnalyticsProvider';

type AddStreamDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchStreams: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export type AddStreamValues = {
  name: string;
  url: string;
  username: string;
  password: string;
  selectedAnalytics: AnalyticsTypes[];
  assignedTagsIds: { value: string; label: string };
};

const initialValues = {
  name: '',
  url: '',
  username: '',
  password: '',
  selectedAnalytics: [],
  assignedTagsIds: '',
};

export const AddStreamDialog = ({
  onClose,
  fetchStreams,
  setFeedback,
  ...props
}: AddStreamDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { fetchAvailableAnalytics } = useAnalytics();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: CreateStreamDTO) => {
      const valuesCopy = { ...values };
      valuesCopy.assignedTagsIds = valuesCopy.assignedTagsIds
        ? ([valuesCopy.assignedTagsIds] as string[])
        : [];
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      createStream(valuesCopy)
        .then(() => {
          handleClose();
          fetchAvailableAnalytics();
          fetchStreams();
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.SUCCESS,
            msg: 'add-stream-success',
          });
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description
              ? error.response.data.description
              : 'general-error',
          });
        });
    },
    validationSchema: addStreamValidationSchema,
  });

  return (
    <DialogBase
      title={t('streams.add')}
      className={classes.dialog}
      onClose={handleClose}
      id="add-stream-dialog"
      disabled={loading}
      {...props}
    >
      <AddStreamForm
        // @ts-ignore
        formik={formik}
        loading={loading}
        handleClose={handleClose}
      />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    width: '400px',
    maxWidth: '400px',
  },
}));
