import { ReactNode } from 'react';
import { Drawer, makeStyles } from '@material-ui/core';
import { MenuContent } from 'components/Navbar/MenuContent';
import { Breadcrumbs } from 'components/Breadcrumbs/Breadcrumbs';

const drawerWidth = 247;

type NavbarProps = {
  children: ReactNode;
};

export const Navbar = ({ children }: NavbarProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <nav>
        <Drawer className={classes.drawer} variant="permanent" anchor="left">
          <MenuContent />
        </Drawer>
      </nav>
      <main className={classes.content}>
        <Breadcrumbs />
        {children}
      </main>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      border: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3, 5, 0, 5),
  },
}));
