import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import { useAuth } from 'context/providers/AuthProvider';

type ResetButtonProps = ButtonProps & {
  label: string;
};

export const ResetButton = ({
  label,
  disabled,
  ...props
}: ResetButtonProps) => {
  const classes = useStyles();
  const { isGuest } = useAuth();

  return (
    <Button
      className={classes.button}
      variant="outlined"
      size="small"
      disabled={disabled || isGuest}
      {...props}
    >
      {label}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
}));
