import { makeStyles } from '@material-ui/core';
import { SettingsWrapper } from '../SettingsWrapper';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useState } from 'react';
import { AddZoneDialog } from './AddZoneDialog';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import AddIcon from '@material-ui/icons/Add';
import { AlertSeverity } from 'components/Alert/Toast';
import { isEmpty } from 'lodash';
import ZoneCard from 'views/SettingsView/MonitoringZonesSettings/ZoneCard';
import { useCameraZones } from 'context/providers/CameraZonesProvider';

type MonitoringZonesSettingsProps = {
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export const MonitoringZonesSettings = ({
  setFeedback,
}: MonitoringZonesSettingsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { cameraZones, fetchCameraZones } = useCameraZones();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const handleAdd = () => {
    setAddDialogOpen(true);
  };

  const AddZoneButton = () => {
    return (
      <ButtonPrimary
        startIcon={<AddIcon />}
        className={classes.button}
        onClick={handleAdd}
        id="add-zone-button"
      >
        {t('settings.zones.add')}
      </ButtonPrimary>
    );
  };

  return (
    <SettingsWrapper title="zone-settings" button={<AddZoneButton />}>
      <div className={classes.zonesContainer}>
        {!isEmpty(cameraZones) &&
          cameraZones.map((zone) => (
            <ZoneCard
              key={zone.id}
              zone={zone}
              fetchZones={fetchCameraZones}
              setFeedback={setFeedback}
            />
          ))}
      </div>
      <AddZoneDialog
        open={addDialogOpen}
        onClose={() => setAddDialogOpen(false)}
        setFeedback={setFeedback}
        fetchZones={fetchCameraZones}
      />
    </SettingsWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: '44px',
  },
  zonesContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
