import Toast, { AlertSeverity } from 'components/Alert/Toast';
import { AlarmingSettings } from 'views/SettingsView/AlarmingSettings/AlarmingSettings';
import { useState } from 'react';
import { MonitoringZonesSettings } from './MonitoringZonesSettings/MonitoringZonesSettings';

const SettingsView = () => {
  const [feedback, setFeedback] = useState<{
    open: boolean;
    status: AlertSeverity;
    msg: string;
  }>({ open: false, status: AlertSeverity.SUCCESS, msg: '' });

  return (
    <>
      <AlarmingSettings setFeedback={setFeedback} />
      <MonitoringZonesSettings setFeedback={setFeedback} />
      {feedback.open && (
        <Toast
          setState={setFeedback}
          severity={feedback.status}
          msg={feedback.msg}
          noTranslation={feedback.msg.startsWith('Stream tag with name')}
        />
      )}
    </>
  );
};

export default SettingsView;
