import clsx from 'clsx';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import CircularLoader from 'components/Progress/CircularLoader';

type ButtonPrimaryProps = ButtonProps & {
  isLoading?: boolean;
};

const ButtonPrimary = ({
  children,
  className,
  onClick,
  isLoading = false,
  disabled,
  startIcon,
  ...props
}: ButtonPrimaryProps) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      className={clsx(className, classes.button)}
      classes={{
        startIcon: classes.startIcon,
      }}
      onClick={onClick}
      disabled={disabled}
      startIcon={isLoading ? <CircularLoader size={20} /> : startIcon}
      {...props}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '14px',
    fontWeight: 500,
    height: '44px',
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
      boxShadow: theme.shadows[2],
    },
  },
  startIcon: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    color: theme.palette.secondary.main,
  },
}));

export default ButtonPrimary;
