import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ResetOptions } from 'components/KonvaShapes/ResetOptions';
import { ToggleDrawingMode } from 'components/KonvaShapes/ToggleDrawingMode';
import { KonvaRectangle } from 'components/KonvaShapes/FreeFormRectangle/FreeFormRectangle';
import { ZoneModes } from 'components/KonvaShapes/types';
import { Dispatch, SetStateAction } from 'react';

type FreeFormRectanglesOptionsProps = {
  handleRemoveAll: () => void;
  handleRemoveSelected: () => void;
  zones: KonvaRectangle[];
  selectedZones: number[];
  mode: ZoneModes;
  setMode: Dispatch<SetStateAction<ZoneModes>>;
  setSelectedZones: Dispatch<SetStateAction<number[]>>;
};

export const FreeFormRectanglesOptions = ({
  handleRemoveAll,
  handleRemoveSelected,
  zones,
  selectedZones,
  mode,
  setMode,
  setSelectedZones,
}: FreeFormRectanglesOptionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.zoneOptions}>
      <Typography>{t('stream-settings.monitoring-zone-options')}</Typography>
      <ToggleDrawingMode
        mode={mode}
        setMode={setMode}
        setSelectedZones={setSelectedZones}
      />
      <ResetOptions
        handleRemoveAll={handleRemoveAll}
        handleRemoveSelected={handleRemoveSelected}
        zones={zones}
        selectedZones={selectedZones}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  zoneOptions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '14px',
  },
}));
