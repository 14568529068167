import { Dispatch, SetStateAction, useState } from 'react';
import { makeStyles, Divider, Typography } from '@material-ui/core';
import { EventTimers, Zone } from 'types/analyticsTypes';
import { defaultInclusionZone } from 'components/KonvaShapes/Polygon/constants';
import { IntruderDetectionSettings } from 'types/analytics/IntruderDetection';
import { useResizeDetector } from 'react-resize-detector';
import { Trans } from 'react-i18next';
import { layerOffset } from 'components/KonvaShapes/constants';
import { AlarmingModeSettings } from 'views/StreamDetails/Settings/AlarmingModeSettings';
import { ReportingModeSettings } from 'views/StreamDetails/Settings/ReportingModeSettings';
import { JPGPreviewWithFetch } from 'views/StreamDetails/Preview/JPGPreviewWithFetch';
import { useStreamData } from 'context/providers/StreamDataProvider';
import { isEmpty } from 'lodash';
import PolygonZones, {
  PolygonZoneTypes,
} from 'components/KonvaShapes/Polygon/PolygonZones';
import { IntruderDetectionThresholds } from 'views/StreamDetails/Settings/Analytics/IntruderDetection/IntruderThresholds';

export type IntruderDetectionProps = {
  settings: IntruderDetectionSettings;
  setSettings: Dispatch<SetStateAction<IntruderDetectionSettings>>;
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
};

export const IntruderDetection = ({
  settings,
  setSettings,
  eventTimers,
  setEventTimers,
}: IntruderDetectionProps) => {
  const classes = useStyles();
  const { width, height, ref } = useResizeDetector();
  const { vpaStreamId } = useStreamData();
  const [previewLoading, setPreviewLoading] = useState(true);

  const videSizeDetermined = width && height;

  const setInclusionZones = (inclusionZones: Zone[]) => {
    if (isEmpty(inclusionZones)) {
      setSettings({ ...settings, inclusionZones: [defaultInclusionZone] });
    } else {
      setSettings({ ...settings, inclusionZones });
    }
  };

  const inclusionAreasState = {
    activeZones: settings?.inclusionZones || [],
    setActiveZones: setInclusionZones,
  };

  return (
    <>
      <div className={classes.firstRow}>
        <div className={classes.previewWithZones}>
          <Typography variant="body2" className={classes.intro}>
            <Trans i18nKey="stream-settings.inclusion-zones-intro" />
          </Typography>
          {videSizeDetermined && !previewLoading && (
            <div className={classes.konvaShape}>
              <PolygonZones
                width={width}
                height={height}
                activeZonesState={inclusionAreasState}
                type={PolygonZoneTypes.INCLUSION}
              />
            </div>
          )}
          <JPGPreviewWithFetch
            ref={ref}
            className={classes.preview}
            previewLoading={previewLoading}
            setPreviewLoading={setPreviewLoading}
            streamId={vpaStreamId}
          />
        </div>
        <div className={classes.thresholdsColumn}>
          <IntruderDetectionThresholds
            settings={settings}
            setSettings={setSettings}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.secondRow}>
        <AlarmingModeSettings
          eventTimers={eventTimers}
          setEventTimers={setEventTimers}
          className={classes.alarmingSettings}
        />
        <ReportingModeSettings
          eventTimers={eventTimers}
          setEventTimers={setEventTimers}
        />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  thresholdsColumn: {
    maxWidth: '400px',
    minWidth: '300px',
    flexGrow: 1,
  },
  previewWithZones: {
    flex: 2,
    maxWidth: '1400px',
    marginBottom: theme.spacing(12),
  },
  konvaShape: {
    position: 'absolute',
    zIndex: 10,
  },
  preview: {
    marginLeft: `${layerOffset}px`,
    marginTop: `${layerOffset}px`,
  },
  intro: {
    marginLeft: `${layerOffset}px`,
  },
  firstRow: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  secondRow: {
    display: 'flex',
  },
  alarmingSettings: {
    marginRight: theme.spacing(6),
  },
  divider: {
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.grey[400],
    maxWidth: '1800px',
  },
}));
