import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

type CircularLoaderProps = {
  className?: string;
  id?: string;
};

const PreviewLoader = ({ className, id }: CircularLoaderProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)}>
      <CircularProgress id={id} color="inherit" size={100} thickness={0.8} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
}));

export default PreviewLoader;
