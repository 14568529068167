import { Dispatch, SetStateAction, useState } from 'react';
import {
  Divider,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { deleteZone } from 'api/settingsApi';
import { AlertSeverity } from 'components/Alert/Toast';
import { CameraZone } from 'types/settingsTypes';
import { EditZoneDialog } from 'views/SettingsView/MonitoringZonesSettings/EditZoneDialog';
import { isEmpty } from 'lodash';
import { ConfirmDeleteDialog } from 'views/SettingsView/MonitoringZonesSettings/ConfirmDeleteDialog';

type ZoneCardProps = {
  zone: CameraZone;
  fetchZones: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};
const ZoneCard = ({ zone, fetchZones, setFeedback }: ZoneCardProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [editZoneOpen, setEditZoneOpen] = useState(false);

  const handleDelete = (zoneId: string) => {
    setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
    setLoading(true);
    deleteZone(zoneId)
      .then(() => {
        setLoading(false);
        fetchZones();
        setConfirmDeleteOpen(false);
        setFeedback({
          open: true,
          status: AlertSeverity.SUCCESS,
          msg: 'delete-zone-success',
        });
      })
      .catch((error) => {
        setLoading(false);
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: error.response.data.description
            ? error.response.data.description
            : 'general-error',
        });
      });
  };

  const handleDeleteClick = () => {
    if (isEmpty(zone.taggedStreams)) {
      handleDelete(zone.id);
    } else {
      setConfirmDeleteOpen(true);
    }
  };

  const handleDialogClose = () => {
    setConfirmDeleteOpen(false);
  };

  return (
    <div className={classes.zoneCard}>
      <div className={classes.header}>
        <Typography noWrap className={classes.name}>
          {zone.name}
        </Typography>
        <div>
          <IconButton
            size="small"
            onClick={() => setEditZoneOpen(true)}
            className={classes.editButton}
          >
            <EditIcon className={classes.icon} />
          </IconButton>
          <IconButton size="small" onClick={handleDeleteClick}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </div>
      </div>
      <Divider />
      <Tooltip
        placement="bottom-start"
        classes={{
          popper: classes.popper,
        }}
        title={zone.description.length > 45 ? zone.description : ''}
      >
        <Typography variant="caption" noWrap>
          {zone.description}
        </Typography>
      </Tooltip>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={handleDialogClose}
        handleDelete={handleDelete}
        zone={zone}
        loading={loading}
      />
      <EditZoneDialog
        open={editZoneOpen}
        onClose={() => setEditZoneOpen(false)}
        setFeedback={setFeedback}
        fetchZones={fetchZones}
        zone={zone}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  zoneCard: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '4px',
    padding: theme.spacing(1, 2, 1, 2),
    width: '250px',
    maxWidth: '250px',
    height: '73px',
    maxHeight: '100px',
    backgroundColor: theme.palette.grey[800],
    margin: theme.spacing(0, 2, 2, 0),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    width: '18px',
    height: '18px',
  },
  name: {
    maxWidth: '180px',
  },
  editButton: {
    margin: theme.spacing(0, 0.5),
  },
  popper: {
    marginLeft: theme.spacing(-3.5),
    maxWidth: '270px',
  },
}));

export default ZoneCard;
