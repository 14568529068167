import { EventTimers } from 'types/analyticsTypes';
import { cloneDeep } from 'lodash';

const convertSecToMin = (seconds: number) => {
  return seconds ? Math.ceil(seconds / 60) : 1;
};

const convertMinToSec = (minutes: number) => {
  return minutes * 60;
};

const decimalToPercent = (decimal: number) => {
  return decimal * 100;
};

const percentToDecimal = (percent: number) => {
  return percent / 100;
};

export const convertToFrontedValues = (timers: EventTimers) => {
  const frontendValues = cloneDeep(timers);
  frontendValues.reportingSettings.analysedTimeFrameSeconds = convertSecToMin(
    frontendValues.reportingSettings.analysedTimeFrameSeconds
  );
  frontendValues.reportingSettings.analysisIntervalSeconds = convertSecToMin(
    frontendValues.reportingSettings.analysisIntervalSeconds
  );
  frontendValues.reportingSettings.cumulativeEventOccurrenceTime =
    decimalToPercent(
      frontendValues.reportingSettings.cumulativeEventOccurrenceTime
    );

  return frontendValues;
};

export const convertToApiValues = (timers: EventTimers) => {
  const apiValues = cloneDeep(timers);
  apiValues.reportingSettings.analysedTimeFrameSeconds = convertMinToSec(
    apiValues.reportingSettings.analysedTimeFrameSeconds
  );
  apiValues.reportingSettings.analysisIntervalSeconds = convertMinToSec(
    apiValues.reportingSettings.analysisIntervalSeconds
  );
  apiValues.reportingSettings.cumulativeEventOccurrenceTime = percentToDecimal(
    apiValues.reportingSettings.cumulativeEventOccurrenceTime
  );

  return apiValues;
};
