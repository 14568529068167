import { apiOrigin } from 'api/config/interceptors';
import { ZoneDTO } from 'types/settingsTypes';

export const addNewZone = (addNewZoneDTO: ZoneDTO) => {
  return apiOrigin.post(`/tags`, addNewZoneDTO).then((res) => {
    return res.data;
  });
};

export const getZones = () => {
  return apiOrigin.get(`/tags`).then((res) => {
    return res.data;
  });
};

export const updateZone = (zoneId: string, updateZoneDTO: ZoneDTO) => {
  return apiOrigin.put(`/tags/${zoneId}`, updateZoneDTO).then((res) => {
    return res.data;
  });
};

export const deleteZone = (zoneId: string) => {
  return apiOrigin.delete(`/tags/${zoneId}`).then((res) => {
    return res.data;
  });
};
