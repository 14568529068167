import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from 'components/Button/IconButton';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import { deleteUser } from 'api/usersApi';
import { AlertSeverity } from 'components/Alert/Toast';
import { User } from 'types/userTypes';
import { useAuth } from 'context/providers/AuthProvider';
import { removeDotsAts } from 'utils/helpers';

type DeleteUserProps = {
  user: User;
  fetchUsers: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export const DeleteUser = ({
  user,
  fetchUsers,
  setFeedback,
}: DeleteUserProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { loggedInUser } = useAuth();

  const handleDelete = (userEmail: string) => {
    setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
    setLoading(true);
    deleteUser(userEmail)
      .then(() => {
        setLoading(false);
        fetchUsers();
        setFeedback({
          open: true,
          status: AlertSeverity.SUCCESS,
          msg: 'user-delete-success',
        });
      })
      .catch((error) => {
        setLoading(false);
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: error.response.data.description
            ? error.response.data.description
            : 'general-error',
        });
        setDeleteDialogOpen(false);
      });
  };

  const isCurrentUser = loggedInUser && loggedInUser.sub === user.email;

  return (
    <>
      <Tooltip title={isCurrentUser ? '' : t('users.delete')}>
        <IconButton
          className={classes.actionsButton}
          onClick={() => setDeleteDialogOpen(true)}
          id={`${removeDotsAts(user.email)}-delete-button`}
          disabled={isCurrentUser}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        message={
          <Trans
            i18nKey="users.user-delete-confirm"
            components={{ italic: <i /> }}
            values={{ userName: `${user.firstName} ${user.lastName}` }}
          />
        }
        confirmAction={() => handleDelete(user.email)}
        loading={loading}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsButton: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  },
}));
