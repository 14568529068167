import { useState, useRef, useEffect } from 'react';
import { isEmpty, inRange } from 'lodash';
import { Stage, Layer } from 'react-konva';
import Konva from 'konva';
import PolygonAnnotation from 'components/KonvaShapes/Polygon/PolygonAnnotation';
import {
  clickOutOfBounds,
  getMousePos,
  isDefaultZone,
  isMouseOverShape,
} from 'components/KonvaShapes/Polygon/utils';
import { ActiveZonesState, ZoneModes } from 'components/KonvaShapes/types';
import {
  convertToPX,
  convertTo01,
  drawPolygons,
} from 'components/KonvaShapes/Polygon/helpers';
import { useKeyDown } from 'components/KonvaShapes/Polygon/useKeyDown';
import { stagePadding, layerOffset } from 'components/KonvaShapes/constants';
import { PolygonZoneOptions } from 'components/KonvaShapes/Polygon/PolygonZoneOptions';
import { useAuth } from 'context/providers/AuthProvider';
import { toggleSelect } from 'components/KonvaShapes/helpers';

export enum PolygonZoneTypes {
  INCLUSION = 'INCLUSION',
  EXCLUSION = 'EXCLUSION',
}

type PolygonZonesProps = {
  activeZonesState?: ActiveZonesState;
  width: number;
  height: number;
  type: PolygonZoneTypes;
};

export type Polygon = {
  points: number[][];
  isComplete: boolean;
};

const PolygonZones = ({
  activeZonesState,
  width,
  height,
  type,
}: PolygonZonesProps) => {
  const { isGuest } = useAuth();
  const stageRef = useRef<null | Konva.Stage>(null);
  const originalWidth = useRef(width);
  const originalHeight = useRef(height);
  const [allPolygons, setAllPolygons] = useState<Polygon[]>([]);
  const [polygonInProgress, setPolygonInProgress] = useState<Polygon>({
    points: [],
    isComplete: false,
  });
  const [selectedPolygons, setSelectedPolygons] = useState<number[]>([]);
  const [intersectingSegments, setIntersectingSegments] = useState<number[][]>(
    []
  );
  const [mode, setMode] = useState(ZoneModes.DRAW);
  const [startClickPos, setStartClickPos] = useState({ x: 0, y: 0 });

  let scaleX = 1;
  let scaleY = 1;

  if (width && originalWidth.current) {
    scaleX = width / originalWidth.current;
  }

  if (height && originalHeight.current) {
    scaleY = height / originalHeight.current;
  }

  useEffect(() => {
    const container = stageRef.current!.container();
    if (mode === ZoneModes.DRAW && allPolygons.length < 10 && !isGuest) {
      container.style.cursor = 'crosshair';
    } else {
      container.style.cursor = 'default';
    }
  }, [mode, allPolygons.length, activeZonesState?.activeZones, isGuest]);

  useEffect(() => {
    if (!activeZonesState) return;

    if (
      isDefaultZone(activeZonesState?.activeZones) &&
      type === PolygonZoneTypes.INCLUSION
    ) {
      setAllPolygons([]);
    } else {
      const formatted = convertToPX(
        activeZonesState.activeZones,
        width,
        height,
        scaleX,
        scaleY
      );
      setAllPolygons(formatted);
    }

    setPolygonInProgress({ points: [], isComplete: false });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!activeZonesState) {
      return;
    }

    const formatted = convertTo01(allPolygons, width, height, scaleX, scaleY);
    activeZonesState.setActiveZones(formatted);
    // eslint-disable-next-line
  }, [allPolygons]);

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    setStartClickPos({ x: event.evt.x, y: event.evt.y });
  };

  const handleMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (!stageRef.current || isGuest) return;

    const clickPosX = event.evt.x;
    const clickPosY = event.evt.y;

    if (
      !inRange(clickPosX, startClickPos.x - 2, startClickPos.x + 2) ||
      !inRange(clickPosY, startClickPos.y - 2, startClickPos.y + 2)
    ) {
      return;
    }

    const { clickOnShape, shapeIndex } = isMouseOverShape(stageRef);

    if (mode === ZoneModes.DRAW) {
      const mousePos = getMousePos(stageRef, scaleX, scaleY);

      if (!mousePos) return;
      if (clickOutOfBounds(stageRef)) return;
      if (allPolygons.length >= 10) return;

      drawPolygons(
        stageRef,
        allPolygons,
        setAllPolygons,
        polygonInProgress,
        setPolygonInProgress,
        mousePos,
        setIntersectingSegments
      );
    } else if (mode === ZoneModes.SELECT && clickOnShape) {
      if (shapeIndex === undefined) return;
      toggleSelect(shapeIndex, selectedPolygons, setSelectedPolygons);
    }
  };

  const handleRemoveSelected = () => {
    if (isEmpty(selectedPolygons)) return;

    const polygonsCopy = [...allPolygons];

    const filtered = polygonsCopy.filter((polygon, index) => {
      return !selectedPolygons.includes(index);
    });

    setSelectedPolygons([]);
    setAllPolygons(filtered);
  };

  useKeyDown(handleRemoveSelected);

  const handleRemoveAll = () => {
    setSelectedPolygons([]);
    setAllPolygons([]);
    setPolygonInProgress({ points: [], isComplete: false });
    setIntersectingSegments([]);
    activeZonesState?.setActiveZones([]);
  };

  return (
    <>
      <Stage
        width={width + stagePadding}
        height={height + stagePadding}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        ref={stageRef}
        scaleX={scaleX}
        scaleY={scaleY}
      >
        <Layer offset={{ x: -layerOffset / scaleX, y: -layerOffset / scaleY }}>
          {allPolygons.map((polygon, index) => {
            const isSelected = selectedPolygons.find(
              (element) => element === index
            );
            return (
              <PolygonAnnotation
                key={index}
                polygonIndex={index}
                name="polygon"
                polygonData={polygon}
                stageRef={stageRef}
                scaleX={scaleX}
                scaleY={scaleY}
                selected={isSelected !== undefined}
                mode={mode}
                allPolygons={allPolygons}
                setAllPolygons={setAllPolygons}
              />
            );
          })}
          <PolygonAnnotation
            name="polygonInProgress"
            polygonData={polygonInProgress}
            stageRef={stageRef}
            scaleX={scaleX}
            scaleY={scaleY}
            intersectingSegments={intersectingSegments}
            setIntersectingSegments={setIntersectingSegments}
            mode={mode}
            allPolygons={allPolygons}
            setAllPolygons={setAllPolygons}
          />
        </Layer>
      </Stage>
      <PolygonZoneOptions
        mode={mode}
        setMode={setMode}
        type={type}
        setSelectedPolygons={setSelectedPolygons}
        setPolygonInProgress={setPolygonInProgress}
        handleRemoveAll={handleRemoveAll}
        handleRemoveSelected={handleRemoveSelected}
        allPolygons={allPolygons}
        selectedPolygons={selectedPolygons}
      />
    </>
  );
};

export default PolygonZones;
