import { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useAuth } from 'context/providers/AuthProvider';
import { StreamsTable } from 'views/StreamsView/StreamsTable/StreamsTable';
import { AddStreamButton } from 'views/StreamsView/AddStream/AddStreamButton';
import { AddStreamDialog } from 'views/StreamsView/AddStream/AddStreamDialog';
import Toast, { AlertSeverity } from 'components/Alert/Toast';
import { getStreams } from 'api/streamApi';
import { useLicense } from 'context/providers/LicenseProvider';
import CircularLoader from 'components/Progress/CircularLoader';
import { identifyConnectingStreams } from 'views/StreamsView/StreamsTable/helpers';
import { OpenVideoWallButton } from 'views/StreamsView/OpenVideoWallButton';
import { Stream } from 'types/streamTypes';
import { useStreamsAnalytics } from 'context/providers/StreamsAnalyticsProvider';
import { isEmpty } from 'lodash';

const StreamsView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { fetchLicense } = useLicense();
  const { loggedInUser, isAdmin, isGuest } = useAuth();
  const { updateSavedAnalytics } = useStreamsAnalytics();
  const [streams, setStreams] = useState([]);
  const [streamsLoading, setStreamsLoading] = useState(true);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState<{
    open: boolean;
    status: AlertSeverity;
    msg: string;
  }>({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
  const refreshTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  const clearLocalStorage = useCallback(
    (currentStreams: Stream[]) => {
      const currentStreamIds = currentStreams.map((stream) => stream.id);

      const storageStreams = localStorage.getItem('analyticsPerStream');
      const updatedStreams = storageStreams && JSON.parse(storageStreams);

      if (!isEmpty(updatedStreams)) {
        for (const stream in updatedStreams) {
          if (!currentStreamIds.includes(stream)) {
            delete updatedStreams[stream];
          }
        }
        updateSavedAnalytics(updatedStreams);
      }
    },
    [updateSavedAnalytics]
  );

  const refreshStreams = useCallback(() => {
    getStreams()
      .then((res) => {
        setStreams(res);
        const streamsConnecting = identifyConnectingStreams(res);
        if (!streamsConnecting) {
          clearInterval(refreshTimeout.current);
        }
      })
      .catch(() => {});
  }, []);

  const fetchStreams = useCallback(() => {
    clearInterval(refreshTimeout.current);
    setStreamsLoading(true);
    fetchLicense();
    getStreams()
      .then((res) => {
        setStreams(res);
        setStreamsLoading(false);
        clearLocalStorage(res);
        const streamsConnecting = identifyConnectingStreams(res);
        if (streamsConnecting) {
          refreshTimeout.current = setInterval(() => {
            refreshStreams();
          }, 3000);
        }
      })
      .catch(() => {
        setStreamsLoading(false);
      });
  }, [fetchLicense, refreshStreams, clearLocalStorage]);

  const handleOpenVideoWall = () => {
    navigate('/streams/video-wall');
  };

  useEffect(() => {
    fetchStreams();
    return () => {
      clearInterval(refreshTimeout.current);
    };
  }, [fetchStreams, loggedInUser]);

  if (streamsLoading) {
    return <CircularLoader className={classes.loader} />;
  }

  return (
    <Box className={classes.container}>
      <div className={clsx(classes.row, { [classes.guestRow]: isGuest })}>
        {isAdmin && (
          <>
            <AddStreamButton onClick={() => setAddDialogOpen(true)} />
            <AddStreamDialog
              open={addDialogOpen}
              onClose={() => setAddDialogOpen(false)}
              fetchStreams={fetchStreams}
              setFeedback={setFeedback}
            />
          </>
        )}
        <OpenVideoWallButton onClick={handleOpenVideoWall} />
      </div>
      <StreamsTable
        streams={streams}
        fetchStreams={fetchStreams}
        setFeedback={setFeedback}
      />
      {feedback.open && (
        <Toast
          setState={setFeedback}
          severity={feedback.status}
          msg={feedback.msg}
          noTranslation={feedback.msg.startsWith(
            'Unable to verify connection to stream'
          )}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1800px',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(4, 0, 3, 0),
  },
  guestRow: {
    justifyContent: 'flex-end',
  },
  loader: {
    paddingTop: '30vh',
  },
}));

export default StreamsView;
