import { createTheme } from '@material-ui/core/styles';
import { Shadows } from '@material-ui/core/styles/shadows';

declare module '@material-ui/core/styles/createPalette' {
  interface SimplePaletteColorOptions {
    darkmode?: string;
    background?: string;
    hover?: string;
    border?: string;
    background2?: string;
  }

  interface PaletteColor {
    darkmode?: string;
    background?: string;
    hover?: string;
    border?: string;
    background2?: string;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#030101',
      light: '#0E0E0E',
      background: '#F1F1F1',
      hover: '#66393b',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#AC2916',
      light: '#cc7366',
      dark: '#711E10',
      contrastText: '#030101',
      background: 'rgba(172, 41, 22, 0.15)',
      hover: 'rgba(186, 82, 67, 0.05)',
      background2: 'rgba(186, 82, 67, 0.7)',
    },
    error: {
      main: '#c9363f',
      dark: '#a12d34',
    },
    success: {
      main: '#33965e',
      dark: '#28784a',
    },
    warning: {
      main: '#FF9C54',
      dark: '#946200',
    },
    text: {
      primary: '#302424',
      secondary: '#706f6f',
      disabled: 'rgba(0, 0, 0, 0.26)',
    },
    background: {
      default: '#FFFEFE',
    },
    divider: '#D9DBE9',
    grey: {
      200: 'rgba(0, 0, 0, 0.26)',
      300: 'rgba(0, 0, 0, 0.4)',
      400: '#C4C4C4',
      500: '#F9F9FA',
      600: 'rgba(48, 36, 36, 0.11)',
      700: '#6E7191',
      800: '#EFF0F7',
      900: '#14142B',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: '36px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    h2: {
      fontSize: '28px',
      lineHeight: '30px',
      fontWeight: 500,
    },
    h3: {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.75px',
      fontWeight: 600,
    },
    h4: {
      fontSize: '24px',
    },
    subtitle1: {
      fontSize: '15px',
      lineHeight: '28px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    button: {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.75px',
      fontWeight: 600,
      textTransform: 'none',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
    },
  },
  shadows: [
    'none',
    '0px 2px 10px rgba(172, 41, 22, 0.3)',
    '0px 2px 10px 2px rgba(194, 97, 77, 0.6)',
    '0px 0px 15px 8px rgba(194, 97, 77, 0.5), 0px 0px 15px 10px rgba(255, 254, 255, 0.5)',
    '0px 0px 10px 4px rgba(201, 54, 63, 1)',
    '0px 0px 5px 5px rgb(199, 30, 41)',
    'none',
    '0px 0px 10px 8px rgba(194, 97, 77, 0.6), 0px 0px 10px 2px rgba(255, 254, 255, 0.5)',
  ].concat(Array(21).fill('none')) as Shadows,
  shape: {
    borderRadius: 6,
  },
  overrides: {
    MuiTouchRipple: {
      child: {
        backgroundColor: '#711E10',
      },
    },
    MuiLink: {
      underlineHover: {
        '&:focus, &:hover, &:visited, &:link, &:active': {
          textDecoration: 'none',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        '&$active': {
          '&& $icon': {
            color: '#2DD3E6',
          },
        },
      },
      icon: {
        opacity: 0.2,
        transition: 'none',
      },
    },
  },
});

export default theme;
