import { useState, ChangeEvent } from 'react';
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PLFlag } from 'icons/flags/pl.svg';
import { ReactComponent as ENFlag } from 'icons/flags/en.svg';
import { ReactComponent as ESFlag } from 'icons/flags/es.svg';
import { ReactComponent as PTFlag } from 'icons/flags/pt.svg';

import i18n from 'translations/i18n';

const languages = [
  { label: 'english', value: 'en', icon: <ENFlag width={20} /> },
  { label: 'polish', value: 'pl', icon: <PLFlag width={20} /> },
  { label: 'spanish', value: 'es', icon: <ESFlag width={20} /> },
  { label: 'portugese', value: 'pt', icon: <PTFlag width={20} /> },
];

const initLang = (language: string | null) => {
  if (!language) {
    return 'en';
  }
  if (language.startsWith('pl')) {
    return 'pl';
  }
  if (language.startsWith('es')) {
    return 'es';
  }
  if (language.startsWith('pt')) {
    return 'pt';
  }
  return 'en';
};

const LangSelect = () => {
  const language = localStorage.getItem('i18nextLng');

  const [currLang, setCurrLang] = useState(initLang(language));
  const classes = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line
  const handleChange = (event: ChangeEvent<any>) => {
    setCurrLang(event.target.value as string);
    i18n.changeLanguage(event.target.value as string);
  };

  return (
    <FormControl fullWidth size="small">
      <Select
        value={currLang}
        onChange={handleChange}
        className={classes.select}
        variant="outlined"
      >
        {languages.map((item) => {
          return (
            <MenuItem value={item.value} key={item.value}>
              <div className={classes.listItemContent}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{t(`${item.label}`)}</ListItemText>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    border: `1px solid ${theme.palette.background.default}`,
    color: theme.palette.background.default,
    letterSpacing: '0.5px',

    '& .MuiSelect-icon': {
      color: theme.palette.background.default,
    },
  },
  listItemContent: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
  },
}));

export default LangSelect;
