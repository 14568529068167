import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import { getAnalyticsSettings, getEventTimers } from 'api/analyticsApi';
import { AnalyticsSettings, EventTimers } from 'types/analyticsTypes';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import {
  DefaultSettings,
  DefaultFallSettings,
  DefaultFireTunnelSettings,
  DefaultPPESettings,
  DefaultFlareInspectionSettings,
  DefaultIntruderDetectionSettings,
} from 'context/providers/defaultAnalyticsSettings';
import { convertToFrontedValues } from 'api/helpers';
import { useStreamData } from 'context/providers/StreamDataProvider';

export type AnalyticsSettingsContextValue = {
  analyticsSettings: AnalyticsSettings | undefined;
  setAnalyticsSettings: Dispatch<SetStateAction<AnalyticsSettings | undefined>>;
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
  fetchAnalyticsSettings: () => void;
};

export type AnalyticsSettingsProviderProps = {
  children: ReactNode;
};

const defaultValue: AnalyticsSettingsContextValue = {
  analyticsSettings: DefaultSettings,
  setAnalyticsSettings: () => {},
  eventTimers: {
    delayTimeSeconds: 0,
    alarmingSettings: {
      isEnabled: false,
      nextEventCooldownSeconds: 0,
    },
    reportingSettings: {
      isEnabled: false,
      analysedTimeFrameSeconds: 60,
      cumulativeEventOccurrenceTime: 1,
      analysisIntervalSeconds: 60,
    },
  },
  setEventTimers: () => {},
  fetchAnalyticsSettings: () => {},
};

const chooseDefaultValue = (selectedAnalytics: AnalyticsTypes | null) => {
  switch (selectedAnalytics) {
    case AnalyticsTypes.FallDetection:
      return DefaultFallSettings;
    case AnalyticsTypes.FireSmokeTunnel:
      return DefaultFireTunnelSettings;
    case AnalyticsTypes.PPE:
      return DefaultPPESettings;
    case AnalyticsTypes.FlareInspection:
      return DefaultFlareInspectionSettings;
    case AnalyticsTypes.IntruderDetection:
      return DefaultIntruderDetectionSettings;
    default:
      return undefined;
  }
};

const AnalyticsSettingsContext = createContext(defaultValue);

const AnalyticsSettingsProvider: FC<AnalyticsSettingsProviderProps> = (
  props: AnalyticsSettingsProviderProps
) => {
  const { selectedAnalytics } = useStreamData();
  const { streamId } = useParams<{ streamId: string }>();
  const [analyticsSettings, setAnalyticsSettings] = useState(
    chooseDefaultValue(selectedAnalytics)
  );
  const [eventTimers, setEventTimers] = useState(defaultValue.eventTimers);

  const fetchAnalyticsSettings = useCallback(() => {
    if (!streamId || !selectedAnalytics) return;

    Promise.all([
      getAnalyticsSettings(streamId, selectedAnalytics),
      getEventTimers(streamId, selectedAnalytics),
    ]).then((responses) => {
      const settings: AnalyticsSettings = responses[0];
      const timers: EventTimers = responses[1];

      const convertedTimers = convertToFrontedValues(timers);

      setAnalyticsSettings(settings);
      setEventTimers(convertedTimers);
    });
  }, [streamId, selectedAnalytics]);

  useEffect(() => {
    fetchAnalyticsSettings();
  }, [fetchAnalyticsSettings]);

  return (
    <AnalyticsSettingsContext.Provider
      value={{
        analyticsSettings,
        setAnalyticsSettings,
        eventTimers,
        setEventTimers,
        fetchAnalyticsSettings,
      }}
      {...props}
    />
  );
};

const useAnalyticsSettings = () => useContext(AnalyticsSettingsContext);
export {
  AnalyticsSettingsProvider,
  AnalyticsSettingsContext,
  useAnalyticsSettings,
};
