import React from 'react';
import ReactDOM from 'react-dom/client';
import Root from 'Root';
import AppProviders from 'context/AppProviders';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'translations/i18n';

async function enableMocking() {
  // if (process.env.NODE_ENV !== 'development') {
  //   return;
  // }
  // const { worker } = await import('./mocks/browser');
  // return worker.start({
  //   onUnhandledRequest: 'bypass',
  // });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

enableMocking().then(() => {
  root.render(
    <AppProviders>
      <Root />
    </AppProviders>
  );
});
