import { forwardRef } from 'react';
import { makeStyles, IconButton, IconButtonProps } from '@material-ui/core';
import clsx from 'clsx';

const CustomIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (props, ref) => {
    const classes = useStyles();
    const { className, children, ...rest } = props;

    return (
      <IconButton
        className={clsx(className, classes.iconButton)}
        ref={ref}
        {...rest}
      >
        {children}
      </IconButton>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  iconButton: {
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[0],
    borderRadius: '6px',

    '&:hover': {
      backgroundColor: theme.palette.secondary.background,
    },
    '&.Mui-disabled': {
      boxShadow: 'none',
    },
  },
}));

export default CustomIconButton;
