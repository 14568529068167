import { ReactNode, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { TableRow, makeStyles } from '@material-ui/core';

type TableRowProps = {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  clickable?: boolean;
  id?: string;
};

const TableBodyRow = ({
  children,
  onClick,
  clickable,
  ...props
}: TableRowProps) => {
  const classes = useStyles();

  return (
    <TableRow
      className={clsx(classes.tableRow, {
        [classes.clickableRow]: onClick || clickable,
      })}
      onClick={onClick}
      {...props}
    >
      {children}
    </TableRow>
  );
};

const useStyles = makeStyles((theme) => ({
  tableRow: {
    height: '35px',

    '&:nth-child(even)': {
      backgroundColor: theme.palette.background.default,
    },
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  clickableRow: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export default TableBodyRow;
