import { useEffect, useState } from 'react';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLicense } from 'context/providers/LicenseProvider';

const dateFormat: DateTimeFormatOptions = {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  minute: '2-digit',
  hour: '2-digit',
  timeZoneName: 'short',
};

export const LicenseExpInfo = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { licenseExpiration, licenseExpired } = useLicense();
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    if (!licenseExpiration) {
      return;
    }
    setFormattedDate(
      DateTime.fromISO(licenseExpiration).toLocaleString(dateFormat)
    );
  }, [licenseExpiration]);

  if (!licenseExpiration && !licenseExpired) {
    return null;
  }

  return (
    <div className={classes.container}>
      {licenseExpired ? (
        <Typography className={classes.expired} align="center">
          {t('licenses.license-expired')}
        </Typography>
      ) : (
        <Typography align="center">
          {t('licenses.license-exp-date')} {formattedDate}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  expired: {
    color: theme.palette.error.main,
  },
  container: {
    marginBottom: theme.spacing(2),
    color: theme.palette.background.default,
  },
}));
