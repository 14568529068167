import { apiOrigin } from 'api/config/interceptors';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { AnalyticsSettings, EventTimers } from 'types/analyticsTypes';

export type AvailableAnalytics =
  | { [key in AnalyticsTypes]: number }
  | Record<string, never>;

export const getAvailableAnalytics: () => Promise<{
  availableAnalyticsLicenses: AvailableAnalytics;
}> = () => {
  return apiOrigin.get(`/available-analytics`).then((res) => {
    return res.data;
  });
};

export const getAnalyticsSettings = (
  streamId: string,
  analyticsType: AnalyticsTypes
) => {
  return apiOrigin
    .get(`/stream/${streamId}/analytics/${analyticsType}`)
    .then((res) => {
      return res.data;
    });
};

export const changeAnalyticsSettings = (
  streamId: string,
  values: AnalyticsSettings
) => {
  return apiOrigin.put(`/stream/${streamId}/analytics`, values).then((res) => {
    return res.data;
  });
};

export const toggleAnalyticsOnStream = (
  streamId: string,
  analyticsType: AnalyticsTypes,
  value: { enabled: boolean }
) => {
  return apiOrigin
    .patch(`/stream/${streamId}/analytics/${analyticsType}`, value)
    .then((res) => {
      return res.data;
    });
};

export const getEventTimers = (
  streamId: string,
  analyticsType: AnalyticsTypes
) => {
  return apiOrigin
    .get(`/stream/${streamId}/analytics/${analyticsType}/settings`)
    .then((res) => {
      return res.data;
    });
};

export const changeEventTimers = (
  streamId: string,
  analyticsType: AnalyticsTypes,
  timers: EventTimers
) => {
  return apiOrigin
    .put(`/stream/${streamId}/analytics/${analyticsType}/settings`, timers)
    .then((res) => {
      return res.data;
    });
};

export const getAnalyticsMetaData = (
  streamId: string,
  analyticsType: AnalyticsTypes
) => {
  return apiOrigin
    .get(`/stream/${streamId}/analytics/${analyticsType}/metadata`)
    .then((res) => {
      return res.data;
    });
};

export const sendTestEvents = (
  streamId: string,
  analyticsType: AnalyticsTypes
) => {
  return apiOrigin
    .post(`/camera/${streamId}/test-event/${analyticsType}`)
    .then((res) => {
      return res.data;
    });
};
