import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import { LoginForm } from 'views/LoginView/LoginForm';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import CircularLoader from 'components/Progress/CircularLoader';
import { FormikContextType } from 'formik';
import { LoginValues } from 'views/LoginView/LoginView';

type DemoLoginViewProps = {
  formik: FormikContextType<LoginValues>;
  loading: boolean;
};

export const LoginContent = ({ loading, formik }: DemoLoginViewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div>
      <img
        src="logo_vertical.png"
        alt="noctuai logo"
        className={classes.logo}
      />
      <form onSubmit={formik.handleSubmit}>
        <LoginForm formik={formik} />
        <ButtonPrimary
          type="submit"
          id="submit-button"
          className={classes.loginButton}
          fullWidth
        >
          {loading ? <CircularLoader /> : t('login-view.login')}
        </ButtonPrimary>
      </form>
      <a href="https://noctuai.com/wdtou/" target="_blank" rel="noreferrer">
        <Typography className={classes.footer}>
          {t('login-view.terms-of-use')}
        </Typography>
      </a>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '160px',
    marginBottom: theme.spacing(5),
  },
  loginButton: {
    margin: theme.spacing(2, 0, 4, 0),
    fontSize: '16px',
  },
  footer: {
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(2),
  },
}));
