import { InputAdornment, IconButton, makeStyles } from '@material-ui/core';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

type ToggleVisibilityProps = {
  showPassword: boolean;
  onToggle: () => void;
};

export const ToggleVisibility = ({
  showPassword,
  onToggle,
}: ToggleVisibilityProps) => {
  const classes = useStyles();

  return (
    <InputAdornment position="end">
      <IconButton onClick={onToggle} className={classes.iconButton}>
        {showPassword ? (
          <VisibilityOutlinedIcon className={classes.icon} />
        ) : (
          <VisibilityOffOutlinedIcon className={classes.icon} />
        )}
      </IconButton>
    </InputAdornment>
  );
};

const useStyles = makeStyles(() => ({
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: '1.6rem',
    height: '1.6rem',
  },
}));
