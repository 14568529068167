import { makeStyles, ButtonProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import AddIcon from '@material-ui/icons/Add';

export const AddUserButton = ({ onClick }: ButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ButtonPrimary
      startIcon={<AddIcon />}
      className={classes.button}
      onClick={onClick}
      id="add-user-button"
    >
      {t('users.add')}
    </ButtonPrimary>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    height: '44px',
    marginLeft: theme.spacing(2),
  },
}));
