import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetectionBox } from 'views/StreamDetails/Preview/components/DetectionBox';
import { ReactComponent as Fall } from 'icons/fall.svg';
import { FallStreamData } from 'types/analytics/FallDetection';

type FallDetectionMetaDataProps = {
  metaData: FallStreamData | undefined;
};

export const FallDetectionMetaData = ({
  metaData,
}: FallDetectionMetaDataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const fallDetected = metaData?.isFallDetected;

  return (
    <div className={classes.detections}>
      <DetectionBox
        label={
          fallDetected
            ? t('stream-preview.fall.fall-detected')
            : t('stream-preview.fall.fall-not-detected')
        }
        Icon={Fall}
        isDetected={fallDetected}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  detections: {
    display: 'flex',
  },
}));
