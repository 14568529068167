import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import DialogActions from 'components/Dialog/DialogActions';
import CheckboxWithLabel from 'components/Checkbox/CheckboxWithLabel';
import { DoublePassInput } from 'views/PassChange/DoublePassInput';
import { ResetValues } from 'views/UsersView/ResetPassword/ResetPassword';
import { useAuth } from 'context/providers/AuthProvider';

type ResetPasswordFormProps = {
  loading: boolean;
  handleClose: () => void;
  formik: FormikContextType<ResetValues>;
  setPassword: Dispatch<SetStateAction<string>>;
  userEmail: string;
};

export const ResetPasswordForm = ({
  formik,
  loading,
  handleClose,
  setPassword,
  userEmail,
}: ResetPasswordFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loggedInUser } = useAuth();

  const notIsSelf = userEmail !== (loggedInUser && loggedInUser.sub);

  return (
    <form onSubmit={formik.handleSubmit}>
      <DoublePassInput
        formik={formik}
        setPassword={setPassword}
        firstInputClass={classes.firstInput}
        secondInputClass={notIsSelf ? classes.secondInput : classes.bigMargin}
      />
      {notIsSelf && (
        <CheckboxWithLabel
          name="mustChangePassword"
          label={t('users.must-change-password')}
          disabled={loading}
          value={formik.values.mustChangePassword}
          checked={formik.values.mustChangePassword}
          onChange={formik.handleChange}
          className={classes.checkbox}
        />
      )}
      <DialogActions
        onCancel={handleClose}
        isLoading={loading}
        isValid={formik.dirty && formik.isValid}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  checkbox: {
    marginBottom: theme.spacing(3),
  },
  firstInput: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  secondInput: {
    marginBottom: theme.spacing(3),
  },
  bigMargin: {
    marginBottom: theme.spacing(5),
  },
}));
