import { FallDetectionSettings } from 'types/analytics/FallDetection';
import { FireSmokeSettings } from 'types/analytics/fire/FireSmoke';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { PPESettings } from 'types/analytics/PPE';
import { FlareInspectionSettings } from 'types/analytics/fire/FlareInspection';
import { IntruderDetectionSettings } from 'types/analytics/IntruderDetection';

export const DefaultFallSettings: FallDetectionSettings = {
  type: AnalyticsTypes.FallDetection,
  detectorThreshold: 0.1,
  threshold: 0.1,
  exclusionZones: [],
  inclusionZones: [],
};

export const DefaultFireTunnelSettings: FireSmokeSettings = {
  type: AnalyticsTypes.FireSmokeTunnel,
  fireThreshold: 0.1,
  smokeThreshold: 0.1,
  roi: [],
};

export const DefaultPPESettings: PPESettings = {
  type: AnalyticsTypes.FallDetection,
  detectorThreshold: 0.1,
  vestThreshold: 0.1,
  hardHatThreshold: 0.1,
  exclusionZones: [],
  inclusionZones: [],
  trackedItems: {
    isVestTracked: false,
    isHardHatTracked: false,
  },
};

export const DefaultFlareInspectionSettings: FlareInspectionSettings = {
  type: AnalyticsTypes.FlareInspection,
  fireThreshold: 0.1,
  smokeThreshold: 0.1,
  monitoringZones: [],
};

export const DefaultIntruderDetectionSettings: IntruderDetectionSettings = {
  type: AnalyticsTypes.FlareInspection,
  intruderDetectionThreshold: 0.1,
  inclusionZones: [],
};

export const DefaultSettings =
  DefaultFallSettings ||
  DefaultFireTunnelSettings ||
  DefaultPPESettings ||
  DefaultFlareInspectionSettings ||
  DefaultIntruderDetectionSettings;
