import { makeStyles } from '@material-ui/core';
import { updateZone } from 'api/settingsApi';
import { AlertSeverity } from 'components/Alert/Toast';
import DialogBase from 'components/Dialog/Dialog';
import { useFormik } from 'formik';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CameraZone } from 'types/settingsTypes';
import { zoneValidationSchema } from 'validation/zoneValidationSchema';
import { ZoneForm } from 'views/SettingsView/MonitoringZonesSettings/ZoneForm';

type EditZoneDialogProps = {
  open: boolean;
  onClose: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
  fetchZones: () => void;
  zone: CameraZone;
};

export type EditZoneValues = {
  name: string;
  description: string;
};

export const EditZoneDialog = ({
  setFeedback,
  fetchZones,
  zone,
  ...props
}: EditZoneDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: zone.name,
    description: zone.description,
  };

  const handleClose = () => {
    props.onClose();
    setTimeout(() => {
      formik.resetForm();
    }, 100);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: EditZoneValues) => {
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      updateZone(zone.id, values)
        .then(() => {
          setLoading(false);
          fetchZones();
          handleClose();
          setFeedback({
            open: true,
            status: AlertSeverity.SUCCESS,
            msg: 'edit-zone-success',
          });
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description
              ? error.response.data.description
              : 'general-error',
          });
        });
    },
    validationSchema: zoneValidationSchema,
  });

  return (
    <DialogBase
      title={t('settings.zones.edit')}
      className={classes.dialog}
      id="edit-zone-dialog"
      disabled={loading}
      onClose={handleClose}
      open={props.open}
    >
      <ZoneForm formik={formik} loading={loading} handleClose={handleClose} />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: '400px',
    minWidth: '350px',
  },
}));
