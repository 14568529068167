import { MultipleSelectItem } from 'components/Input/Select/MultipleSelect';
import { Dayjs } from 'dayjs';
import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { IgnoredStatus, ResolvedStatus, TimeUnit } from 'types/alarmTypes';

export type AlarmFiltersType = {
  timeframe: { from: Dayjs | null; to: Dayjs | null };
  fromLast: {
    number: string | number;
    unit: string;
  };
  analytics: MultipleSelectItem[];
  zones: MultipleSelectItem[];
  streams: MultipleSelectItem[];
  ignoredStatus: IgnoredStatus;
  resolvedStatus: ResolvedStatus;
  favouriteOnly: boolean;
  mode: {
    liveMode: boolean;
    lastAlarms: boolean;
    timeframe: boolean;
  };
};

export type AlarmFiltersContextValue = {
  alarmFilters: AlarmFiltersType;
  setAlarmFilters: Dispatch<SetStateAction<AlarmFiltersType>>;
};

export type AlarmFiltersProviderProps = {
  children?: ReactNode;
};

const defaultValue: AlarmFiltersContextValue = {
  alarmFilters: {
    timeframe: { from: null, to: null },
    fromLast: { number: 1, unit: TimeUnit.MINUTES },
    analytics: [],
    zones: [],
    streams: [],
    ignoredStatus: IgnoredStatus.UNIGNORED,
    resolvedStatus: ResolvedStatus.UNRESOLVED,
    favouriteOnly: false,
    mode: {
      liveMode: true,
      lastAlarms: false,
      timeframe: false,
    },
  },
  setAlarmFilters: () => {},
};

const AlarmFiltersContext = createContext(defaultValue);

const AlarmFiltersProvider: FC<AlarmFiltersProviderProps> = (
  props: AlarmFiltersProviderProps
) => {
  const [alarmFilters, setAlarmFilters] = useState(defaultValue.alarmFilters);

  return (
    <AlarmFiltersContext.Provider
      value={{
        alarmFilters,
        setAlarmFilters,
      }}
      {...props}
    />
  );
};

const useAlarmFilters = () => useContext(AlarmFiltersContext);
export { AlarmFiltersProvider, AlarmFiltersContext, useAlarmFilters };
