import { makeStyles, ButtonProps, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';

export const OpenVideoWallButton = ({ onClick, disabled }: ButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      variant="text"
      startIcon={<QueuePlayNextIcon />}
      className={classes.button}
      onClick={onClick}
      id="video-wall-button"
      disabled={disabled}
    >
      {t('streams.open-video-wall')}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    height: '44px',
    color: theme.palette.secondary.main,
  },
}));
