import { RefObject, forwardRef } from 'react';
import clsx from 'clsx';
import { Theme, makeStyles } from '@material-ui/core';
import { NoPreview } from 'views/StreamDetails/Preview/NoPreview';

type JPGPreviewProps = {
  frame: string;
  loading: boolean;
  isDetected?: boolean;
  maxWidth?: string;
  minWidth?: string;
  hasBorder?: boolean;
  className?: string;
  ref: RefObject<HTMLDivElement>;
};

export const JPGPreview = forwardRef<HTMLDivElement, JPGPreviewProps>(
  (
    {
      frame,
      loading,
      isDetected,
      maxWidth,
      minWidth,
      className,
      hasBorder = false,
    },
    ref
  ) => {
    const classes = useStyles({ maxWidth, minWidth });

    return (
      <div
        className={clsx(className, classes.container, {
          [classes.detected]: isDetected && hasBorder,
          [classes.border]: hasBorder,
        })}
        ref={ref}
      >
        {frame ? (
          <img
            src={frame}
            className={classes.img}
            alt="stream-preview"
            draggable="false"
          ></img>
        ) : (
          <NoPreview loading={loading} />
        )}
      </div>
    );
  }
);

type StyleProps = {
  maxWidth: string | undefined;
  minWidth: string | undefined;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  img: {
    backgroundColor: 'black',
    width: '100%',
    aspectRatio: '16/9',
    verticalAlign: 'bottom',
  },
  container: (props) => ({
    maxWidth: props.maxWidth ? props.maxWidth : '1400px',
    minWidth: props.minWidth ? props.minWidth : '635px',
    position: 'relative',
    borderRadius: '4px 4px 0px 0px',
    aspectRatio: '16/9',
  }),
  border: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  detected: {
    border: `2px solid ${theme.palette.primary.hover}`,
  },
}));
