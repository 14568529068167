import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';

export enum MessageType {
  NEW = 'NEW',
  UPDATE = 'UPDATE',
}

export enum ResolvedStatus {
  RESOLVED = 'RESOLVED',
  UNRESOLVED = 'UNRESOLVED',
  ALL = 'ALL',
}

export enum IgnoredStatus {
  IGNORED = 'IGNORED',
  UNIGNORED = 'UNIGNORED',
  ALL = 'ALL',
}

export enum AlarmMediaStatus {
  AVAILABLE = 'AVAILABLE',
  NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum EventType {
  MISSING_HAT = 'MISSING_HAT',
  MISSING_VEST = 'MISSING_VEST',
  FALLEN = 'FALLEN',
  FIRE = 'FIRE',
  SMOKE = 'SMOKE',
  FLARE_FIRE = 'FLARE_FIRE',
  FLARE_SMOKE = 'FLARE_SMOKE',
  FIRE_TUNNEL = 'FIRE_TUNNEL',
  SMOKE_TUNNEL = 'SMOKE_TUNNEL',
  BED_EXIT = 'BED_EXIT',
  INTRUDER_DETECTION = 'INTRUDER_DETECTION',
}

export enum TimeUnit {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export type Alarm = {
  messageType: MessageType;
  alarmId: string;
  streamId: string;
  streamName: string;
  tags: {
    tagId: string;
    tagName: string;
  }[];
  eventType: EventType;
  startTimestamp: string;
  endTimestamp: string;
  analyticsType: AnalyticsTypes;
  isFavourite: boolean;
  isIgnored: boolean;
  isResolved: boolean;
  thumbnail: {
    status: AlarmMediaStatus;
    failureReason?: string;
  };
  image: {
    status: AlarmMediaStatus;
    failureReason?: string;
  };
  video: {
    status: AlarmMediaStatus;
    failureReason?: string;
  };
};

export type GetAlarmsDTO = {
  streamIds: string[];
  analyticsTypes: AnalyticsTypes[];
  tagIds: string[];
  favouriteOnly: boolean;
  resolvedStatus: ResolvedStatus;
  ignoredStatus: IgnoredStatus;
  liveMode: boolean;
  timeFrameFrom: string;
  timeFrameTo: string;
};

export type UserUpdateAlarmDTO = {
  isFavourite: boolean;
  isIgnored: boolean;
  isResolved: boolean;
};

export type ApiUpdateAlarmDTO = {
  alarmId: string;
  thumbnail: {
    status: AlarmMediaStatus;
    failureReason?: string;
  };
  image: {
    status: AlarmMediaStatus;
    failureReason?: string;
  };
  video: {
    status: AlarmMediaStatus;
    failureReason?: string;
  };
  endTimestamp: string;
};
