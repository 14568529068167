import { useCameraZones } from 'context/providers/CameraZonesProvider';
import SelectBase from 'components/Input/Select/SelectBase';
import { SelectProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type SelectCameraZoneProps = SelectProps & {
  label: string;
};

export const SelectCameraZone = ({
  label,
  ...props
}: SelectCameraZoneProps) => {
  const { cameraZones } = useCameraZones();
  const { t } = useTranslation();

  const items = cameraZones.map((zone) => {
    return { value: zone.id, label: zone.name };
  });

  items.push({ value: '', label: `${t('streams.no-zone')}` });

  return <SelectBase items={items} label={label} {...props} />;
};
