import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { getLicenseInfo } from 'api/authApi';

export type LicenseContextValue = {
  licenseExpiration: string;
  licenseExpired: boolean;
  fetchLicense: () => void;
};

export type LicenseProviderProps = {
  children: ReactNode;
};

const defaultValue: LicenseContextValue = {
  licenseExpiration: '',
  licenseExpired: false,
  fetchLicense: () => {},
};

const LicenseContext = createContext(defaultValue);

const LicenseProvider: FC<LicenseProviderProps> = (
  props: LicenseProviderProps
) => {
  const [licenseExpiration, setLicenseExpiration] = useState(
    defaultValue.licenseExpiration
  );
  const [licenseExpired, setLicenseExpired] = useState(
    defaultValue.licenseExpired
  );

  const fetchLicense = useCallback(() => {
    getLicenseInfo()
      .then((data) => {
        setLicenseExpiration(data.expirationDate);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          setLicenseExpired(true);
        }
      });
  }, []);

  useEffect(() => {
    fetchLicense();
  }, [fetchLicense]);

  return (
    <LicenseContext.Provider
      value={{
        licenseExpiration,
        fetchLicense,
        licenseExpired,
      }}
      {...props}
    />
  );
};

const useLicense = () => useContext(LicenseContext);
export { LicenseProvider, LicenseContext, useLicense };
