import { AnalyticsMetaData } from 'types/analyticsTypes';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { FireSmokeStreamData } from 'types/analytics/fire/FireSmoke';
import { FallStreamData } from 'types/analytics/FallDetection';
import { FlareInspectionStreamData } from 'types/analytics/fire/FlareInspection';

export const checkIfDetected = (
  selectedAnalytics: AnalyticsTypes | '',
  metaData: AnalyticsMetaData | undefined
) => {
  if (!metaData) return false;

  switch (selectedAnalytics) {
    case AnalyticsTypes.FallDetection:
      const fallData = metaData as FallStreamData;
      return fallData.isFallDetected;
    case AnalyticsTypes.FireSmokeTunnel:
    case AnalyticsTypes.FireSmoke:
      const fireData = metaData as FireSmokeStreamData;
      return fireData.isFireDetected || fireData.isSmokeDetected;
    case AnalyticsTypes.FlareInspection:
      const flareData = metaData as FlareInspectionStreamData;
      return (
        flareData.numberOfZonesWithFire > 0 ||
        flareData.numberOfZonesWithSmoke > 0
      );
    default:
      return false;
  }
};
