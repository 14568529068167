import { Grid, makeStyles } from '@material-ui/core';
import { getStreams } from 'api/streamApi';
import { MultipleSelectVideoWall } from 'components/Input/Select/MultipleSelectVideoWall';
import { AnalyticsSelect } from 'components/Other/ColorIndicator/AnalyticsSelect';
import {
  AnalyticsTypes,
  useAnalytics,
} from 'context/providers/AnalyticsProvider';
import {
  VideoWallStream,
  useVideoWall,
} from 'context/providers/VideoWallProvider';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stream } from 'types/streamTypes';
import { VideoWallItem } from 'views/VideoWall/VideoWallItem';

export type StreamFrame = {
  name: string;
  frame: string | null;
  id: string;
};

export const VideoWall = () => {
  const { availableAnalytics } = useAnalytics();
  const {
    videoWallStreams,
    saveVideoWallStreams,
    selectedAnalytics,
    changeAnalytics,
  } = useVideoWall();
  const classes = useStyles();
  const { t } = useTranslation();
  const [apiStreams, setApiStreams] = useState<Stream[]>([]);
  const [filteredStreams, setFilteredStreams] = useState<VideoWallStream[]>([]);
  const [matchingStreams, setMatchingStreams] = useState<VideoWallStream[]>([]);

  useEffect(() => {
    getStreams()
      .then((res) => {
        setApiStreams(res);
      })
      .catch(() => {});
  }, [setApiStreams]);

  useEffect(() => {
    if (!selectedAnalytics) return;

    const filtered = apiStreams.filter((stream: Stream) =>
      stream.enabledAnalytics.includes(selectedAnalytics)
    );
    const formatted = filtered.map((stream: Stream) => {
      return {
        id: stream.id,
        vpaStreamId: stream.vpaStreamId,
        name: stream.name,
        enabledAnalytics: stream.enabledAnalytics,
      };
    });
    setFilteredStreams(formatted);
  }, [selectedAnalytics, apiStreams]);

  const selectMatchingStreams = useCallback(() => {
    if (!selectedAnalytics) return;

    const filtered = videoWallStreams.filter((stream) =>
      stream.enabledAnalytics.includes(selectedAnalytics)
    );
    setMatchingStreams(filtered);
  }, [selectedAnalytics, videoWallStreams]);

  useEffect(() => {
    selectMatchingStreams();
  }, [selectMatchingStreams]);

  const handleDelete = (streamId: string) => {
    const filtered = videoWallStreams.filter(
      (videoWallStream) => videoWallStream.id !== streamId
    );
    saveVideoWallStreams(filtered);
  };

  const handleChangeAnalytics = (analytics: AnalyticsTypes) => {
    changeAnalytics(analytics);
  };

  return (
    <>
      <div className={classes.topBar}>
        <MultipleSelectVideoWall
          items={filteredStreams}
          selectedItems={matchingStreams}
          setSelectedItems={saveVideoWallStreams}
          label={t('video-wall.select')}
        />
        <AnalyticsSelect
          selectedAnalytics={selectedAnalytics}
          handleChange={handleChangeAnalytics}
          availableAnalytics={
            Object.keys(availableAnalytics) as AnalyticsTypes[]
          }
        />
      </div>
      <Grid container spacing={1} className={classes.outerContainer}>
        {matchingStreams.map((stream) => {
          return (
            <Fragment key={stream.id}>
              <Grid item xs={12} sm={6} md={4} xl={3}>
                <VideoWallItem
                  stream={stream}
                  handleDelete={() => handleDelete(stream.id)}
                />
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    marginTop: theme.spacing(3),
  },
  topBar: {
    display: 'flex',
    alignItems: 'end',
  },
}));
