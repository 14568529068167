import { FunctionComponent, SVGProps } from 'react';
import clsx from 'clsx';
import { makeStyles, Box, Typography } from '@material-ui/core';

type DetectionBoxProps = {
  label: string;
  withValue?: boolean;
  value?: number;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  isDetected?: boolean;
};

export const DetectionBox = ({
  label,
  withValue,
  value = 0,
  Icon,
  isDetected = false,
}: DetectionBoxProps) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.box, {
        [classes.detected]: isDetected,
        [classes.boxWithValue]: withValue,
      })}
    >
      <Icon
        className={clsx(classes.icon, {
          [classes.detectedIcon]: isDetected,
          [classes.iconWithValue]: withValue,
        })}
      />
      <Typography variant="subtitle1" align="center">
        {label}
      </Typography>
      {withValue && (
        <Typography variant="subtitle1" className={classes.value}>
          {value}
        </Typography>
      )}
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  box: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderTop: 0,
    width: '300px',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    padding: theme.spacing(2),
    textTransform: 'uppercase',
    '&:not(:last-child)': {
      borderRight: 'none',
    },
    '&:first-child': {
      borderRadius: '0px 0px 0px 6px',
    },
    '&:last-child': {
      borderRadius: '0px 0px 6px 0px',
    },
  },
  detected: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
    border: `2px solid ${theme.palette.primary.hover}`,
    borderTop: 0,
  },
  icon: {
    height: '30px',
    fill: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(3),
  },
  detectedIcon: {
    fill: theme.palette.primary.background,
    color: theme.palette.primary.background,
  },
  value: {
    width: '25px',
    marginLeft: theme.spacing(1),
  },
  boxWithValue: {
    justifyContent: 'space-between',
  },
  iconWithValue: {
    marginRight: theme.spacing(1),
  },
}));
