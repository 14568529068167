import { Dispatch, SetStateAction } from 'react';

type Node = {
  x: number;
  y: number;
};

export const isIntersect = (A: Node, B: Node, C: Node, D: Node) => {
  const ccw = (a: Node, b: Node, c: Node) => {
    return (c.y - a.y) * (b.x - a.x) > (b.y - a.y) * (c.x - a.x);
  };

  return ccw(A, C, D) !== ccw(B, C, D) && ccw(A, B, C) !== ccw(A, B, D);
};

const checkBoundaries01 = (point: number) => {
  if (point > 1) {
    point = 1;
  }
  if (point < 0) {
    point = 0;
  }
  return point;
};

const checkBoundariesPX = (point: number, boundaryPX: number) => {
  if (point > boundaryPX) {
    point = boundaryPX;
  }
  if (point < 0) {
    point = 0;
  }
  return point;
};

export const normalizeValue01 = (
  node: Node,
  width: number,
  height: number,
  scaleX: number,
  scaleY: number
) => {
  const x = checkBoundaries01((node.x * scaleX) / width);
  const y = checkBoundaries01((node.y * scaleY) / height);

  return { x, y };
};

export const normalizeValuePX = (
  node: Node,
  width: number,
  height: number,
  scaleX: number,
  scaleY: number
) => {
  let x = node.x * (width / scaleX);
  x = checkBoundariesPX(x, width / scaleX);

  let y = node.y * (height / scaleY);
  y = checkBoundariesPX(y, height / scaleY);

  return { x, y };
};

export const validateBoundaries = (
  position: Node,
  width: number | undefined,
  height: number | undefined
) => {
  const validatedPosition = { x: position.x, y: position.y };

  if (!width || !height) return;

  if (validatedPosition.x < 0) {
    validatedPosition.x = 0;
  }
  if (validatedPosition.y < 0) {
    validatedPosition.y = 0;
  }
  if (validatedPosition.x > width) {
    validatedPosition.x = width;
  }
  if (validatedPosition.y > height) {
    validatedPosition.y = height;
  }

  return validatedPosition;
};

export const toggleSelect = (
  shapeIndex: number,
  selectedZones: number[],
  setSelectedZones: Dispatch<SetStateAction<number[]>>
) => {
  if (selectedZones.includes(shapeIndex)) {
    const selected = [...selectedZones];
    const filtered = selected.filter((element) => element !== shapeIndex);
    setSelectedZones(filtered);
  } else {
    const selected = [...selectedZones];
    selected.push(shapeIndex);
    setSelectedZones(selected);
  }
};
