import { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import Toast, { AlertSeverity } from 'components/Alert/Toast';
import { loginValidationSchema } from 'validation/loginValidationSchema';
import { userLogin } from 'api/authApi';
import { useAuth } from 'context/providers/AuthProvider';
import { LoginContent } from 'views/LoginView/LoginContent';

export type LoginValues = {
  email: string;
  password: string;
};

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { login } = useAuth();
  const [feedback, setFeedback] = useState<{
    open: boolean;
    status: AlertSeverity;
    msg: string;
  }>({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
  const [loading, setLoading] = useState(false);

  const initialValues = {
    email: '',
    password: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      userLogin(values)
        .then((user) => {
          if (user) {
            login(user);
            navigate('/streams');
          }
          setLoading(false);
        })
        .catch((error) => {
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description,
          });
          setLoading(false);
        });
    },
    validationSchema: loginValidationSchema,
  });

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className={classes.formContainer}>
          <Box className={classes.innerContainer}>
            <LoginContent formik={formik} loading={loading} />
          </Box>
        </div>
        <div className={classes.image}></div>
      </div>
      {feedback.open && (
        <Toast
          setState={setFeedback}
          severity={feedback.status}
          msg={feedback.msg}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    display: 'flex',
    height: '100vh',
    maxWidth: '100vw',
  },
  formContainer: {
    width: '500px',
    minWidth: '500px',
    height: '100vh',
    padding: 0,
    display: 'flex',
  },
  innerContainer: {
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(5),
    boxShadow: theme.shadows[7],
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
  },
  image: {
    backgroundImage: `url("login_img.webp")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  },
}));

export default LoginView;
