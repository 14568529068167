import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import TableBodyRow from 'components/Table/TableBodyRow';
import DataCell from 'components/Table/DataCell';
import { Stream } from 'types/streamTypes';
import { AlertSeverity } from 'components/Alert/Toast';
import { DeleteStream } from 'views/StreamsView/DeleteStream/DeleteStream';
import { EditStream } from 'views/StreamsView/EditStream/EditStream';
import { ColorIndicator } from 'components/Other/ColorIndicator/ColorIndicator';
import { processStreamStatus } from 'views/StreamsView/StreamsTable/helpers';
import { useAuth } from 'context/providers/AuthProvider';
import { MultipleSelectAnalytics } from 'components/Input/Select/MultipleSelectAnalytics';
import {
  AnalyticsTypes,
  useAnalytics,
} from 'context/providers/AnalyticsProvider';
import { toggleAnalyticsOnStream } from 'api/analyticsApi';
import { PreviewButton } from './PreviewButton';
import { useStreamsAnalytics } from 'context/providers/StreamsAnalyticsProvider';

type StreamsTableRowProps = {
  stream: Stream;
  fetchStreams: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

const StreamsTableRow = ({
  stream,
  fetchStreams,
  setFeedback,
}: StreamsTableRowProps) => {
  const classes = useStyles();
  const { isAdmin, isGuest } = useAuth();
  const { availableAnalytics, fetchAvailableAnalytics } = useAnalytics();
  const { savedAnalytics, updateSavedAnalytics } = useStreamsAnalytics();
  const [value, setValue] = useState(stream.enabledAnalytics);
  const [loading, setLoading] = useState(false);

  const handleSelectAnalytics = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setLoading(true);
    const eventValue = event.target.value as AnalyticsTypes[];
    let selectedItem;
    let enabled;
    if (value.length > eventValue.length) {
      selectedItem = value.filter((item) => eventValue.indexOf(item) === -1)[0];
      enabled = false;
      if (savedAnalytics[stream.id] === selectedItem) {
        const updatedAnalytics = { ...savedAnalytics };
        delete updatedAnalytics[stream.id];
        updateSavedAnalytics(updatedAnalytics);
      }
    } else {
      selectedItem = eventValue.filter((item) => value.indexOf(item) === -1)[0];
      enabled = true;
    }
    toggleAnalyticsOnStream(stream.id, selectedItem, { enabled })
      .then(() => {
        setValue(event.target.value as AnalyticsTypes[]);
        fetchAvailableAnalytics();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: 'enable-analytics-error',
        });
      });
  };

  const status = processStreamStatus(stream.status);

  return (
    <TableBodyRow key={stream.id} id="stream-data-row">
      <DataCell id={`${stream.id}-name-cell`}>{stream.name}</DataCell>
      <DataCell id={`${stream.id}-url-cell`}>{stream.url}</DataCell>
      <DataCell id={`${stream.id}-status-cell`} className={classes.statusCell}>
        <div className={classes.statusContent}>
          <ColorIndicator color={status.color} />
          {status.msg}
        </div>
      </DataCell>
      <DataCell id={`${stream.id}-zone-cell`}>
        {stream.assignedTags[0]?.name}
      </DataCell>
      {isAdmin && (
        <>
          <DataCell
            id={`${stream.id}-analytics-cell`}
            className={classes.analyticsCell}
          >
            <MultipleSelectAnalytics
              name="selectedAnalytics"
              id="stream-selectedAnalytics"
              items={availableAnalytics}
              value={value}
              onChange={handleSelectAnalytics}
              displayEmpty
              disabled={loading}
            />
          </DataCell>
        </>
      )}
      <DataCell
        id={`${stream.id}-actions-cell`}
        className={clsx(classes.actionsCell, {
          [classes.guestActions]: isGuest,
        })}
      >
        <PreviewButton stream={stream} />
        {isAdmin && (
          <>
            <EditStream
              stream={stream}
              setFeedback={setFeedback}
              fetchStreams={fetchStreams}
            />
            <DeleteStream
              stream={stream}
              setFeedback={setFeedback}
              fetchStreams={fetchStreams}
            />
          </>
        )}
      </DataCell>
    </TableBodyRow>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsCell: {
    textAlign: 'center',
    padding: theme.spacing(0.5),
    cursor: 'default',
    width: '170px',
  },
  analyticsCell: {
    paddingBottom: theme.spacing(1),
  },
  statusCell: {
    width: '170px',
  },
  statusContent: {
    display: 'flex',
    alignItems: 'center',
  },
  guestActions: {
    width: '80px',
  },
}));

export default StreamsTableRow;
