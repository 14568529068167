import { Theme, makeStyles } from '@material-ui/core';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { useStreamData } from 'context/providers/StreamDataProvider';

export const AnalyticsSettingsPlaceholder = () => {
  const { selectedAnalytics } = useStreamData();
  let placeholderHeight = 0;

  switch (selectedAnalytics) {
    case AnalyticsTypes.FallDetection:
      placeholderHeight = 835;
      break;
    case AnalyticsTypes.FireSmoke:
      placeholderHeight = 1420;
      break;
    case AnalyticsTypes.FlareInspection:
      placeholderHeight = 1470;
      break;
    case AnalyticsTypes.PPE:
      placeholderHeight = 1500;
      break;
    default:
      placeholderHeight = 1450;
  }

  const classes = useStyles({ height: placeholderHeight });

  return <div className={classes.placeholder}></div>;
};

type StyleProps = {
  height: number;
};

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  placeholder: (props) => ({
    height: props.height,
  }),
}));
