import { ColorEnum } from 'components/Other/ColorIndicator/ColorIndicator';
import { t } from 'i18next';
import { StreamStatus, Stream } from 'types/streamTypes';

export const processStreamStatus = (status: StreamStatus) => {
  switch (status) {
    case StreamStatus.CONNECTED:
      return { color: ColorEnum.GREEN, msg: t('streams.connected') };
    case StreamStatus.DISCONNECTED:
      return { color: ColorEnum.RED, msg: t('streams.disconnected') };
    case StreamStatus.CONNECTING:
      return { color: ColorEnum.YELLOW, msg: t('streams.connecting') };
  }
};

export const identifyConnectingStreams = (streamArr: Stream[]) => {
  let isConnecting = false;
  streamArr.forEach((stream) => {
    if (stream.status === StreamStatus.CONNECTING) {
      isConnecting = true;
    }
  });
  return isConnecting;
};
