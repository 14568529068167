import { Dispatch, SetStateAction } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ResetOptions } from 'components/KonvaShapes/ResetOptions';
import { ToggleDrawingMode } from 'components/KonvaShapes/ToggleDrawingMode';
import {
  PolygonZoneTypes,
  Polygon,
} from 'components/KonvaShapes/Polygon/PolygonZones';
import { layerOffset } from 'components/KonvaShapes/constants';
import { ZoneModes } from 'components/KonvaShapes/types';

type PolygonZoneOptionsProps = {
  mode: ZoneModes;
  setMode: Dispatch<SetStateAction<ZoneModes>>;
  type: PolygonZoneTypes;
  setSelectedPolygons: Dispatch<SetStateAction<number[]>>;
  setPolygonInProgress: Dispatch<SetStateAction<Polygon>>;
  handleRemoveAll: () => void;
  handleRemoveSelected: () => void;
  allPolygons: Polygon[];
  selectedPolygons: number[];
};

export const PolygonZoneOptions = ({
  mode,
  setMode,
  type,
  setSelectedPolygons,
  setPolygonInProgress,
  handleRemoveAll,
  handleRemoveSelected,
  allPolygons,
  selectedPolygons,
}: PolygonZoneOptionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.zoneOptions}>
      <Typography>
        {t(
          `stream-settings.${
            type === PolygonZoneTypes.EXCLUSION
              ? 'exclusion-zone-options'
              : 'inclusion-zone-options'
          }`
        )}
      </Typography>
      <ToggleDrawingMode
        mode={mode}
        setMode={setMode}
        setSelectedZones={setSelectedPolygons}
        setZoneInProgress={setPolygonInProgress}
      />
      <ResetOptions
        handleRemoveAll={handleRemoveAll}
        handleRemoveSelected={handleRemoveSelected}
        zones={allPolygons}
        selectedZones={selectedPolygons}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  zoneOptions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '14px',
    marginRight: `${layerOffset}px`,
  },
}));
