import { Routes, Route, Navigate } from 'react-router-dom';
import LoginView from 'views/LoginView/LoginView';

const NotAuthenticatedApp = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginView />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default NotAuthenticatedApp;
