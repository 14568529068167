import { IconButton, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { NoPreview } from 'views/StreamDetails/Preview/NoPreview';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useEffect, useRef, useState } from 'react';
import { useAnalyticsSettings } from 'context/providers/AnalyticsSettingsProvider';
import { getStreamPreview } from 'api/streamApi';
import { intervals } from 'views/StreamDetails/Preview/constants';
import {
  VideoWallStream,
  useVideoWall,
} from 'context/providers/VideoWallProvider';
import { getAnalyticsMetaData } from 'api/analyticsApi';
import { AnalyticsMetaData } from 'types/analyticsTypes';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { FireSmokeStreamData } from 'types/analytics/fire/FireSmoke';

type VideoWallItemProps = {
  stream: VideoWallStream;
  handleDelete: () => void;
};

const drawSettings = false;
const imgQuality = 50;
const resolution = '640x360';

export const VideoWallItem = ({ stream, handleDelete }: VideoWallItemProps) => {
  const classes = useStyles();
  const { selectedAnalytics } = useVideoWall();
  const [frame, setFrame] = useState('');
  const { analyticsSettings } = useAnalyticsSettings();
  const firstLoad = useRef(true);
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState<AnalyticsMetaData | undefined>(
    undefined
  );

  const fetchConditionsMet =
    stream.vpaStreamId &&
    analyticsSettings &&
    selectedAnalytics &&
    stream.enabledAnalytics.includes(selectedAnalytics);

  useEffect(() => {
    let continueRequests = true;
    const fetchPreview = () => {
      if (!fetchConditionsMet || !continueRequests) {
        return;
      }
      const startTimestamp = Date.now();
      getStreamPreview(
        stream.vpaStreamId,
        selectedAnalytics,
        analyticsSettings,
        drawSettings,
        imgQuality,
        resolution
      )
        .then((res) => {
          setFrame(res);
        })
        .catch(() => {})
        .finally(() => {
          if (firstLoad.current) {
            setLoading(false);
            firstLoad.current = false;
          }
          const currentTimestamp = Date.now();
          const timeout = Math.max(
            0,
            intervals[3].value - (currentTimestamp - startTimestamp)
          );
          window.setTimeout(fetchPreview, timeout);
        });
    };

    const fetchPreviewAndMetadata = () => {
      if (!fetchConditionsMet || !continueRequests) {
        return;
      }
      const startTimestamp = Date.now();
      Promise.allSettled([
        getAnalyticsMetaData(stream.id, selectedAnalytics),
        getStreamPreview(
          stream.vpaStreamId,
          selectedAnalytics,
          analyticsSettings,
          drawSettings,
          imgQuality,
          resolution
        ),
      ])
        .then((responses) => {
          const metadata = responses[0];
          const preview = responses[1];

          if (metadata.status === 'fulfilled') {
            setMetaData(metadata.value);
          }
          if (preview.status === 'fulfilled') {
            setFrame(preview.value);
          }
        })
        .catch(() => {})
        .finally(() => {
          if (firstLoad.current) {
            setLoading(false);
            firstLoad.current = false;
          }
          const currentTimestamp = Date.now();
          const timeout = Math.max(
            0,
            intervals[3].value - (currentTimestamp - startTimestamp)
          );
          window.setTimeout(fetchPreviewAndMetadata, timeout);
        });
    };

    if (selectedAnalytics === AnalyticsTypes.FireSmoke) {
      fetchPreviewAndMetadata();
    } else {
      fetchPreview();
    }

    return () => {
      continueRequests = false;
    };
  }, [
    stream.id,
    stream.vpaStreamId,
    selectedAnalytics,
    fetchConditionsMet,
    analyticsSettings,
  ]);

  let detected = false;

  if (selectedAnalytics === AnalyticsTypes.FireSmoke) {
    const FSMetadata = metaData as FireSmokeStreamData;
    detected =
      (FSMetadata?.isFireDetected || FSMetadata?.isSmokeDetected) &&
      Boolean(frame);
  }

  return (
    <div className={clsx({ [classes.detected]: detected })}>
      <div className={classes.header}>
        <Typography noWrap>{stream.name}</Typography>
        <IconButton
          size="small"
          className={classes.icon}
          onClick={handleDelete}
        >
          <HighlightOffIcon />
        </IconButton>
      </div>
      <div className={classes.preview}>
        <Link to={`/streams/${stream.id}`} className={classes.link}>
          {frame ? (
            <img
              src={frame}
              className={classes.img}
              alt="stream-preview"
              draggable="false"
            ></img>
          ) : (
            <NoPreview loading={loading} />
          )}
        </Link>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  img: {
    backgroundColor: 'black',
    width: '100%',
    aspectRatio: '16/9',
    verticalAlign: 'bottom',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(0, 0.5, 0, 1),
    borderRadius: '4px 4px 0px 0px',
    backgroundColor: theme.palette.secondary.background,
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  preview: {
    border: '1px solid black',
    borderRadius: '0px 0px 4px 4px',
  },
  detected: {
    boxShadow: theme.shadows[5],
    borderRadius: '4px',
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  link: {
    textDecoration: 'none',
  },
}));
