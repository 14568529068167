export const passReg =
  /^(?=.*[0-9])(?=.*[a-z-żźćńółęąś])(?=.*[A-Z-ŻŹĆŃÓŁĘĄŚ]).{8,}$/;

export const sliderMarks = [
  { value: 0.1 },
  { value: 0.2 },
  { value: 0.3 },
  { value: 0.4 },
  { value: 0.5 },
  { value: 0.6 },
  { value: 0.7 },
  { value: 0.8 },
  { value: 0.9 },
];

export const flareMarks = [
  { value: 0.3 },
  { value: 0.4 },
  { value: 0.5 },
  { value: 0.6 },
  { value: 0.7 },
  { value: 0.8 },
  { value: 0.9 },
];
