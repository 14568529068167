import { ChangeEvent } from 'react';
import {
  makeStyles,
  Box,
  Typography,
  Slider,
  SliderProps,
  Theme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/providers/AuthProvider';
import clsx from 'clsx';

interface IMarks {
  value: number;
  label?: string;
}

type SliderStepsProps = SliderProps & {
  name: string;
  marks: IMarks[];
  value: number;
  setValue: (value: number, name: string) => void;
  label?: string;
  description?: string;
  onChangeCommitted?: (
    event: ChangeEvent<unknown>,
    value: number | number[]
  ) => void;
  maxWidth?: string;
  minHeight?: string;
};

const SliderSteps = (props: SliderStepsProps) => {
  const {
    name,
    marks,
    value,
    setValue,
    label,
    onChangeCommitted,
    step,
    className,
    description,
    maxWidth,
    minHeight,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ maxWidth, minHeight });
  const { isGuest } = useAuth();

  const handleChange = (
    event: ChangeEvent<number | number[]>,
    eventValue: number | number[]
  ) => {
    if (typeof eventValue === 'number') {
      setValue(eventValue, name);
    }
  };

  return (
    <Box className={clsx(classes.container, className)}>
      {label && (
        <Typography
          className={clsx(classes.label, {
            [classes.labelWithDesc]: description,
          })}
        >
          {t(label)}
        </Typography>
      )}
      {description && (
        <Typography variant="caption" className={classes.caption}>
          {description}
        </Typography>
      )}
      <Slider
        value={value}
        step={step || 0.1}
        min={marks[0].value}
        max={marks[marks.length - 1].value}
        marks={marks.map((mark) => {
          return {
            value: mark.value,
            label: mark.label ? mark.label : mark.value,
          };
        })}
        className={classes.slider}
        // @ts-ignore
        onChange={handleChange}
        onChangeCommitted={onChangeCommitted}
        disabled={isGuest}
      />
    </Box>
  );
};

type StyleProps = {
  maxWidth: string | undefined;
  minHeight: string | undefined;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
    lineHeight: '18px',
  },
  labelWithDesc: {
    marginBottom: 0,
  },
  container: (props) => ({
    padding: theme.spacing(0, 1),
    maxWidth: '500px',
    minHeight: props.minHeight ? props.minHeight : '120px',
    maxHeight: '170px',
  }),
  slider: (props) => ({
    maxWidth: props.maxWidth ? props.maxWidth : '500px',
    '& .MuiSlider-track': {
      color: theme.palette.secondary.main,
    },
    '& .MuiSlider-rail': {
      color: theme.palette.grey[600],
    },
  }),
  caption: {
    display: 'inline-block',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
}));

export default SliderSteps;
