import { CreateStreamDTO, EditStreamDTO } from 'types/streamTypes';
import { apiOrigin, previewOrigin } from 'api/config/interceptors';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { AnalyticsSettings } from 'types/analyticsTypes';
import { PPESettings, TrackedItems } from 'types/analytics/PPE';

const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};

export const getStreams = () => {
  return apiOrigin.get(`/stream`).then((res) => {
    return res.data;
  });
};

export const getStream = (streamId: string) => {
  return apiOrigin.get(`/stream/${streamId}`).then((res) => {
    return res.data;
  });
};

export const createStream = (createStreamDTO: CreateStreamDTO) => {
  return apiOrigin.post(`/stream`, createStreamDTO).then((res) => {
    return res.data;
  });
};

export const deleteStream = (streamId: string) => {
  return apiOrigin.delete(`/stream/${streamId}`).then((res) => {
    return res.data;
  });
};

export const updateStream = (
  streamId: string,
  editStreamDTO: EditStreamDTO
) => {
  return apiOrigin.patch(`/stream/${streamId}`, editStreamDTO).then((res) => {
    return res.data;
  });
};

export const getStreamPreview = (
  streamId: string,
  analyticsType: AnalyticsTypes | null,
  analyticsSettings?: AnalyticsSettings,
  drawSettings = true,
  imgQuality?: number,
  resolution?: string
) => {
  let trackedItems = null;

  if (analyticsType === AnalyticsTypes.PPE && analyticsSettings) {
    const ppeSettings = analyticsSettings as PPESettings;

    const isVestTracked = ppeSettings.trackedItems?.isVestTracked;
    const isHelmetTracked = ppeSettings.trackedItems?.isHardHatTracked;

    if (isVestTracked && isHelmetTracked) {
      trackedItems = `${TrackedItems.VEST},${TrackedItems.HARD_HAT}`;
    } else if (isVestTracked) {
      trackedItems = TrackedItems.VEST;
    } else if (isHelmetTracked) {
      trackedItems = TrackedItems.HARD_HAT;
    }
  }

  return previewOrigin
    .get(`/preview/${streamId}`, {
      params: {
        analytics: analyticsType ? analyticsType : null,
        drawSettings: drawSettings,
        quality: imgQuality,
        resolution,
        ppeTrackedItems: trackedItems,
      },
      responseType: 'blob',
      timeout: 2000,
    })
    .then((res) => {
      return blobToBase64(res.data);
    });
};
