import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SliderSteps from 'components/Slider/SliderSteps';
import { sliderMarks } from 'utils/constants';
import SliderContinous from 'components/Slider/SliderContinuous';
import { FallDetectionProps } from 'views/StreamDetails/Settings/Analytics/FallDetection/FallDetection';
import { ChangeEvent, useState } from 'react';

export const FallThresholds = ({
  settings,
  setSettings,
  eventTimers,
  setEventTimers,
}: FallDetectionProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [detectorThreshold, setDetectorThreshold] = useState(
    settings.detectorThreshold
  );
  const [fallThreshold, setFallThreshold] = useState(settings.threshold);

  const commitThreshold = (
    event: ChangeEvent<unknown>,
    value: number | number[],
    name: string
  ) => {
    if (typeof value !== 'number') return;

    setSettings({ ...settings, [name]: value });
  };

  const setDelay = (value: number) => {
    setEventTimers({ ...eventTimers, delayTimeSeconds: value });
  };

  const setCooldown = (value: number) => {
    setEventTimers({
      ...eventTimers,
      alarmingSettings: {
        ...eventTimers.alarmingSettings,
        nextEventCooldownSeconds: value,
      },
    });
  };

  return (
    <div className={classes.settingsContainer}>
      <div className={classes.sliders}>
        <Typography className={classes.heading} align="center" variant="h3">
          {t('stream-settings.thresholds')}
        </Typography>
        <SliderSteps
          name="detectorThreshold"
          marks={sliderMarks}
          value={detectorThreshold || 0.1}
          setValue={setDetectorThreshold}
          label={t('stream-settings.fall.person-confidence')}
          onChangeCommitted={(event, value) =>
            commitThreshold(event, value, 'detectorThreshold')
          }
        />
        <SliderSteps
          name="threshold"
          marks={sliderMarks}
          value={fallThreshold || 0.1}
          setValue={setFallThreshold}
          label={t('stream-settings.fall.fall-confidence')}
          onChangeCommitted={(event, value) =>
            commitThreshold(event, value, 'threshold')
          }
        />
      </div>
      <div className={classes.sliders}>
        <Typography className={classes.heading} align="center" variant="h3">
          {t('stream-settings.event-timers')}
        </Typography>
        <SliderContinous
          name="delayTimeSeconds"
          value={eventTimers?.delayTimeSeconds}
          max={30}
          setValue={setDelay}
          label={t('stream-settings.delay')}
          description={t('stream-settings.delay-description')}
        />
        <SliderContinous
          name="nextEventCooldownSeconds"
          value={eventTimers?.alarmingSettings.nextEventCooldownSeconds}
          max={30}
          setValue={setCooldown}
          label={t('stream-settings.cooldown')}
          description={t('stream-settings.cooldown-description')}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  settingsContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5),
    maxWidth: '400px',
    minWidth: '300px',
    flex: 1,
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
  sliders: {
    marginBottom: theme.spacing(2),
  },
}));
