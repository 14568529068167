import { Dispatch, SetStateAction, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FallDetectionSettings } from 'types/analytics/FallDetection';
import { EventTimers, Zone } from 'types/analyticsTypes';
import { FallThresholds } from 'views/StreamDetails/Settings/Analytics/FallDetection/FallThresholds';
import PolygonZones, {
  PolygonZoneTypes,
} from 'components/KonvaShapes/Polygon/PolygonZones';
import { useResizeDetector } from 'react-resize-detector';
import { layerOffset } from 'components/KonvaShapes/constants';
import { JPGPreviewWithFetch } from 'views/StreamDetails/Preview/JPGPreviewWithFetch';
import { useStreamData } from 'context/providers/StreamDataProvider';

export type FallDetectionProps = {
  settings: FallDetectionSettings;
  setSettings: Dispatch<SetStateAction<FallDetectionSettings>>;
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
};

export const FallDetection = ({
  settings,
  setSettings,
  ...props
}: FallDetectionProps) => {
  const classes = useStyles();
  const { width, height, ref } = useResizeDetector();
  const { t } = useTranslation();
  const { vpaStreamId } = useStreamData();
  const [previewLoading, setPreviewLoading] = useState(true);

  const setExclusionZones = (exclusionZones: Zone[]) => {
    setSettings({ ...settings, exclusionZones });
  };

  const exclusionsAreasState = {
    activeZones: settings?.exclusionZones || [],
    setActiveZones: setExclusionZones,
  };

  const videSizeDetermined = width && height;

  return (
    <div className={classes.outerContainer}>
      <div className={classes.previewWithZones}>
        <Typography variant="body2" className={classes.intro}>
          {t('stream-settings.exclusion-zones-intro')}
        </Typography>
        {videSizeDetermined && !previewLoading && (
          <div className={classes.konvaShape}>
            <PolygonZones
              width={width}
              height={height}
              activeZonesState={exclusionsAreasState}
              type={PolygonZoneTypes.EXCLUSION}
            />
          </div>
        )}
        <JPGPreviewWithFetch
          ref={ref}
          className={classes.preview}
          previewLoading={previewLoading}
          setPreviewLoading={setPreviewLoading}
          streamId={vpaStreamId}
        />
      </div>
      <div className={classes.thresholds}>
        <FallThresholds
          settings={settings}
          setSettings={setSettings}
          {...props}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  outerContainer: {
    marginTop: theme.spacing(3),
    display: 'flex',
  },
  previewWithZones: {
    flex: 2,
    maxWidth: '1400px',
  },
  konvaShape: {
    position: 'absolute',
    zIndex: 10,
  },
  thresholds: {
    maxWidth: '400px',
    minWidth: '300px',
    flexGrow: 1,
  },
  preview: {
    marginLeft: `${layerOffset}px`,
    marginTop: `${layerOffset}px`,
  },
  intro: {
    marginLeft: `${layerOffset}px`,
  },
}));
