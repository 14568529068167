import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { Stream } from 'types/streamTypes';
import { intervals } from 'views/StreamDetails/Preview/constants';
import { AnalyticsTypes, useAnalytics } from './AnalyticsProvider';
import { isEmpty } from 'lodash';
import { getStreams } from 'api/streamApi';

export type VideoWallStream = {
  id: string;
  vpaStreamId: string;
  name: string;
  enabledAnalytics: AnalyticsTypes[];
};

export type VideoWallContextValue = {
  refreshIntervals: { [key: string]: number };
  setRefreshIntervals: Dispatch<SetStateAction<{ [key: string]: number }>>;
  initializeIntervals: (streams: Stream[]) => void;
  videoWallStreams: VideoWallStream[];
  saveVideoWallStreams: (streams: VideoWallStream[]) => void;
  selectedAnalytics: AnalyticsTypes | null;
  changeAnalytics: (analytics: AnalyticsTypes | null) => void;
};

export type VideoWallProviderProps = {
  children?: ReactNode;
};

const defaultValue: VideoWallContextValue = {
  refreshIntervals: {},
  setRefreshIntervals: () => {},
  initializeIntervals: () => {},
  videoWallStreams: [],
  saveVideoWallStreams: () => {},
  selectedAnalytics: null,
  changeAnalytics: () => {},
};

const VideoWallContext = createContext(defaultValue);

const VideoWallProvider: FC<VideoWallProviderProps> = (
  props: VideoWallProviderProps
) => {
  const [refreshIntervals, setRefreshIntervals] = useState({});
  const [videoWallStreams, setVideoWallStreams] = useState<VideoWallStream[]>(
    []
  );
  const { availableAnalytics } = useAnalytics();
  const [selectedAnalytics, setSelectedAnalytics] =
    useState<AnalyticsTypes | null>(null);
  const [apiStreams, setApiStreams] = useState<Stream[]>([]);

  useEffect(() => {
    getStreams()
      .then((res) => {
        setApiStreams(res);
      })
      .catch(() => {});
  }, [setApiStreams]);

  useEffect(() => {
    const savedStreams = localStorage.getItem('videoWallStreams');
    const savedAnalytics = localStorage.getItem('videoWallAnalytics');

    const updateLocalStorage = () => {
      if (!savedStreams) return;

      const apiStreamsIds = apiStreams.map((stream) => stream.id);
      const savedData = JSON.parse(savedStreams) as VideoWallStream[];
      const filtered = savedData.filter((wallStream) =>
        apiStreamsIds.includes(wallStream.id)
      );

      const updatedVPAIds = filtered.map((stream) => {
        const updated = apiStreams.find(
          (apiStream) => apiStream.id === stream.id
        );
        return { ...stream, vpaStreamId: updated!.vpaStreamId };
      });
      saveVideoWallStreams(updatedVPAIds);
    };

    if (savedStreams && !isEmpty(apiStreams)) {
      updateLocalStorage();
    }
    if (savedAnalytics) {
      setSelectedAnalytics(savedAnalytics as AnalyticsTypes);
    } else {
      setSelectedAnalytics(
        Object.keys(availableAnalytics)[0] as AnalyticsTypes
      );
    }
  }, [availableAnalytics, apiStreams]);

  const saveVideoWallStreams = (streams: VideoWallStream[]) => {
    setVideoWallStreams(streams);
    localStorage.setItem('videoWallStreams', JSON.stringify(streams));
  };

  const initializeIntervals = (streams: Stream[]) => {
    const streamsWithIntervals: { [key: string]: number } = {};
    streams.forEach(
      (stream: Stream) => (streamsWithIntervals[stream.id] = intervals[2].value)
    );
    setRefreshIntervals(streamsWithIntervals);
  };

  const changeAnalytics = (analytics: AnalyticsTypes | null) => {
    if (!analytics) return;
    setSelectedAnalytics(analytics);
    localStorage.setItem('videoWallAnalytics', analytics);
  };

  return (
    <VideoWallContext.Provider
      value={{
        refreshIntervals,
        setRefreshIntervals,
        initializeIntervals,
        videoWallStreams,
        saveVideoWallStreams,
        selectedAnalytics,
        changeAnalytics,
      }}
      {...props}
    />
  );
};

const useVideoWall = () => useContext(VideoWallContext);
export { VideoWallProvider, VideoWallContext, useVideoWall };
