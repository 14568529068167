export const minutesToHoursFormatter = (value: number) => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} h ${minutes} m`;
    } else {
      return `${hours} h`;
    }
  } else {
    return `${minutes} m`;
  }
};
