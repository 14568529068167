import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import DialogActions from 'components/Dialog/DialogActions';
import { handleBlurAndTrim } from 'utils/helpers';
import { FormikContextType } from 'formik';
import { AddZoneValues } from './AddZoneDialog';
import TextFieldBase from 'components/Input/TextField/TextFieldBase';

type ZoneFormProps = {
  formik: FormikContextType<AddZoneValues>;
  loading: boolean;
  handleClose: () => void;
};

export const ZoneForm = ({ formik, loading, handleClose }: ZoneFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.content}>
        <TextFieldBase
          required
          name="name"
          id="zone-name"
          disabled={loading}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          label={t('settings.zones.zone-name')}
          className={classes.nameInput}
          error={Boolean(formik.errors.name && formik.touched.name)}
          helperText={
            formik.errors.name && formik.touched.name
              ? formik.errors.name
              : null
          }
          inputProps={{ maxLength: 40 }}
        />
        <TextFieldBase
          name="description"
          id="zone-description"
          disabled={loading}
          value={formik.values.description}
          className={classes.descInput}
          onChange={formik.handleChange}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          label={t('settings.zones.zone-description')}
          variant="outlined"
          multiline
          minRows={3}
          maxRows={3}
          inputProps={{ maxLength: 150 }}
        />
      </div>
      <DialogActions
        onCancel={handleClose}
        isLoading={loading}
        isValid={formik.dirty && formik.isValid}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  nameInput: {
    marginBottom: theme.spacing(4),
  },
  descInput: {
    height: '88px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
}));
