import { makeStyles, TableCell, TableCellProps } from '@material-ui/core';
import clsx from 'clsx';

const DataCell = ({ children, className, ...props }: TableCellProps) => {
  const classes = useStyles();

  return (
    <TableCell className={clsx(className, classes.dataCell)} {...props}>
      {children}
    </TableCell>
  );
};

const useStyles = makeStyles((theme) => ({
  dataCell: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    color: theme.palette.grey[900],
    padding: theme.spacing(0.5, 1, 0.5, 2),
    border: 'none',
    maxWidth: '200px',
    overflow: 'auto',

    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },

    '@media (min-width:1500px)': {
      padding: theme.spacing(0.5, 2, 0.5, 2),
    },
  },
}));

export default DataCell;
