import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import Konva from 'konva';
import { checkIntersection } from 'line-intersect';
import { Zone } from 'types/analyticsTypes';
import { isEmpty } from 'lodash';
import { layerOffset, stagePadding } from 'components/KonvaShapes/constants';

export const dragBoundFunc = (
  stageWidth: number,
  stageHeight: number,
  vertexRadius: number,
  pos: Konva.Vector2d
): Konva.Vector2d => {
  let x = pos.x;
  let y = pos.y;
  if (pos.x + vertexRadius > stageWidth) x = stageWidth;
  if (pos.x - vertexRadius < 0) x = 0;
  if (pos.y + vertexRadius > stageHeight) y = stageHeight;
  if (pos.y - vertexRadius < 0) y = 0;
  return { x, y };
};

export const checkIntersections = (
  newPoints: number[][],
  setIntersectingSegments: Dispatch<SetStateAction<number[][]>> | undefined
) => {
  for (let i = 0; i < newPoints.length; i++) {
    for (let j = i + 2; j < newPoints.length; j++) {
      if ((i + 1) % newPoints.length === j) {
        continue;
      }
      if ((j + 1) % newPoints.length === i) {
        continue;
      }
      const isIntersection = checkIntersection(
        newPoints[i][0],
        newPoints[i][1],
        newPoints[(i + 1) % newPoints.length][0],
        newPoints[(i + 1) % newPoints.length][1],
        newPoints[j][0],
        newPoints[j][1],
        newPoints[(j + 1) % newPoints.length][0],
        newPoints[(j + 1) % newPoints.length][1]
      );

      if (isIntersection.type === 'intersecting') {
        if (!setIntersectingSegments) return;

        setIntersectingSegments([
          [
            newPoints[i][0],
            newPoints[i][1],
            newPoints[(i + 1) % newPoints.length][0],
            newPoints[(i + 1) % newPoints.length][1],
          ],
          [
            newPoints[j][0],
            newPoints[j][1],
            newPoints[(j + 1) % newPoints.length][0],
            newPoints[(j + 1) % newPoints.length][1],
          ],
        ]);
        return true;
      }
    }
  }

  return false;
};

export const minMax = (points: number[]) => {
  return points.reduce((acc: number[], val: number) => {
    acc[0] = acc[0] === undefined || val < acc[0] ? val : acc[0];
    acc[1] = acc[1] === undefined || val > acc[1] ? val : acc[1];
    return acc;
  }, []);
};

export const isMouseOverStartPoint = (
  stageRef: MutableRefObject<Konva.Stage | null>
) => {
  if (!stageRef.current) return;

  // getIntersection method needs pointerPosition without scale applied
  const pointerPosition = stageRef.current.getPointerPosition();
  const shape =
    pointerPosition && stageRef.current.getIntersection(pointerPosition);

  return (
    shape?.parent?.name() === 'polygonInProgress' &&
    shape.getClassName() === 'Circle' &&
    shape.index === 1
  );
};

export const isMouseOverCurrentShape = (
  stageRef: MutableRefObject<Konva.Stage | null>
) => {
  if (!stageRef.current) return;

  // getIntersection method needs pointerPosition without scale applied
  const pointerPosition = stageRef.current.getPointerPosition();
  const shape =
    pointerPosition && stageRef.current.getIntersection(pointerPosition);

  const isMouseOverPolygon = shape?.parent?.attrs.name === 'polygonInProgress';

  return isMouseOverPolygon && !isMouseOverStartPoint(stageRef);
};

export const isMouseOverShape = (
  stageRef: MutableRefObject<Konva.Stage | null>
) => {
  if (!stageRef.current) {
    return { clickOnShape: false, shapeIndex: undefined };
  }

  // getIntersection method needs pointerPosition without scale applied
  const pointerPosition = stageRef.current.getPointerPosition();
  const shape =
    pointerPosition && stageRef.current.getIntersection(pointerPosition);

  const isMouseOverPolygon = shape?.parent?.attrs.name === 'polygon';

  return {
    clickOnShape: isMouseOverPolygon,
    shapeIndex: shape?.parent?.index,
  };
};

export const getMousePos = (
  stageRef: MutableRefObject<Konva.Stage | null>,
  scaleX: number,
  scaleY: number
) => {
  if (!stageRef.current) return;
  const position = stageRef.current.getPointerPosition();

  if (position) {
    return [
      (position.x - layerOffset) / scaleX,
      (position.y - layerOffset) / scaleY,
    ];
  }
};

export const isDefaultZone = (activeZones: Zone[] | undefined) => {
  if (!activeZones || isEmpty(activeZones) || activeZones[0].points.length < 4)
    return false;

  const upperLeft = activeZones[0].points[0];
  const upperRight = activeZones[0].points[1];
  const lowerRight = activeZones[0].points[2];
  const lowerLeft = activeZones[0].points[3];

  const isDefaultUpperLeft = upperLeft.x === 0 && upperLeft.y === 0;
  const isDefaultUpperRight = upperRight.x === 1 && upperRight.y === 0;
  const isDefaultLowerRight = lowerRight.x === 1 && lowerRight.y === 1;
  const isDefaultLowerLeft = lowerLeft.x === 0 && lowerRight.y === 1;

  return (
    isDefaultUpperLeft &&
    isDefaultUpperRight &&
    isDefaultLowerRight &&
    isDefaultLowerLeft
  );
};

export const clickOutOfBounds = (
  stageRef: MutableRefObject<Konva.Stage | null>
) => {
  if (!stageRef.current) return true;

  const pointerPosition = stageRef.current.getPointerPosition();

  if (!pointerPosition) return true;

  const stageWidth = stageRef.current.width() - stagePadding;
  const stageHeight = stageRef.current.height() - stagePadding;

  const x = pointerPosition.x;
  const y = pointerPosition.y;

  const xOutOfBounds = x < layerOffset || x > stageWidth + layerOffset;
  const yOutOfBounds = y < layerOffset || y > stageHeight + layerOffset;

  return xOutOfBounds || yOutOfBounds;
};
