import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { decodeToken } from 'utils/decodeJWT';

export const apiAddress =
  process.env.NODE_ENV === 'development' ? 'http://localhost' : '';

const apiOrigin = axios.create({
  baseURL: `${apiAddress}/api`,
});

const previewOrigin = axios.create({
  baseURL: `${apiAddress}`,
});

const alarmingOrigin = axios.create({
  baseURL: `${apiAddress}/alarming-reporting-api`,
});

const loginURL = '/user/auth/login';

const setupInterceptors = (logout: () => void) => {
  apiOrigin.interceptors.response.use(
    (response) => {
      return updateToken(response);
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiOrigin.interceptors.request.use((config) => {
    if (config.url === loginURL) {
      return config;
    }
    return addAuthorizationHeader(config, logout);
  });
};

const updateToken = (response: AxiosResponse) => {
  if (response.headers.authorization) {
    localStorage.setItem('token', `${response.headers.authorization}`);
  }
  return response;
};

const addAuthorizationHeader = (
  config: AxiosRequestConfig,
  logout: () => void
) => {
  const token = localStorage.getItem('token');
  const newConfig = { ...config };
  if (token && decodeToken(token) && newConfig.headers) {
    newConfig.headers.Authorization = token;
  } else {
    logout();
  }
  return newConfig;
};

export { apiOrigin, previewOrigin, alarmingOrigin, setupInterceptors };
