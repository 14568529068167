import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import TableBodyRow from 'components/Table/TableBodyRow';
import DataCell from 'components/Table/DataCell';
import { User } from 'types/userTypes';
import { DeleteUser } from 'views/UsersView/DeleteUser/DeleteUser';
import { AlertSeverity } from 'components/Alert/Toast';
import { EditUser } from 'views/UsersView/EditUser/EditUser';
import { ResetPassword } from 'views/UsersView/ResetPassword/ResetPassword';
import { removeDotsAts } from 'utils/helpers';

type UsersTableRowProps = {
  user: User;
  fetchUsers: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

const UsersTableRow = ({
  user,
  setFeedback,
  fetchUsers,
}: UsersTableRowProps) => {
  const classes = useStyles();
  const email = removeDotsAts(user.email);
  return (
    <TableBodyRow key={email} id="user-data-row">
      <DataCell
        id={`${email}-name-cell`}
      >{`${user.firstName} ${user.lastName}`}</DataCell>
      <DataCell id={`${email}-email-cell`}>{user.email}</DataCell>
      <DataCell id={`${email}-role-cell`}>{user.role}</DataCell>
      <DataCell
        id={`${email}-actions-cell`}
        className={classes.actionsCell}
        data-cy="actions-cell"
      >
        <EditUser
          user={user}
          setFeedback={setFeedback}
          fetchUsers={fetchUsers}
        />
        <ResetPassword user={user} setFeedback={setFeedback} />
        <DeleteUser
          user={user}
          setFeedback={setFeedback}
          fetchUsers={fetchUsers}
        />
      </DataCell>
    </TableBodyRow>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsCell: {
    textAlign: 'center',
    width: '180px',
    padding: theme.spacing(0.5),
  },
}));

export default UsersTableRow;
