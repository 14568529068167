import { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Rect, Transformer } from 'react-konva';
import { Transformer as TransformerClass } from 'konva/lib/shapes/Transformer';
import { DetectionAreaState } from 'components/KonvaShapes/types';
import { shapeConfig } from 'components/KonvaShapes/RectangleWithRatio/constants';
import {
  normalizeValuePX,
  normalizeValue01,
} from 'components/KonvaShapes/helpers';
import Konva from 'konva';
import { DetectionArea } from 'types/analyticsTypes';
import { stagePadding, layerOffset } from 'components/KonvaShapes/constants';

interface RectProps {
  width: number | undefined;
  height: number | undefined;
  detectionAreaState: DetectionAreaState;
}

const RectangleWithRatio = ({
  width,
  height,
  detectionAreaState,
}: RectProps) => {
  const originalWidth = useRef(width);
  const originalHeight = useRef(height);
  const shapeRef = useRef<null | Konva.Rect>(null);
  const trRef = useRef<null | Konva.Transformer>(null);
  const [rectHeight, setRectHeight] = useState(0);
  const [rectWidth, setRectWidth] = useState(0);
  const [coords, setCoords] = useState<DetectionArea>([
    { x: 0, y: 0 },
    { x: 0, y: 0 },
  ]);

  useEffect(() => {
    if (!width || !height) return;
    const normalized = detectionAreaState.detectionArea.map((node) => {
      return normalizeValuePX(node, width, height, scaleX, scaleY);
    });

    setCoords(normalized);
    const computedHeight = normalized[1].y - normalized[0].y;
    const computedWidth = normalized[1].x - normalized[0].x;
    setRectHeight(computedHeight);
    setRectWidth(computedWidth);
    // eslint-disable-next-line
  }, []);

  let scaleX = 1;
  let scaleY = 1;

  if (width && originalWidth.current) {
    scaleX = width / originalWidth.current;
  }

  if (height && originalHeight.current) {
    scaleY = height / originalHeight.current;
  }

  useEffect(() => {
    if (!trRef.current || !shapeRef.current) {
      return;
    }

    trRef.current.nodes([shapeRef.current]);
    trRef.current!.getLayer()!.batchDraw();
  }, []);

  const calcCoors = (event: Konva.KonvaEventObject<Event>) => {
    if (!width || !height) return;

    const upperLeftCorner = { x: event.target.x(), y: event.target.y() };

    const scale = event.target.scale() || { x: 1, y: 1 };

    const lowerRightCornerX = event.target.x() + event.target.width() * scale.x;
    const lowerRightCornerY =
      event.target.y() + event.target.height() * scale.y;

    const lowerRightCorner = { x: lowerRightCornerX, y: lowerRightCornerY };
    setCoords([upperLeftCorner, lowerRightCorner]);
    const normalized = [
      normalizeValue01(upperLeftCorner, width, height, scaleX, scaleY),
      normalizeValue01(lowerRightCorner, width, height, scaleX, scaleY),
    ];

    detectionAreaState.setDetectionArea(normalized);
  };

  return width && height ? (
    <>
      <Stage
        width={width + stagePadding}
        height={height + stagePadding}
        scaleX={scaleX}
        scaleY={scaleY}
      >
        <Layer offset={{ x: -layerOffset / scaleX, y: -layerOffset / scaleY }}>
          <Rect
            ref={shapeRef}
            x={coords[0].x}
            y={coords[0].y}
            width={rectWidth}
            height={rectHeight}
            onMouseOver={(e) => {
              const container = e.target.getStage()!.container();
              container.style.cursor = 'pointer';
            }}
            onMouseLeave={(e) => {
              const container = e.target.getStage()!.container();
              container.style.cursor = 'default';
            }}
            {...shapeConfig}
            dragBoundFunc={(pos) => {
              if (!shapeRef.current || !trRef.current) {
                return {
                  x: 0,
                  y: 0,
                };
              }
              const scale = shapeRef.current.scale() || { x: 1, y: 1 };
              const currentWidth = rectWidth * scale.x * scaleX;
              const currentHeight = rectHeight * scale.y * scaleX;

              let x = pos.x;
              let y = pos.y;

              x = Math.max(pos.x, layerOffset);
              x = Math.min(x, width + layerOffset - currentWidth);

              y = Math.max(pos.y, layerOffset);
              y = Math.min(y, height + layerOffset - currentHeight);

              return {
                x,
                y,
              };
            }}
            onDragEnd={(event) => {
              calcCoors(event);
            }}
          />
          <Transformer
            ref={trRef}
            rotateEnabled={false}
            flipEnabled={false}
            ignoreStroke={true}
            anchorStrokeWidth={2}
            enabledAnchors={[
              'top-left',
              'top-right',
              'bottom-left',
              'bottom-right',
            ]}
            boundBoxFunc={(oldBox, newBox) => {
              const isOut =
                newBox.x < 9.09 ||
                newBox.y < 9.09 ||
                newBox.x + newBox.width > width + 10.01 ||
                newBox.y + newBox.height > height + 10.01;

              if (isOut) {
                return oldBox;
              }
              return newBox;
            }}
            onTransform={(event) => {
              const transformer = event.currentTarget;

              if (transformer instanceof TransformerClass) {
                const activeAnchor = transformer.getActiveAnchor()!;
                const container = event.target.getStage()!.container();

                if (
                  activeAnchor === 'bottom-left' ||
                  activeAnchor === 'top-right'
                ) {
                  container.style.cursor = 'nesw-resize';
                } else {
                  container.style.cursor = 'nwse-resize';
                }
              }
            }}
            onTransformEnd={(event) => {
              const container = event.target.getStage()!.container();
              container.style.cursor = 'default';
              calcCoors(event);
            }}
          />
        </Layer>
      </Stage>
    </>
  ) : null;
};

export default RectangleWithRatio;
