import { FC, ReactNode } from 'react';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from 'theme';
import { AuthProvider } from 'context/providers/AuthProvider';
import { AntdConfigProvider } from './providers/AndConfigProvider';
import 'dayjs/locale/pl';
import 'dayjs/locale/es';
import 'dayjs/locale/pt';
import 'dayjs/locale/en';

const AppProviders: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AntdConfigProvider>
        <AuthProvider>
          <HashRouter>{children}</HashRouter>
        </AuthProvider>
      </AntdConfigProvider>
    </ThemeProvider>
  );
};

export default AppProviders;
