import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';

type SelectAnalyticsProps = {
  handleChange: (analytics: AnalyticsTypes) => void;
  selectedAnalytics: AnalyticsTypes | null;
  availableAnalytics: AnalyticsTypes[] | null;
};

export const AnalyticsSelect = ({
  handleChange,
  selectedAnalytics,
  availableAnalytics,
}: SelectAnalyticsProps) => {
  const classes = useStyles();

  return (
    <div className={classes.selectAnalyticsContainer}>
      {availableAnalytics &&
        availableAnalytics.map((analytics) => {
          return (
            <div className={classes.outer} key={analytics}>
              <img
                src={`analyticsLogos/${analytics}.svg`}
                alt="analytics-logo"
                draggable={false}
                className={clsx(classes.img, {
                  [classes.disabled]: analytics !== selectedAnalytics,
                })}
                onClick={() => handleChange(analytics)}
              />
            </div>
          );
        })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  img: {
    width: '80px',
  },
  selectAnalyticsContainer: {
    display: 'flex',
  },
  outer: {
    marginLeft: theme.spacing(3),
    height: '80px',
  },
  disabled: {
    filter: 'grayscale(1)',
    cursor: 'pointer',
  },
}));
