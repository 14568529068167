import { apiOrigin } from 'api/config/interceptors';
import {
  CreateUserDTO,
  UpdateUserDTO,
  ResetPasswordDTO,
} from 'types/userTypes';

export const getUsers = () => {
  return apiOrigin.get(`/user/list`).then((res) => {
    return res.data;
  });
};

export const createUser = (createUserDTO: CreateUserDTO) => {
  return apiOrigin.post(`user/auth/register`, createUserDTO).then((res) => {
    return res.data;
  });
};

export const deleteUser = (email: string) => {
  return apiOrigin.delete(`user/${email}`).then((res) => {
    return res.data;
  });
};

export const updateUser = (email: string, updateUserDTO: UpdateUserDTO) => {
  return apiOrigin.patch(`user/${email}`, updateUserDTO).then((res) => {
    return res.data;
  });
};

export const resetPassword = (resetPasswordDTO: ResetPasswordDTO) => {
  return apiOrigin
    .post('user/auth/resetPassword', resetPasswordDTO)
    .then((res) => {
      return res.data;
    });
};
