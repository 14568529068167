import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from 'components/Button/IconButton';
import { AlertSeverity } from 'components/Alert/Toast';
import { EditUserDialog } from 'views/UsersView/EditUser/EditUserDialog';
import { editUserValidationSchema } from 'validation/editUserValidationSchema';
import { updateUser } from 'api/usersApi';
import { UserRole } from 'types/authTypes';
import { User, UpdateUserDTO } from 'types/userTypes';
import { removeDotsAts } from 'utils/helpers';

type EditUserProps = {
  user: User;
  fetchUsers: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export type EditValues = {
  firstName: string;
  lastName: string;
  role: UserRole;
};

export const EditUser = ({ user, setFeedback, fetchUsers }: EditUserProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues: EditValues = {
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: UpdateUserDTO) => {
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      updateUser(user.email, values)
        .then(() => {
          fetchUsers();
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.SUCCESS,
            msg: 'user-edit-success',
          });
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description
              ? error.response.data.description
              : 'general-error',
          });
        });
    },
    validationSchema: editUserValidationSchema,
  });

  return (
    <>
      <Tooltip title={t('users.edit')}>
        <IconButton
          className={classes.actionsButton}
          onClick={() => setEditDialogOpen(true)}
          id={`${removeDotsAts(user.email)}-edit-button`}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <EditUserDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        email={user.email}
        formik={formik}
        loading={loading}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsButton: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  },
}));
