import * as Yup from 'yup';
import i18n from 'translations/i18n';

export const editStreamValidationSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required(i18n.t('validation.required-field')),
    url: Yup.string().required(i18n.t('validation.required-field')),
    password: Yup.string()
      .when('changePassword', {
        is: true,
        then: Yup.string().required(i18n.t('validation.required-field')),
      })
      .nullable(),
  });
};
