import { AnalyticsSelect } from 'components/Other/ColorIndicator/AnalyticsSelect';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { useStreamData } from 'context/providers/StreamDataProvider';
import { useStreamsAnalytics } from 'context/providers/StreamsAnalyticsProvider';
import { useParams } from 'react-router-dom';

export const SelectAnalyticsDetails = () => {
  const { selectedAnalytics, setSelectedAnalytics } = useStreamData();
  const { savedAnalytics, updateSavedAnalytics } = useStreamsAnalytics();
  const { enabledAnalytics } = useStreamData();
  const { streamId } = useParams<{ streamId: string }>();

  const handleChange = (analytics: AnalyticsTypes) => {
    if (!savedAnalytics || !streamId) return;

    const newAnalytics = { ...savedAnalytics };
    newAnalytics[streamId] = analytics;

    setSelectedAnalytics(analytics as AnalyticsTypes);
    updateSavedAnalytics(newAnalytics);
  };

  return (
    <AnalyticsSelect
      handleChange={handleChange}
      selectedAnalytics={selectedAnalytics}
      availableAnalytics={enabledAnalytics}
    />
  );
};
