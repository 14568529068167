import { useEffect, useState, useCallback } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useAuth } from 'context/providers/AuthProvider';
import { UsersTable } from 'views/UsersView/UsersTable/UsersTable';
import { getUsers } from 'api/usersApi';
import CircularLoader from 'components/Progress/CircularLoader';
import { User } from 'types/userTypes';
import { AddUserButton } from 'views/UsersView/AddUser/AddUserButton';
import { AddUserDialog } from './AddUser/AddUserDialog';
import Toast, { AlertSeverity } from 'components/Alert/Toast';

const UsersView = () => {
  const classes = useStyles();
  const { loggedInUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [feedback, setFeedback] = useState<{
    open: boolean;
    status: AlertSeverity;
    msg: string;
  }>({ open: false, status: AlertSeverity.SUCCESS, msg: '' });

  const fetchUsers = useCallback(() => {
    setUsersLoading(true);
    getUsers()
      .then((res) => {
        const sorted = res.items.sort((a: User, b: User) =>
          a.firstName.localeCompare(b.firstName)
        );
        setUsers(sorted);
        setUsersLoading(false);
      })
      .catch(() => {
        setUsersLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, loggedInUser]);

  if (usersLoading) {
    return <CircularLoader className={classes.loader} />;
  }

  return (
    <Box className={classes.container}>
      <div className={classes.row}>
        <AddUserButton onClick={() => setAddDialogOpen(true)} />
        <AddUserDialog
          open={addDialogOpen}
          onClose={() => setAddDialogOpen(false)}
          fetchUsers={fetchUsers}
          setFeedback={setFeedback}
        />
      </div>
      <UsersTable
        users={users}
        setFeedback={setFeedback}
        fetchUsers={fetchUsers}
      />
      {feedback.open && (
        <Toast
          setState={setFeedback}
          severity={feedback.status}
          msg={feedback.msg}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '1800px',
  },
  loader: {
    paddingTop: '30vh',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export default UsersView;
