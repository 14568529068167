import { makeStyles, SelectProps, FormHelperText } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

enum AnchorOriginHorizontal {
  LEFT = 'left',
  RIGHT = 'right',
}

enum AnchorOriginVertical {
  BOTTOM = 'bottom',
  TOP = 'top',
  CENTER = 'center',
}

const MenuProps = {
  anchorOrigin: {
    vertical: AnchorOriginVertical.BOTTOM,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  transformOrigin: {
    vertical: AnchorOriginVertical.TOP,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  getContentAnchorEl: null,
};

type SelectBaseProps = SelectProps & {
  items: string[] | { value: string | number; label?: string }[];
  label?: string;
  name?: string;
  helperText?: string | null;
};

const SelectBase = ({
  items,
  label,
  name,
  className,
  error,
  helperText,
  id,
  ...props
}: SelectBaseProps) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth error={error} className={className}>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Select
        className={classes.select}
        MenuProps={{ ...MenuProps, classes: { paper: classes.selectMenu } }}
        inputProps={{ name: name, id: `${id}-input` }}
        id={id}
        {...props}
      >
        {items.map((item) => {
          return (
            <MenuItem
              key={typeof item === 'string' ? item : item.value}
              id={typeof item === 'string' ? item : item.value.toString()}
              value={typeof item === 'string' ? item : item.value}
              classes={{ root: classes.menuItem }}
            >
              {typeof item === 'string' ? item : item.label}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  select: {
    fontWeight: 400,
    '&.Mui-disabled': {
      color: theme.palette.grey[200],
    },
    '&.MuiInput-underline.Mui-disabled:before': {
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  label: {
    fontWeight: 400,
  },
  selectMenu: {
    border: '1px solid black',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
  },
  menuItem: {
    fontWeight: 400,
  },
}));

export default SelectBase;
