import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SliderContinous from 'components/Slider/SliderContinuous';
import { EventTimers } from 'types/analyticsTypes';
import CheckboxWithLabel from 'components/Checkbox/CheckboxWithLabel';
import { minutesToHoursFormatter } from 'components/Slider/helpers';
import SliderContWithInput from 'components/Slider/SliderContWithInput';
import clsx from 'clsx';

type ReportingModeSettingsProps = {
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
  className?: string;
};

export const ReportingModeSettings = ({
  eventTimers,
  setEventTimers,
  className,
}: ReportingModeSettingsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [maxInterval, setMaxInterval] = useState(
    eventTimers.reportingSettings.analysedTimeFrameSeconds
  );
  const [analysedTimeFrame, setAnalysedTimeFrame] = useState<string | number>(
    eventTimers.reportingSettings.analysedTimeFrameSeconds
  );
  const [analysisInterval, setAnalysisInterval] = useState<string | number>(
    eventTimers.reportingSettings.analysisIntervalSeconds
  );
  const [eventOccurrence, setEventOccurrence] = useState(
    eventTimers.reportingSettings.cumulativeEventOccurrenceTime
  );

  const setReportingSettings = (value: number, name: string) => {
    setEventTimers({
      ...eventTimers,
      reportingSettings: {
        ...eventTimers.reportingSettings,
        [name]: value,
      },
    });
  };

  const setReportingSettingsEnabled = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setEventTimers({
      ...eventTimers,
      reportingSettings: {
        ...eventTimers.reportingSettings,
        isEnabled: checked,
      },
    });
  };

  const commitTimeFrame = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[]
  ) => {
    if (typeof value !== 'number') return;
    if (typeof analysisInterval !== 'number') return;

    if (value && value > 0) {
      setMaxInterval(value);
    } else {
      setMaxInterval(1);
    }

    const newTimeFrame = value;
    let newInterval = analysisInterval;

    if (value < analysisInterval && value > 0) {
      if (value) {
        newInterval = value;
      } else {
        newInterval = 1;
      }
    }

    setAnalysisInterval(newInterval);
    setAnalysedTimeFrame(newTimeFrame);

    setEventTimers({
      ...eventTimers,
      reportingSettings: {
        ...eventTimers.reportingSettings,
        analysedTimeFrameSeconds: newTimeFrame,
        analysisIntervalSeconds: newInterval as number,
      },
    });
  };

  const commitInterval = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[]
  ) => {
    if (typeof value !== 'number') return;

    setReportingSettings(value, 'analysisIntervalSeconds');
  };

  const commitEventOcurrence = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[]
  ) => {
    if (typeof value !== 'number') return;

    setReportingSettings(value, 'cumulativeEventOccurrenceTime');
  };

  return (
    <div className={clsx(className, classes.reportingSettingsContainer)}>
      <Typography className={classes.heading} align="center" variant="h3">
        {t('stream-settings.reporting-mode-settings')}
      </Typography>
      <Typography variant="body2" className={classes.margin}>
        {t('stream-settings.reporting-mode-description')}
      </Typography>
      <CheckboxWithLabel
        label={t('stream-settings.enable-reporting')}
        checked={eventTimers.reportingSettings.isEnabled}
        onChange={setReportingSettingsEnabled}
        labelClassName={classes.checkboxLabel}
        className={classes.margin}
        id="enable-reporting-checkbox"
      />
      <SliderContWithInput
        name="analysedTimeFrameSeconds"
        label={t('stream-settings.time-frame')}
        value={analysedTimeFrame}
        setValue={(value) => setAnalysedTimeFrame(value)}
        min={1}
        max={180}
        valueFormatter={minutesToHoursFormatter}
        description={t('stream-settings.time-frame-description')}
        onChangeCommitted={commitTimeFrame}
        id="timeframe-slider-reporting"
      />
      <SliderContWithInput
        name="analysisIntervalSeconds"
        label={t('stream-settings.reporting-interval')}
        value={analysisInterval}
        setValue={(value) => setAnalysisInterval(value)}
        min={1}
        max={maxInterval}
        valueFormatter={minutesToHoursFormatter}
        description={t('stream-settings.interval-description')}
        onChangeCommitted={commitInterval}
        id="interval-slider-reporting"
      />
      <SliderContinous
        name="cumulativeEventOccurrenceTime"
        value={eventOccurrence}
        min={1}
        max={100}
        setValue={setEventOccurrence}
        label={t('stream-settings.event-occurrence-time')}
        customValueLabel
        description={t('stream-settings.event-ocurrence-description')}
        onChangeCommitted={commitEventOcurrence}
        id="occurance-slider-reporting"
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    fontWeight: 500,
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  reportingSettingsContainer: {
    maxWidth: '500px',
    minWidth: '350px',
    flexGrow: 1,
  },
}));
