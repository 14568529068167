import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetectionBox } from 'views/StreamDetails/Preview/components/DetectionBox';
import { ReactComponent as Smoke } from 'icons/smoke.svg';
import { ReactComponent as Fire } from 'icons/fire.svg';
import { FireSmokeStreamData } from 'types/analytics/fire/FireSmoke';

type FireSmokeMetaDataProps = {
  metaData: FireSmokeStreamData | undefined;
};

export const FireSmokeMetaData = ({ metaData }: FireSmokeMetaDataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const fireDetected = metaData?.isFireDetected;
  const smokeDetected = metaData?.isSmokeDetected;

  return (
    <div className={classes.detections}>
      <DetectionBox
        label={
          fireDetected
            ? t('stream-preview.fire.fire-detected')
            : t('stream-preview.fire.fire-not-detected')
        }
        Icon={Fire}
        isDetected={fireDetected}
      />
      <DetectionBox
        label={
          smokeDetected
            ? t('stream-preview.fire.smoke-detected')
            : t('stream-preview.fire.smoke-not-detected')
        }
        Icon={Smoke}
        isDetected={smokeDetected}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  detections: {
    display: 'flex',
  },
}));
