import { ReactNode } from 'react';

type TabPanelProps = {
  children: ReactNode;
  index: number;
  value: number;
  className?: string;
};

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

export default TabPanel;
