import { FocusEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import { FormikContextType } from 'formik';
import { useTranslation } from 'react-i18next';
import DialogActions from 'components/Dialog/DialogActions';
import TextFieldBase from 'components/Input/TextField/TextFieldBase';
import PasswordInput from 'components/Input/TextField/PasswordInput';
import { handleBlurAndTrim } from 'utils/helpers';
import { AddStreamValues } from 'views/StreamsView/AddStream/AddStreamDialog';
import { useAnalytics } from 'context/providers/AnalyticsProvider';
import { MultipleSelectAnalytics } from 'components/Input/Select/MultipleSelectAnalytics';
import { SelectCameraZone } from 'components/Input/Select/SelectCameraZone';

type AddStreamFormProps = {
  formik: FormikContextType<AddStreamValues>;
  loading: boolean;
  handleClose: () => void;
};

export const AddStreamForm = ({
  formik,
  loading,
  handleClose,
}: AddStreamFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { availableAnalytics } = useAnalytics();

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldBase
        required
        name="name"
        id="stream-name"
        maxLength={255}
        disabled={loading}
        value={formik.values.name}
        label={t('streams.stream-name')}
        className={classes.input}
        onChange={formik.handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        error={Boolean(formik.errors.name && formik.touched.name)}
        helperText={
          formik.errors.name && formik.touched.name ? formik.errors.name : null
        }
      />
      <TextFieldBase
        required
        name="url"
        id="stream-url"
        maxLength={511}
        disabled={loading}
        value={formik.values.url}
        label={t('streams.stream-url')}
        className={classes.input}
        onChange={formik.handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        error={Boolean(formik.errors.url && formik.touched.url)}
        helperText={
          formik.errors.url && formik.touched.url ? formik.errors.url : null
        }
      />
      <TextFieldBase
        name="username"
        id="stream-username"
        maxLength={255}
        disabled={loading}
        value={formik.values.username}
        label={t('streams.stream-username')}
        className={classes.inputWithouthelper}
        onChange={formik.handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
      />
      <PasswordInput
        name="password"
        id="stream-password"
        maxLength={255}
        disabled={loading}
        value={formik.values.password}
        label={t('streams.stream-password')}
        onChange={formik.handleChange}
        onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) =>
          handleBlurAndTrim(event, formik)
        }
      />
      <SelectCameraZone
        name="assignedTagsIds"
        id="add-stream-selectCameraZone"
        label={t('streams.stream-zone')}
        value={formik.values.assignedTagsIds}
        onChange={formik.handleChange}
        className={classes.formControl}
      />
      <MultipleSelectAnalytics
        name="selectedAnalytics"
        id="add-stream-selectedAnalytics"
        label={t('streams.enable-analytics')}
        items={availableAnalytics}
        value={formik.values.selectedAnalytics}
        onChange={formik.handleChange}
        selectClass={classes.select}
        formControlClass={classes.formControl}
      />
      <DialogActions
        onCancel={handleClose}
        isLoading={loading}
        isValid={formik.dirty && formik.isValid}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(3),
  },
  inputWithouthelper: {
    marginBottom: theme.spacing(2),
  },
  select: {
    marginBottom: theme.spacing(5),
  },
  formControl: {
    marginTop: theme.spacing(2),
  },
}));
