import { ChangeEvent, useState } from 'react';
import { Tabs, Tab, makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StreamPreview from 'views/StreamDetails/Preview/StreamPreview';
import AnalyticsSettings from 'views/StreamDetails/Settings/AnalyticsSettings';
import TabPanel from 'components/Tabs/TabPanel';
import { useAnalyticsSettings } from 'context/providers/AnalyticsSettingsProvider';
import { SelectAnalyticsDetails } from 'views/StreamDetails/SelectAnalyticsDetails';
import { isEmpty } from 'lodash';
import { useStreamData } from 'context/providers/StreamDataProvider';
import { AnalyticsActions } from './Settings/AnalyticsActions';
import Toast, { AlertSeverity } from 'components/Alert/Toast';

const StreamDetails = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(1);
  const { enabledAnalytics } = useStreamData();
  const { fetchAnalyticsSettings } = useAnalyticsSettings();

  const [feedback, setFeedback] = useState<{
    open: boolean;
    status: AlertSeverity;
    msg: string;
  }>({ open: false, status: AlertSeverity.SUCCESS, msg: '' });

  const handleChange = (
    event: ChangeEvent<Record<string, unknown>>,
    newValue: number
  ) => {
    setValue(newValue);
    setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });

    if (newValue === 1) {
      // reset settings when user switches to preview
      fetchAnalyticsSettings();
    }
  };

  return (
    <>
      {!isEmpty(enabledAnalytics) ? (
        <>
          <div className={classes.tabsAndSelect}>
            <Box className={classes.tabsContainer}>
              <Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}
              >
                <Tab
                  value={1}
                  className={classes.tab}
                  label={t('stream-details.preview')}
                  id="preview-button"
                />
                <Tab
                  value={2}
                  className={classes.tab}
                  label={t('stream-details.settings')}
                  id="settings-button"
                />
              </Tabs>
            </Box>
            <SelectAnalyticsDetails />
          </div>
          <TabPanel index={1} value={value}>
            <StreamPreview />
          </TabPanel>
          <TabPanel index={2} value={value}>
            <AnalyticsSettings />
            <AnalyticsActions setFeedback={setFeedback} />
            {feedback.open && (
              <Toast
                setState={setFeedback}
                severity={feedback.status}
                msg={feedback.msg}
              />
            )}
          </TabPanel>
        </>
      ) : (
        <>{enabledAnalytics && <StreamPreview />}</>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  tab: {
    height: '44px',
    borderRadius: '5px 5px 0px 0px',
    color: theme.palette.text.primary,
    opacity: 1,
    '&:hover': {
      backgroundColor: theme.palette.secondary.hover,
    },
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
    },
  },
  tabsAndSelect: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '1800px',
  },
}));

export default StreamDetails;
