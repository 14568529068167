import { makeStyles, ButtonProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'components/Button/ButtonPrimary';
import AddIcon from '@material-ui/icons/Add';

export const AddStreamButton = ({ onClick }: ButtonProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ButtonPrimary
      startIcon={<AddIcon />}
      className={classes.addStreamButton}
      onClick={onClick}
      id="add-stream-button"
    >
      {t('streams.add')}
    </ButtonPrimary>
  );
};

const useStyles = makeStyles(() => ({
  addStreamButton: {
    height: '44px',
  },
}));
