import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { FallDetection } from 'views/StreamDetails/Settings/Analytics/FallDetection/FallDetection';
import { FireSmokeTunnel } from 'views/StreamDetails/Settings/Analytics/Fire/FireSmokeTunnel/FireSmokeTunnel';
import { FireSmoke } from 'views/StreamDetails/Settings/Analytics/Fire/FireSmoke/FireSmoke';
import { PPE } from 'views/StreamDetails/Settings/Analytics/PPE/PPE';
import { FlareInspection } from 'views/StreamDetails/Settings/Analytics/Fire/FlareInspection/FlareInspection';
import { IntruderDetection } from 'views/StreamDetails/Settings/Analytics/IntruderDetection/IntruderDetection';
import { useAnalyticsSettings } from 'context/providers/AnalyticsSettingsProvider';
import { FallDetectionSettings } from 'types/analytics/FallDetection';
import { FireSmokeSettings } from 'types/analytics/fire/FireSmoke';
import { PPESettings } from 'types/analytics/PPE';
import { useAuth } from 'context/providers/AuthProvider';
import { FlareInspectionSettings } from 'types/analytics/fire/FlareInspection';
import { useStreamData } from 'context/providers/StreamDataProvider';
import { AnalyticsSettingsPlaceholder } from './AnalyticsSettingsPlaceholder';
import { IntruderDetectionSettings } from 'types/analytics/IntruderDetection';

const AnalyticsSettings = () => {
  const { selectedAnalytics } = useStreamData();
  const classes = useStyles();
  const { t } = useTranslation();
  const { isGuest } = useAuth();
  const {
    analyticsSettings,
    setAnalyticsSettings,
    eventTimers,
    setEventTimers,
  } = useAnalyticsSettings();

  if (analyticsSettings && selectedAnalytics !== analyticsSettings.type)
    return <AnalyticsSettingsPlaceholder />;

  const renderSwitch = () => {
    switch (selectedAnalytics) {
      case AnalyticsTypes.FallDetection:
        const fallSettings = analyticsSettings as FallDetectionSettings;
        const setFallSettings = setAnalyticsSettings as Dispatch<
          SetStateAction<FallDetectionSettings>
        >;

        return (
          <FallDetection
            settings={fallSettings}
            setSettings={setFallSettings}
            eventTimers={eventTimers}
            setEventTimers={setEventTimers}
          />
        );
      case AnalyticsTypes.FireSmoke:
        const fireSmokeSettings = analyticsSettings as FireSmokeSettings;
        const setFireSmokeSettings = setAnalyticsSettings as Dispatch<
          SetStateAction<FireSmokeSettings>
        >;

        return (
          <FireSmoke
            settings={fireSmokeSettings}
            setSettings={setFireSmokeSettings}
            eventTimers={eventTimers}
            setEventTimers={setEventTimers}
          />
        );
      case AnalyticsTypes.FireSmokeTunnel:
        const fireSmokeTunnelSettings = analyticsSettings as FireSmokeSettings;
        const setFireSmokeTunnelSettings = setAnalyticsSettings as Dispatch<
          SetStateAction<FireSmokeSettings>
        >;

        return (
          <FireSmokeTunnel
            settings={fireSmokeTunnelSettings}
            setSettings={setFireSmokeTunnelSettings}
            eventTimers={eventTimers}
            setEventTimers={setEventTimers}
          />
        );
      case AnalyticsTypes.FlareInspection:
        const flareInspectionSettings =
          analyticsSettings as FlareInspectionSettings;
        const setFlareInspectionSettings = setAnalyticsSettings as Dispatch<
          SetStateAction<FlareInspectionSettings>
        >;

        return (
          <FlareInspection
            settings={flareInspectionSettings}
            setSettings={setFlareInspectionSettings}
            eventTimers={eventTimers}
            setEventTimers={setEventTimers}
          />
        );
      case AnalyticsTypes.PPE:
        const ppeSettings = analyticsSettings as PPESettings;
        const setPpeSettings = setAnalyticsSettings as Dispatch<
          SetStateAction<PPESettings>
        >;

        return (
          <PPE
            settings={ppeSettings}
            setSettings={setPpeSettings}
            eventTimers={eventTimers}
            setEventTimers={setEventTimers}
          />
        );
      case AnalyticsTypes.IntruderDetection:
        const intruderSettings = analyticsSettings as IntruderDetectionSettings;
        const setIntruderSettings = setAnalyticsSettings as Dispatch<
          SetStateAction<IntruderDetectionSettings>
        >;

        return (
          <IntruderDetection
            settings={intruderSettings}
            setSettings={setIntruderSettings}
            eventTimers={eventTimers}
            setEventTimers={setEventTimers}
          />
        );
    }
  };

  return (
    <div className={classes.settings}>
      {isGuest && (
        <Alert severity="info" className={classes.alert}>
          {t('stream-settings.guest-user-info')}
        </Alert>
      )}
      {renderSwitch()}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  settings: {
    paddingBottom: theme.spacing(18),
  },
  alert: {
    marginTop: theme.spacing(3),
    fontWeight: 500,
  },
}));

export default AnalyticsSettings;
