import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { FireSmokeSettings } from 'types/analytics/fire/FireSmoke';
import { EventTimers } from 'types/analyticsTypes';
import { FireThresholds } from 'views/StreamDetails/Settings/Analytics/Fire/FireThresholds';
import { AlarmingModeSettings } from 'views/StreamDetails/Settings/AlarmingModeSettings';
import { ReportingModeSettings } from 'views/StreamDetails/Settings/ReportingModeSettings';
import clsx from 'clsx';

type FireSmokeTunnelProps = {
  settings: FireSmokeSettings;
  setSettings: Dispatch<SetStateAction<FireSmokeSettings>>;
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
};

export const FireSmokeTunnel = ({
  settings,
  setSettings,
  eventTimers,
  setEventTimers,
}: FireSmokeTunnelProps) => {
  const classes = useStyles();

  return (
    <div className={classes.settingsContainer}>
      <div className={clsx(classes.thresholds, classes.margin)}>
        <FireThresholds settings={settings} setSettings={setSettings} />
      </div>
      <AlarmingModeSettings
        eventTimers={eventTimers}
        setEventTimers={setEventTimers}
        className={classes.margin}
      />
      <ReportingModeSettings
        eventTimers={eventTimers}
        setEventTimers={setEventTimers}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(6),
  },
  settingsContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
  },
  thresholds: {
    maxWidth: '450px',
    minWidth: '300px',
    flexGrow: 1,
  },
}));
