import { FocusEvent, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import DialogActions from 'components/Dialog/DialogActions';
import TextFieldBase from 'components/Input/TextField/TextFieldBase';
import PasswordInput from 'components/Input/TextField/PasswordInput';
import { handleBlurAndTrim } from 'utils/helpers';
import CheckboxWithLabel from 'components/Checkbox/CheckboxWithLabel';
import { EditValues } from 'views/StreamsView/EditStream/EditStream';
import { SelectCameraZone } from 'components/Input/Select/SelectCameraZone';

type EditStreamFormProps = {
  formik: FormikContextType<EditValues>;
  loading: boolean;
  handleClose: () => void;
};

export const EditStreamForm = ({
  formik,
  loading,
  handleClose,
}: EditStreamFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleEditPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    if (formik.values.deletePassword) {
      formik.setFieldValue('deletePassword', false, false);
    }
  };

  const handleDeletePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    if (formik.values.changePassword) {
      formik.setFieldValue('changePassword', false);
    }
    formik.setFieldValue('password', '', false);
    formik.setFieldTouched('password', false, false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextFieldBase
        required
        name="name"
        id="stream-name"
        maxLength={255}
        disabled={loading}
        value={formik.values.name}
        label={t('streams.stream-name')}
        className={classes.input}
        onChange={formik.handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        error={Boolean(formik.errors.name && formik.touched.name)}
        helperText={
          formik.errors.name && formik.touched.name ? formik.errors.name : null
        }
      />
      <TextFieldBase
        required
        name="url"
        id="stream-url"
        maxLength={511}
        disabled={loading}
        value={formik.values.url}
        label={t('streams.stream-url')}
        className={classes.input}
        onChange={formik.handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        error={Boolean(formik.errors.url && formik.touched.url)}
        helperText={
          formik.errors.url && formik.touched.url ? formik.errors.url : null
        }
      />
      <SelectCameraZone
        name="assignedTags"
        id="stream-camera-zone"
        label={t('streams.stream-zone')}
        value={formik.values.assignedTags}
        onChange={formik.handleChange}
        className={classes.smallMargin}
      />
      <TextFieldBase
        name="username"
        id="stream-username"
        maxLength={255}
        disabled={loading}
        value={formik.values.username}
        label={t('streams.stream-username')}
        className={classes.smallMargin}
        onChange={formik.handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
      />
      {formik.values.passwordExists && (
        <>
          <CheckboxWithLabel
            label={t('streams.delete-password')}
            name="deletePassword"
            disabled={loading}
            value={formik.values.deletePassword}
            checked={formik.values.deletePassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleDeletePasswordChange(event)
            }
          />
          <CheckboxWithLabel
            label={t('streams.change-password')}
            name="changePassword"
            disabled={loading}
            value={formik.values.changePassword}
            checked={formik.values.changePassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleEditPasswordChange(event)
            }
          />
        </>
      )}
      <PasswordInput
        required={formik.values.changePassword}
        name="password"
        id="stream-password"
        maxLength={255}
        disabled={
          (formik.values.passwordExists && !formik.values.changePassword) ||
          loading
        }
        value={formik.values.password}
        label={
          formik.values.passwordExists
            ? t('streams.new-password')
            : t('streams.stream-password')
        }
        className={classes.margin}
        onChange={formik.handleChange}
        onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) =>
          handleBlurAndTrim(event, formik)
        }
        error={Boolean(formik.errors.password && formik.touched.password)}
        helperText={
          formik.errors.password && formik.touched.password
            ? formik.errors.password
            : null
        }
      />
      <DialogActions
        onCancel={handleClose}
        isLoading={loading}
        isValid={formik.dirty && formik.isValid}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(3),
  },
  smallMargin: {
    marginBottom: theme.spacing(2),
  },
  margin: {
    marginBottom: theme.spacing(5),
  },
}));
