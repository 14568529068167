import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { JWTUser, UserRole } from 'types/authTypes';
import { setupInterceptors } from 'api/config/interceptors';
import { decodeToken } from 'utils/decodeJWT';

export type AuthContextValue = {
  loggedInUser?: JWTUser;
  login: (user: JWTUser) => void;
  logout: () => void;
  fetchingUser: boolean;
  isAdmin: boolean;
  isGuest: boolean;
};

export type AuthProviderProps = {
  children: ReactNode;
};

const defaultValue: AuthContextValue = {
  loggedInUser: undefined,
  login: () => {},
  logout: () => {},
  fetchingUser: true,
  isAdmin: false,
  isGuest: false,
};

const AuthContext = createContext(defaultValue);

const AuthProvider: FC<AuthProviderProps> = (props: AuthProviderProps) => {
  const [loggedInUser, setLoggedInUser] = useState<JWTUser | undefined>(
    undefined
  );
  const [fetchingUser, setFetchingUser] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isGuest, setIsGuest] = useState(false);

  const login = useCallback((userData: JWTUser) => {
    setLoggedInUser({ ...userData });

    switch (userData.role) {
      case UserRole.ADMINISTRATOR:
        setIsAdmin(true);
        break;
      case UserRole.GUEST:
        setIsGuest(true);
        break;
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setLoggedInUser(undefined);
    setIsAdmin(false);
    setIsGuest(false);
  }, []);

  useMemo(() => {
    setupInterceptors(logout);
  }, [logout]);

  useEffect(() => {
    const checkIfUserLoggedIn = () => {
      if (!localStorage.token || !decodeToken(localStorage.token)) {
        logout();
        return;
      }
      const decodedUser = decodeToken(localStorage.token);
      if (decodedUser) {
        login(decodedUser);
      }
    };
    checkIfUserLoggedIn();
    setFetchingUser(false);
  }, [login, logout]);

  return (
    <AuthContext.Provider
      value={{
        loggedInUser,
        login,
        logout,
        fetchingUser,
        isAdmin,
        isGuest,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);
export { AuthProvider, AuthContext, useAuth };
