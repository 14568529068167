import { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { makeStyles } from '@material-ui/core';
import TextFieldWIthIcon from 'components/Input/TextField/TextFieldWithIcon';
import PasswordInputIcon from 'components/Input/TextField/PasswordInputIcon';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { handleBlurAndTrim } from 'utils/helpers';

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  formik: FormikContextType<LoginValues>;
};

export const LoginForm = ({ formik }: LoginFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <TextFieldWIthIcon
        name="email"
        id="email-input"
        label={t('email')}
        maxLength={255}
        icon={<MailOutlineIcon />}
        className={classes.input}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={(event) => handleBlurAndTrim(event, formik)}
        error={Boolean(formik.errors.email && formik.touched.email)}
        helperText={
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : null
        }
      />
      <PasswordInputIcon
        maxLength={255}
        className={classes.input}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={(event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) =>
          handleBlurAndTrim(event, formik)
        }
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  input: {
    marginBottom: theme.spacing(6),
  },
}));
