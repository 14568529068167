import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import DialogBase from 'components/Dialog/Dialog';
import DialogActions from 'components/Dialog/DialogActions';
import TextFieldBase from 'components/Input/TextField/TextFieldBase';
import SelectBase from 'components/Input/Select/SelectBase';
import { handleBlurAndTrim } from 'utils/helpers';
import { EditValues } from 'views/UsersView/EditUser/EditUser';
import { UserRole } from 'types/authTypes';
import { useAuth } from 'context/providers/AuthProvider';

type EditUserDialogProps = {
  open: boolean;
  onClose: () => void;
  formik: FormikContextType<EditValues>;
  loading: boolean;
  email: string;
};

export const EditUserDialog = ({
  onClose,
  formik,
  loading,
  email,
  ...props
}: EditUserDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { loggedInUser } = useAuth();

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const isSelf = loggedInUser && loggedInUser.sub === email;

  return (
    <DialogBase
      title={t('users.edit')}
      className={classes.dialog}
      onClose={handleClose}
      id="edit-user-dialog"
      disabled={loading}
      {...props}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextFieldBase
          required
          name="firstName"
          id="firstName"
          maxLength={255}
          disabled={loading}
          value={formik.values.firstName}
          label={t('users.firstName')}
          className={classes.input}
          onChange={formik.handleChange}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(formik.errors.firstName && formik.touched.firstName)}
          helperText={
            formik.errors.firstName && formik.touched.firstName
              ? formik.errors.firstName
              : null
          }
        />
        <TextFieldBase
          required
          name="lastName"
          id="lastName"
          maxLength={255}
          disabled={loading}
          value={formik.values.lastName}
          label={t('users.lastName')}
          className={classes.input}
          onChange={formik.handleChange}
          onBlur={(event) => handleBlurAndTrim(event, formik)}
          error={Boolean(formik.errors.lastName && formik.touched.lastName)}
          helperText={
            formik.errors.lastName && formik.touched.lastName
              ? formik.errors.lastName
              : null
          }
        />
        <TextFieldBase
          name="email"
          id="email"
          disabled
          value={email}
          label={t('email')}
          className={classes.input}
        />
        <SelectBase
          name="role"
          id="user-role"
          items={Object.values(UserRole)}
          disabled={loading || isSelf}
          value={formik.values.role}
          label={t('users.role')}
          className={classes.margin}
          onChange={formik.handleChange}
        />
        <DialogActions
          onCancel={handleClose}
          isLoading={loading}
          isValid={formik.dirty && formik.isValid}
        />
      </form>
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '400px',
    maxWidth: '400px',
  },
  input: {
    marginBottom: theme.spacing(3),
  },
  margin: {
    marginBottom: theme.spacing(5),
  },
}));
