import {
  makeStyles,
  Checkbox,
  FormControlLabel,
  CheckboxProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { useAuth } from 'context/providers/AuthProvider';

type CheckboxWithLabelProps = CheckboxProps & {
  label: string;
  labelClassName?: string;
  checkboxClassName?: string;
};

const CheckboxWithLabel = ({
  label,
  className,
  labelClassName,
  checkboxClassName,
  color,
  ...props
}: CheckboxWithLabelProps) => {
  const classes = useStyles();
  const { isGuest } = useAuth();

  return (
    <FormControlLabel
      className={className}
      control={
        <Checkbox
          color={color || 'primary'}
          {...props}
          disabled={isGuest}
          className={checkboxClassName}
        />
      }
      label={label}
      classes={{ label: clsx(labelClassName, classes.label) }}
    />
  );
};

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: 400,
  },
}));

export default CheckboxWithLabel;
