import {
  Dialog,
  DialogContent,
  makeStyles,
  DialogProps,
  IconButton,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

type DialogBaseProps = Omit<DialogProps, 'onClose'> & {
  onClose?: () => void;
  disabled?: boolean;
  closeable?: boolean;
};

const DialogBase = ({
  children,
  onClose,
  open,
  title,
  className,
  disabled = false,
  closeable = true,
  ...props
}: DialogBaseProps) => {
  const classes = useStyles();

  return (
    <Dialog classes={{ paper: className }} open={open} maxWidth="lg" {...props}>
      <div className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        {closeable && (
          <IconButton
            className={classes.closeButton}
            onClick={onClose}
            disabled={disabled}
          >
            <CloseIcon
              className={clsx(classes.closeIcon, {
                [classes.disabled]: disabled,
              })}
            />
          </IconButton>
        )}
      </div>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(0, 4, 3, 4),
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    margin: theme.spacing(1),
  },
  title: {
    color: theme.palette.secondary.main,
    margin: theme.spacing(2, 0, 0, 4),
  },
  closeIcon: {
    width: '16px',
    height: '16px',
  },
  disabled: {
    fill: theme.palette.text.disabled,
  },
}));

export default DialogBase;
