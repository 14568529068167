import { ChangeEvent } from 'react';
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Checkbox,
  makeStyles,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import {
  VideoWallStream,
  useVideoWall,
} from 'context/providers/VideoWallProvider';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

enum AnchorOriginHorizontal {
  LEFT = 'left',
  RIGHT = 'right',
}

enum AnchorOriginVertical {
  BOTTOM = 'bottom',
  TOP = 'top',
  CENTER = 'center',
}

const MenuProps = {
  anchorOrigin: {
    vertical: AnchorOriginVertical.BOTTOM,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  transformOrigin: {
    vertical: AnchorOriginVertical.TOP,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  getContentAnchorEl: null,
};

type MultipleSelectProps = {
  items: VideoWallStream[];
  selectedItems: VideoWallStream[];
  setSelectedItems: (streams: VideoWallStream[]) => void;
  label: string;
};

export const MultipleSelectVideoWall = ({
  items,
  selectedItems,
  setSelectedItems,
  label,
}: MultipleSelectProps) => {
  const classes = useStyles();
  const { videoWallStreams } = useVideoWall();
  const { t } = useTranslation();

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    item: VideoWallStream
  ) => {
    let newItems = [...videoWallStreams];

    if (event.target.checked === true) {
      newItems.push(item);
    } else {
      newItems = newItems.filter((stream) => stream.id !== item.id);
    }
    setSelectedItems(newItems);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>
        {isEmpty(items) ? t('video-wall.no-streams') : label}
      </InputLabel>
      <Select
        multiple
        value={selectedItems}
        input={<Input />}
        renderValue={(selected) => {
          return (
            <Typography className={classes.input}>
              {(selected as VideoWallStream[])
                .map((item) => item.name)
                .join(', ')}
            </Typography>
          );
        }}
        MenuProps={{ ...MenuProps, classes: { paper: classes.selectMenu } }}
      >
        {items.map((item) => {
          const checked = selectedItems
            .map((selected) => selected.id)
            .includes(item.id);
          const disabled = !checked && selectedItems.length >= 12;

          return (
            <MenuItem
              key={item.id}
              // @ts-ignore
              value={item}
              className={classes.menuItem}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={disabled}
                    checked={checked}
                    onChange={(event) => handleChange(event, item)}
                  />
                }
                label={item.name}
                className={clsx(classes.text, { [classes.disabled]: disabled })}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 250,
    width: '100%',
  },
  text: {
    fontSize: '14px',
    width: '100%',
    '& .MuiFormControlLabel-label': {
      fontWeight: 400,
    },
  },
  disabled: {
    '& .MuiListItemText-primary': {
      color: theme.palette.grey[300],
    },
  },
  menuItem: {
    height: '36px',
  },
  selectMenu: {
    maxHeight: 360,
    border: '1px solid black',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
  },
  input: {
    whiteSpace: 'normal',
  },
}));
