import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
  PropsWithChildren,
} from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SliderSteps from 'components/Slider/SliderSteps';
import { sliderMarks, flareMarks } from 'utils/constants';
import { FireSettings } from 'types/analytics/fire/Fire';

type FireThresholdsProps<T> = {
  settings: T;
  setSettings: Dispatch<SetStateAction<T>>;
  flare?: boolean;
};

export const FireThresholds = <T extends FireSettings>({
  settings,
  setSettings,
  flare = false,
}: PropsWithChildren<FireThresholdsProps<T>>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [fireThreshold, setFireThreshold] = useState(settings.fireThreshold);
  const [smokeThreshold, setSmokeThreshold] = useState(settings.smokeThreshold);

  const commitThreshold = (
    event: ChangeEvent<unknown>,
    value: number | number[],
    name: string
  ) => {
    if (typeof value !== 'number') return;

    setSettings({ ...settings, [name]: value });
  };

  return (
    <div className={classes.settingsContainer}>
      <Typography className={classes.heading} align="center" variant="h3">
        {t('stream-settings.thresholds')}
      </Typography>
      <SliderSteps
        name="fireThreshold"
        marks={flare ? flareMarks : sliderMarks}
        value={fireThreshold}
        setValue={setFireThreshold}
        label={t('stream-settings.fire.fire-confidence')}
        onChangeCommitted={(event, value) =>
          commitThreshold(event, value, 'fireThreshold')
        }
      />
      <SliderSteps
        name="smokeThreshold"
        marks={flare ? flareMarks : sliderMarks}
        value={smokeThreshold}
        setValue={setSmokeThreshold}
        label={t('stream-settings.fire.smoke-confidence')}
        onChangeCommitted={(event, value) =>
          commitThreshold(event, value, 'smokeThreshold')
        }
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  settingsContainer: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(5),
    maxWidth: '400px',
    minWidth: '300px',
    flex: 1,
  },
}));
