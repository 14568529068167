import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetectionBox } from 'views/StreamDetails/Preview/components/DetectionBox';
import { ReactComponent as Smoke } from 'icons/smoke.svg';
import { ReactComponent as Fire } from 'icons/fire.svg';
import { ReactComponent as MonitoringZone } from 'icons/monitoringZone.svg';
import { FlareInspectionStreamData } from 'types/analytics/fire/FlareInspection';

type FireSmokeMetaDataProps = {
  metaData: FlareInspectionStreamData;
};

export const FlareInspectionMetaData = ({
  metaData,
}: FireSmokeMetaDataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.detections}>
      <DetectionBox
        label={t('stream-preview.flare.zones-total')}
        withValue
        value={metaData?.numberOfZones}
        Icon={MonitoringZone}
      />
      <DetectionBox
        label={t('stream-preview.flare.zones-with-flare')}
        withValue
        value={metaData?.numberOfZonesWithFire}
        Icon={Fire}
        isDetected={metaData?.numberOfZonesWithFire > 0}
      />
      <DetectionBox
        label={t('stream-preview.flare.zones-with-smoke')}
        withValue
        value={metaData?.numberOfZonesWithSmoke}
        Icon={Smoke}
        isDetected={metaData?.numberOfZonesWithSmoke > 0}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  detections: {
    display: 'flex',
  },
}));
