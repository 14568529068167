import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import CircularLoader from 'components/Progress/CircularLoader';

type ButtonOutlinedProps = ButtonProps & {
  loading?: boolean;
};

const ButtonOutlined = ({
  children,
  loading = false,
  startIcon,
  ...props
}: ButtonOutlinedProps) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={classes.buttonOutlined}
      startIcon={loading ? <CircularLoader size={20} /> : startIcon}
      {...props}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonOutlined: {
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    whiteSpace: 'nowrap',
  },
}));

export default ButtonOutlined;
