import { Dispatch, SetStateAction } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import useTranslation from 'i18next';

export enum AlertSeverity {
  ERROR = 'error',
  SUCCESS = 'success',
}

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

type ToastProps = AlertProps & {
  setState: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
  msg: string;
  noTranslation?: boolean;
};

const Toast = ({
  severity,
  msg,
  setState,
  noTranslation = false,
}: ToastProps) => {
  const { t } = useTranslation;

  const handleClose = () => {
    setState({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
  };

  const determineContent = () => {
    if (noTranslation) {
      return msg;
    }
    if (severity === AlertSeverity.SUCCESS) {
      return t(`feedback.${msg}`);
    }
    if (severity === AlertSeverity.ERROR) {
      return msg ? t(`feedback.${msg}`) : t('feedback.general-error');
    }
  };

  return (
    <Snackbar open={true} onClose={handleClose} id={`alert-${severity}`}>
      <Alert onClose={handleClose} severity={severity}>
        {determineContent()}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
