import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { useAuth } from '../../context/providers/AuthProvider';
import { useEffect, useState } from 'react';
import { getEvents } from '../../api/eventsApi';

import { EventResult } from '../../types/eventTypes';
import { useTranslation } from 'react-i18next';
import HeadCell from '../../components/Table/HeadCell';
import EventsTableRow from './EventsTable/EventsTableRow';

const EventView = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [events, setEvents] = useState<EventResult>();
  const [eventsLoading, setEventsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);

  function fetchEvents(page: number) {
    setEventsLoading(true);
    getEvents(page, rowsPerPage)
      .then((res) => {
        setEvents(res);
        setEventsLoading(false);
      })
      .catch(() => {
        setEventsLoading(false);
      });
  }

  useEffect(() => {
    fetchEvents(0);
  }, []);

  useEffect(() => {
    fetchEvents(0);
  }, [rowsPerPage]);

  const columnLabels = [
    'events.stream-name',
    'events.telemetry-timestamp',
    'events.analytics-type',
    'events.event-type',
    'events.duration',
  ];

  function handleChangePage(event: unknown, newPage: number) {
    fetchEvents(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
  }

  return (
    <Box className={classes.container}>
      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader id="streams-table">
          <TableHead>
            <TableRow>
              {columnLabels.map((label) => (
                <HeadCell
                  id={`${label}-head-cell`}
                  key={label}
                  className={classes.headCell}
                >
                  {t(label)}
                </HeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {events?.content.map((row) => (
              <EventsTableRow event={row} key={row.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={events?.totalElements ?? 0}
        rowsPerPage={rowsPerPage}
        page={events?.number ?? 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1800px',
    marginTop: theme.spacing(1),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(4, 0, 3, 0),
  },
  guestRow: {
    justifyContent: 'flex-end',
  },
  loader: {
    paddingTop: '30vh',
  },
  table: {
    backgroundColor: theme.palette.background.default,
    minWidth: '640px',
  },
  centeredCell: {
    textAlign: 'center',
    height: '350px',
  },
  headCell: {
    '&:nth-last-child(-n + 2)': {
      textAlign: 'center',
      padding: theme.spacing(1),
    },
  },
}));

export default EventView;
