import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  makeStyles,
  Typography,
  SelectProps,
} from '@material-ui/core';
import { AvailableAnalytics } from 'api/analyticsApi';
import clsx from 'clsx';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

enum AnchorOriginHorizontal {
  LEFT = 'left',
  RIGHT = 'right',
}

enum AnchorOriginVertical {
  BOTTOM = 'bottom',
  TOP = 'top',
  CENTER = 'center',
}

const MenuProps = {
  anchorOrigin: {
    vertical: AnchorOriginVertical.BOTTOM,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  transformOrigin: {
    vertical: AnchorOriginVertical.TOP,
    horizontal: AnchorOriginHorizontal.LEFT,
  },
  getContentAnchorEl: null,
};

type MultipleSelectProps = SelectProps & {
  items: AvailableAnalytics;
  value: string[];
  formControlClass?: string;
  selectClass?: string;
};

export const MultipleSelectAnalytics = ({
  items,
  label,
  value,
  formControlClass,
  selectClass,
  displayEmpty,
  disabled,
  ...props
}: MultipleSelectProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      className={clsx(formControlClass, classes.formControl, {
        [classes.withoutLabel]: !label,
      })}
    >
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <Select
        multiple
        input={<Input />}
        value={value}
        displayEmpty
        renderValue={(selected) => {
          const translatedSelected = (selected as AnalyticsTypes[]).map(
            (analytics) => t(`analytics.${analytics}`)
          );
          if (isEmpty(selected) && displayEmpty) {
            return (
              <Typography className={classes.input}>
                {t('streams.no-analytics')}
              </Typography>
            );
          }
          return (
            <Typography className={classes.input}>
              {(translatedSelected as string[]).join(', ')}
            </Typography>
          );
        }}
        className={clsx(selectClass, classes.customSelect)}
        MenuProps={{ ...MenuProps, classes: { paper: classes.selectMenu } }}
        {...props}
      >
        {Object.entries(items).map(([name, availableLicenses]) => {
          const checked = value.indexOf(name) > -1;

          return (
            <MenuItem
              id={name}
              key={name}
              value={name}
              className={classes.menuItem}
              disabled={(availableLicenses < 1 && !checked) || disabled}
            >
              <Checkbox checked={checked} />
              <ListItemText
                primary={`${t(`analytics.${name}`)} (${availableLicenses})`}
                className={clsx(classes.text)}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  withoutLabel: {
    marginTop: theme.spacing(0.5),
  },
  text: {
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      fontWeight: 400,
    },
  },
  disabled: {
    '& .MuiListItemText-primary': {
      color: theme.palette.grey[300],
    },
  },
  menuItem: {
    height: '36px',
  },
  selectMenu: {
    maxHeight: 360,
    border: '1px solid black',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px',
  },
  input: {
    whiteSpace: 'normal',
    fontWeight: 400,
  },
  label: {
    fontWeight: 400,
  },
  customSelect: {
    '& .MuiSelect-root': {
      minHeight: '24px',
    },
  },
}));
