import {
  Slider,
  SliderProps,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import { ValueLabelComponent } from 'components/Slider/ValueLabelComponent';
import { useAuth } from 'context/providers/AuthProvider';

type SliderContinousProps = SliderProps & {
  name: string;
  setValue: (value: number, name: string) => void;
  label: string;
  description?: string;
  valueFormatter?: (value: number) => string;
  customValueLabel?: boolean;
};

const SliderContinous = ({
  name,
  setValue,
  label,
  description,
  max,
  valueFormatter,
  customValueLabel = false,
  ...props
}: SliderContinousProps) => {
  const classes = useStyles();
  const { isGuest } = useAuth();

  const handleChange = (event: Event, eventValue: number | number[]) => {
    if (typeof eventValue === 'number') {
      setValue(eventValue, name);
    }
  };

  return (
    <Box className={classes.box}>
      <Typography
        className={clsx(classes.title, {
          [classes.titleWithDesc]: description,
        })}
      >
        {label}
      </Typography>
      <Typography variant="caption" className={classes.caption}>
        {description}
      </Typography>
      <Slider
        valueLabelDisplay="on"
        max={max}
        defaultValue={0}
        // @ts-ignore
        onChange={handleChange}
        valueLabelFormat={valueFormatter ? valueFormatter : undefined}
        ValueLabelComponent={customValueLabel ? ValueLabelComponent : undefined}
        className={classes.slider}
        disabled={isGuest}
        {...props}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  caption: {
    marginBottom: 0,
    display: 'inline-block',
  },
  title: {
    margin: 0,
  },
  titleWithDesc: {
    marginBottom: 0,
  },
  slider: {
    padding: theme.spacing(6, 0, 0, 0),
    '& .MuiSlider-track': {
      color: theme.palette.secondary.main,
    },
    '& .MuiSlider-rail': {
      color: theme.palette.grey[600],
    },
    '& .MuiSlider-valueLabel': {
      left: 'calc(-50% - 2px)',
      '& > span': {
        width: '28px',
        height: '28px',
      },
    },
  },
  box: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
}));

export default SliderContinous;
