import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SliderSteps from 'components/Slider/SliderSteps';
import { sliderMarks } from 'utils/constants';
import { IntruderDetectionSettings } from 'types/analytics/IntruderDetection';

type IntruderDetectionThresholdsProps = {
  settings: IntruderDetectionSettings;
  setSettings: Dispatch<SetStateAction<IntruderDetectionSettings>>;
};

export const IntruderDetectionThresholds = ({
  settings,
  setSettings,
}: IntruderDetectionThresholdsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [intruderDetectionThreshold, setIntruderDetectionThreshold] = useState(
    settings.intruderDetectionThreshold
  );

  const commitThreshold = (
    event: ChangeEvent<unknown>,
    value: number | number[],
    name: string
  ) => {
    if (typeof value !== 'number') return;

    setSettings({ ...settings, [name]: value });
  };

  return (
    <div className={classes.settingsContainer}>
      <div>
        <Typography className={classes.heading} align="center" variant="h3">
          {t('stream-settings.thresholds')}
        </Typography>
        <SliderSteps
          name="intruderDetectionThreshold"
          marks={sliderMarks}
          value={intruderDetectionThreshold}
          setValue={setIntruderDetectionThreshold}
          label={t('stream-settings.intruder.detection-threshold')}
          onChangeCommitted={(event, value) =>
            commitThreshold(event, value, 'intruderDetectionThreshold')
          }
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  settingsContainer: {
    marginTop: theme.spacing(6),
    marginLeft: theme.spacing(5),
    maxWidth: '400px',
    minWidth: '300px',
    flex: 1,
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));
