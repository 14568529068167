import clsx from 'clsx';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const CancelButton = ({
  children,
  className,
  onClick,
  disabled,
  ...props
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      color="primary"
      className={clsx(className, classes.button)}
      classes={{
        startIcon: classes.startIcon,
      }}
      onClick={onClick}
      startIcon={
        <CloseIcon
          className={clsx(classes.iconComponent, {
            [classes.disabled]: disabled,
          })}
        />
      }
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: theme.shadows[1],
    fontSize: '14px',
    fontWeight: 500,
    height: '44px',
    border: 'none',
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.secondary.hover,
    },
  },
  startIcon: {
    padding: theme.spacing(0, 0.5, 0, 0.5),
    color: theme.palette.secondary.main,
  },
  iconComponent: {
    width: '16px',
    height: '16px',
  },
  disabled: {
    fill: theme.palette.text.disabled,
  },
}));

export default CancelButton;
