import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from 'components/Button/IconButton';
import ConfirmDialog from 'components/Dialog/ConfirmDialog';
import { deleteStream } from 'api/streamApi';
import { AlertSeverity } from 'components/Alert/Toast';
import { Stream } from 'types/streamTypes';
import { useVideoWall } from 'context/providers/VideoWallProvider';

type DeleteStreamProps = {
  stream: Stream;
  fetchStreams: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export const DeleteStream = ({
  stream,
  fetchStreams,
  setFeedback,
}: DeleteStreamProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { videoWallStreams, saveVideoWallStreams } = useVideoWall();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = (streamId: string) => {
    setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
    setLoading(true);
    deleteStream(streamId)
      .then(() => {
        const videoWallIds = videoWallStreams.map(
          (videoWallStream) => videoWallStream.id
        );
        if (videoWallIds.includes(streamId)) {
          const filtered = videoWallStreams.filter(
            (videoWallStream) => videoWallStream.id !== streamId
          );
          saveVideoWallStreams(filtered);
        }
        setLoading(false);
        fetchStreams();
        setFeedback({
          open: true,
          status: AlertSeverity.SUCCESS,
          msg: 'stream-delete-success',
        });
      })
      .catch((error) => {
        setLoading(false);
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: error.response.data.description
            ? error.response.data.description
            : 'general-error',
        });
        setDeleteDialogOpen(false);
      });
  };

  return (
    <>
      <Tooltip title={t('streams.delete')}>
        <IconButton
          className={classes.actionsButton}
          onClick={() => setDeleteDialogOpen(true)}
          id={`${stream.id}-delete-button`}
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
      <ConfirmDialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        message={
          <Trans
            i18nKey="streams.stream-delete-confirm"
            components={{ italic: <i /> }}
            values={{ streamName: stream.name }}
          />
        }
        confirmAction={() => handleDelete(stream.id)}
        loading={loading}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsButton: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  },
}));
