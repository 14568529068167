import { Dispatch, SetStateAction } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Polygon } from 'components/KonvaShapes/Polygon/PolygonZones';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/providers/AuthProvider';
import { ZoneModes } from 'components/KonvaShapes/types';

type ToggleDrawingModeProps = {
  mode: string;
  setMode: Dispatch<SetStateAction<ZoneModes>>;
  setSelectedZones: Dispatch<SetStateAction<number[]>>;
  setZoneInProgress?: Dispatch<SetStateAction<Polygon>>;
};

export const ToggleDrawingMode = ({
  mode,
  setMode,
  setSelectedZones,
  setZoneInProgress,
}: ToggleDrawingModeProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isGuest } = useAuth();

  const handleDrawingMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string | null
  ) => {
    if (newMode !== null) {
      setMode(newMode as ZoneModes);
      setSelectedZones([]);
      if (setZoneInProgress) {
        setZoneInProgress({ points: [], isComplete: false });
      }
    }
  };

  return (
    <ToggleButtonGroup
      value={mode}
      exclusive
      onChange={handleDrawingMode}
      size="small"
      color="primary"
    >
      <ToggleButton
        className={classes.toggleButton}
        value={ZoneModes.DRAW}
        disableRipple
        disabled={isGuest}
        id="draw-button"
      >
        {t('stream-settings.draw')}
      </ToggleButton>
      <ToggleButton
        className={classes.toggleButton}
        value={ZoneModes.SELECT}
        disableRipple
        disabled={isGuest}
        id="select-button"
      >
        {t('stream-settings.select')}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    fontSize: '12px',
    fontWeight: 500,
    border: `1px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1, 0.5, 1),
    marginLeft: theme.spacing(1),

    '&.Mui-selected': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&.Mui-disabled': {
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[400],
      },
    },
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.grey[400]}`,
    },
  },
}));
