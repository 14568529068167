import { useState, Dispatch, SetStateAction, useEffect } from 'react';
import {
  makeStyles,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import HeadCell from 'components/Table/HeadCell';
import TableBodyRow from 'components/Table/TableBodyRow';
import DataCell from 'components/Table/DataCell';
import TablePagination from 'components/Table/TablePagination';
import StreamsTableRow from 'views/StreamsView/StreamsTable/StreamsTableRow';
import { Stream } from 'types/streamTypes';
import { AlertSeverity } from 'components/Alert/Toast';
import { useAuth } from 'context/providers/AuthProvider';

type StreamsTableProps = {
  streams: Stream[];
  fetchStreams: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export const StreamsTable = ({
  streams,
  fetchStreams,
  setFeedback,
}: StreamsTableProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const { isAdmin } = useAuth();

  const rowsPerPage = 10;

  const adminLabels = [
    'streams.stream-name',
    'streams.stream-url',
    'streams.stream-status',
    'streams.stream-zone',
    'streams.add-analytics',
    'actions',
  ];

  const columnLabels = isAdmin
    ? adminLabels
    : adminLabels.filter((label) => label !== 'streams.add-analytics');

  const currPage = streams.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    if (isEmpty(currPage) && page > 0) {
      setPage(page - 1);
    }
    // eslint-disable-next-line
  }, [currPage]);

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} stickyHeader id="streams-table">
        <TableHead>
          <TableRow>
            {columnLabels.map((label) => (
              <HeadCell
                id={`${label}-head-cell`}
                key={label}
                className={classes.headCell}
              >
                {t(label)}
              </HeadCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {streams && !isEmpty(streams) ? (
            currPage.map((stream: Stream) => {
              return (
                <StreamsTableRow
                  key={stream.id}
                  stream={stream}
                  fetchStreams={fetchStreams}
                  setFeedback={setFeedback}
                />
              );
            })
          ) : (
            <TableBodyRow>
              <DataCell
                id="info-cell"
                colSpan={isAdmin ? 6 : 5}
                className={classes.centeredCell}
              >
                {streams && isEmpty(streams) && t('no-results')}
              </DataCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        totalRecords={streams?.length || 0}
      />
    </TableContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    backgroundColor: theme.palette.background.default,
    minWidth: '640px',
  },
  centeredCell: {
    textAlign: 'center',
    height: '350px',
  },
  container: {
    marginTop: theme.spacing(1),
  },
  headCell: {
    '&:nth-last-child(-n + 2)': {
      textAlign: 'center',
      padding: theme.spacing(1),
    },
  },
}));
