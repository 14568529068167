import { useEffect, useCallback } from 'react';

export const useKeyDown = (handleRemoveSelected: () => void) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        handleRemoveSelected();
      }
    },
    [handleRemoveSelected]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};
