import { Dispatch, SetStateAction } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import DialogBase from 'components/Dialog/Dialog';
import { ResetValues } from 'views/UsersView/ResetPassword/ResetPassword';
import { ResetPasswordForm } from 'views/UsersView/ResetPassword/ResetPasswordForm';
import { User } from 'types/userTypes';

type ResetPasswordDialogProps = {
  open: boolean;
  onClose: () => void;
  formik: FormikContextType<ResetValues>;
  loading: boolean;
  setPassword: Dispatch<SetStateAction<string>>;
  user: User;
};

export const ResetPasswordDialog = ({
  onClose,
  formik,
  loading,
  setPassword,
  user,
  ...props
}: ResetPasswordDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <DialogBase
      title={t('users.reset-password')}
      className={classes.dialog}
      onClose={handleClose}
      id="reset-password-dialog"
      disabled={loading}
      {...props}
    >
      <Typography variant="body2">{`${t('users.reset-pass-for')} ${
        user.firstName
      } ${user.lastName}`}</Typography>
      <ResetPasswordForm
        formik={formik}
        loading={loading}
        handleClose={handleClose}
        setPassword={setPassword}
        userEmail={user.email}
      />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    width: '400px',
    maxWidth: '400px',
  },
}));
