import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldProps } from '@material-ui/core';
import TextFieldBase from 'components/Input/TextField/TextFieldBase';
import { ToggleVisibility } from 'components/Input/TextField/ToggleVisibility';

type PasswordInputProps = TextFieldProps & {
  maxLength?: number;
};

const PasswordInput = ({
  disabled,
  maxLength,
  ...props
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <TextFieldBase
      id="password-input"
      type={showPassword ? 'text' : 'password'}
      name="password"
      maxLength={maxLength}
      label={t('password')}
      disabled={disabled}
      InputProps={{
        endAdornment: disabled ? null : (
          <ToggleVisibility
            onToggle={handleShowPassword}
            showPassword={showPassword}
          />
        ),
      }}
      {...props}
    />
  );
};

export default PasswordInput;
