import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { TrackingItem } from 'components/TrackingItem/TrackingItem';
import { ReactComponent as Vest } from 'icons/vest.svg';
import { ReactComponent as Helmet } from 'icons/helmet.svg';
import { Typography, makeStyles } from '@material-ui/core';
import { PPESettings } from 'types/analytics/PPE';

type TrackingItemsProps = {
  settings: PPESettings;
  setSettings: Dispatch<SetStateAction<PPESettings>>;
};

export const TrackingItems = ({
  settings,
  setSettings,
}: TrackingItemsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const setActive = (id: string, active: boolean) => {
    const canRemoveTracking = Object.values(settings.trackedItems).every(
      (value) => value === true
    );

    if (active === false && !canRemoveTracking) return;

    setSettings({
      ...settings,
      trackedItems: { ...settings.trackedItems, [id]: active },
    });
  };

  const items = [
    {
      id: 'isVestTracked',
      label: 'stream-settings.ppe.vest',
      active: settings.trackedItems.isVestTracked,
      setActive: setActive,
      icon: <Vest />,
    },
    {
      id: 'isHardHatTracked',
      label: 'stream-settings.ppe.helmet',
      active: settings.trackedItems.isHardHatTracked,
      setActive: setActive,
      icon: <Helmet />,
    },
  ];

  return (
    <>
      <Typography align="center">
        {t('stream-settings.ppe.choose-tracking-items')}
      </Typography>
      <div className={classes.trackingItemsContainer}>
        {items.map((item) => (
          <TrackingItem key={item.id} item={item} />
        ))}
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  trackingItemsContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
