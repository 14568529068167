import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';
import PreviewLoader from 'components/Progress/PreviewLoader';

type NoPreviewProps = {
  loading: boolean;
};

export const NoPreview = ({ loading }: NoPreviewProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {loading ? (
        <PreviewLoader />
      ) : (
        <Typography className={classes.noPreview}>
          {t('stream-preview.no-preview')}
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    aspectRatio: '16/9',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noPreview: {
    color: theme.palette.common.white,
    fontSize: 30,
    fontWeight: 300,

    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
}));
