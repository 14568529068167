import {
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { getAlarmingSettings, updateAlarmingSettings } from 'api/alarmsApi';
import { AlertSeverity } from 'components/Alert/Toast';
import SubmitButton from 'components/Button/SubmitButton';
import SliderSteps from 'components/Slider/SliderSteps';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { sliderMarks } from 'views/SettingsView/AlarmingSettings/constants';
import { blockNonNumericalChars } from 'utils/helpers';
import { SettingsWrapper } from '../SettingsWrapper';

type AlarmingSettingsProps = {
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export const AlarmingSettings = ({ setFeedback }: AlarmingSettingsProps) => {
  const { t } = useTranslation();
  const [videoPadding, setVideoPadding] = useState(1);
  const [dataRetention, setDataRetention] = useState<number | string>(1);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    getAlarmingSettings().then((res) => {
      setVideoPadding(res.videoCapturePaddingTimeSeconds);
      setDataRetention(res.dataRetentionTimeDays);
    });
  }, []);

  const handleDataRetentionChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setDataRetention('');
    } else {
      setDataRetention(parseInt(event.target.value));
    }
  };

  const handleSave = () => {
    if (typeof dataRetention !== 'number') return;
    setLoading(true);

    updateAlarmingSettings({
      videoCapturePaddingTimeSeconds: videoPadding,
      dataRetentionTimeDays: dataRetention,
    })
      .then(() => {
        setLoading(false);
        setFeedback({
          open: true,
          status: AlertSeverity.SUCCESS,
          msg: 'settings-change-success',
        });
      })
      .catch(() => {
        setLoading(false);
        setFeedback({
          open: true,
          status: AlertSeverity.ERROR,
          msg: 'general-error',
        });
      });
  };

  return (
    <SettingsWrapper title="alarming-settings">
      <div className={classes.settingsBox}>
        <SliderSteps
          id="video-capture-time-slider"
          marks={sliderMarks}
          name="videoCapturePaddingTimeSeconds"
          value={videoPadding}
          setValue={setVideoPadding}
          step={1}
          label={t('settings.alarming.video-padding-time')}
          description={t('settings.alarming.video-padding-time-desc')}
          className={classes.paddingSlider}
          maxWidth="300px"
          minHeight="130px"
        />
        <div className={classes.dataRetention}>
          <div>
            <InputLabel
              className={classes.inputLabel}
              id="data-retention-time-label"
            >
              {t('settings.alarming.data-retention-time')}
            </InputLabel>
            <Typography variant="caption" className={classes.inputCaption}>
              {t('settings.alarming.data-retention-time-desc')}
            </Typography>
          </div>
          <TextField
            id="data-retention-time-input"
            value={dataRetention}
            onChange={handleDataRetentionChange}
            onKeyDown={blockNonNumericalChars}
            onBlur={() => {
              if (!dataRetention) {
                setDataRetention(1);
              }
            }}
            type="number"
            inputProps={{ min: 1 }}
            className={classes.dataRetentionInput}
          />
        </div>
        <SubmitButton
          id="save-alarming-settings"
          className={classes.save}
          onClick={handleSave}
          isLoading={loading}
          disabled={loading}
        >
          {t('save')}
        </SubmitButton>
      </div>
    </SettingsWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  paddingSlider: {
    minWidth: '200px',
    marginRight: theme.spacing(10),
  },
  settingsBox: {
    display: 'flex',
  },
  dataRetentionInput: {
    width: '100px',
  },
  save: {
    alignSelf: 'end',
    marginLeft: 'auto',
    minWidth: '97px',
  },
  inputLabel: {
    margin: theme.spacing(0.5, 2, 0.5, 0),
    color: theme.palette.text.primary,
    minWidth: '130px',
    lineHeight: '18px',
  },
  dataRetention: {
    marginRight: theme.spacing(3),
    maxWidth: '500px',
  },
  inputCaption: {
    display: 'inline-block',
  },
}));
