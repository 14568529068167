import { Zone, DetectionArea } from 'types/analyticsTypes';

export type DetectionAreaState = {
  detectionArea: DetectionArea;
  setDetectionArea: (roi: DetectionArea) => void;
};

export type ActiveZonesState = {
  activeZones: Zone[];
  setActiveZones: (exclusionZones: Zone[]) => void;
};

export enum ZoneModes {
  DRAW = 'DRAW',
  SELECT = 'SELECT',
}
