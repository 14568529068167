import { MutableRefObject, Dispatch, SetStateAction, useState } from 'react';
import { Line, Circle, Group } from 'react-konva';
import Konva from 'konva';
import { Polygon } from 'components/KonvaShapes/Polygon/PolygonZones';
import { minMax } from 'components/KonvaShapes/Polygon/utils';
import {
  fillColor,
  layerOffset,
  selectedFillColor,
  stagePadding,
  strokeColor,
} from 'components/KonvaShapes/constants';
import { vertexRadius } from 'components/KonvaShapes/Polygon/constants';
import { useAuth } from 'context/providers/AuthProvider';
import { ZoneModes } from 'components/KonvaShapes/types';

type PolygonAnnotationProps = {
  stageRef: MutableRefObject<Konva.Stage | null>;
  name: string;
  scaleX: number;
  scaleY: number;
  polygonData: Polygon;
  setPolygonData?: Dispatch<SetStateAction<Polygon>>;
  intersectingSegments?: number[][];
  setIntersectingSegments?: Dispatch<SetStateAction<number[][]>>;
  selected?: boolean;
  mode: string;
  polygonIndex?: number;
  setAllPolygons: Dispatch<SetStateAction<Polygon[]>>;
  allPolygons: Polygon[];
};

const PolygonAnnotation = (props: PolygonAnnotationProps) => {
  const {
    stageRef,
    name,
    scaleX,
    scaleY,
    polygonData,
    selected,
    mode,
    polygonIndex,
    setAllPolygons,
    allPolygons,
  } = props;

  const { isGuest } = useAuth();
  const [minMaxX, setMinMaxX] = useState([0, 0]);
  const [minMaxY, setMinMaxY] = useState([0, 0]);

  const handleMouseOverStartPoint = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (polygonData.isComplete || polygonData.points.length < 3) return;
    e.target.scale({ x: 1.5 / scaleX, y: 1.5 / scaleY });
  };

  const handleMouseOutStartPoint = (e: Konva.KonvaEventObject<MouseEvent>) => {
    e.target.scale({ x: 1 / scaleX, y: 1 / scaleY });
  };

  const handleMouseEnterPolygon = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (mode === ZoneModes.DRAW) return;
    const container = e.target.getStage()!.container();
    container.style.cursor = 'pointer';
  };

  const handleMouseOutPolygon = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (mode === ZoneModes.DRAW) return;
    const container = e.target.getStage()!.container();
    container.style.cursor = 'default';
  };

  const flattenedPoints = polygonData.points.reduce(
    (a: number[], b: number[]) => a.concat(b),
    []
  );

  const handleGroupMouseOver = () => {
    if (mode === ZoneModes.DRAW) return;
    if (!polygonData.isComplete || !stageRef.current) {
      return;
    }
    const container = stageRef.current.container();
    container.style.cursor = 'move';
  };

  const handleGroupMouseOut = () => {
    if (mode === ZoneModes.DRAW) return;
    if (!stageRef.current) {
      return;
    }
    const container = stageRef.current.container();
    container.style.cursor = 'default';
  };

  const handleGroupDragStart = () => {
    if (!stageRef.current) {
      return;
    }
    const arrX = polygonData.points.map((p) => p[0]);
    const arrY = polygonData.points.map((p) => p[1]);
    setMinMaxX(minMax(arrX));
    setMinMaxY(minMax(arrY));
    const container = stageRef.current.container();
    container.style.cursor = 'move';
  };

  const handleGroupDragEnd = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (
      !stageRef.current ||
      name === 'polygonInProgress' ||
      polygonIndex === undefined
    ) {
      return;
    }
    const result: number[][] = [];
    const copyPoints = [...polygonData.points];
    copyPoints.map((point) =>
      result.push([point[0] + e.target.x(), point[1] + e.target.y()])
    );
    const polygonsCopy = [...allPolygons];
    polygonsCopy.splice(polygonIndex, 1, { points: result, isComplete: true });
    e.target.position({ x: 0, y: 0 });
    setAllPolygons(polygonsCopy);
    const container = stageRef.current.container();
    container.style.cursor = 'crosshair';
  };

  const groupDragBound = ({ x, y }: Konva.Vector2d) => {
    if (!stageRef.current) return { x, y };

    const stageWidth = stageRef.current.width() - stagePadding;
    const stageHeight = stageRef.current.height() - stagePadding;

    if (minMaxY[0] * scaleX + y < 0) y = -1 * minMaxY[0] * scaleY;
    if (minMaxX[0] * scaleX + x < 0) x = -1 * minMaxX[0] * scaleX;
    if (minMaxY[1] * scaleY + y > stageHeight)
      y = stageHeight - minMaxY[1] * scaleY;
    if (minMaxX[1] * scaleY + x > stageWidth)
      x = stageWidth - minMaxX[1] * scaleX;

    x = x + layerOffset;
    y = y + layerOffset;

    return { x, y };
  };

  return (
    <>
      <Group
        name={name}
        draggable={
          polygonData.isComplete && mode === ZoneModes.DRAW && !isGuest
        }
        onMouseOver={handleGroupMouseOver}
        onMouseOut={handleGroupMouseOut}
        onDragStart={handleGroupDragStart}
        onDragEnd={handleGroupDragEnd}
        dragBoundFunc={groupDragBound}
      >
        <Line
          points={flattenedPoints}
          stroke={strokeColor}
          strokeWidth={3}
          strokeScaleEnabled={false}
          closed={polygonData.isComplete}
          fill={selected ? selectedFillColor : fillColor}
          hitStrokeWidth={12}
          onMouseEnter={
            polygonData.isComplete ? handleMouseEnterPolygon : undefined
          }
          onMouseLeave={
            polygonData.isComplete ? handleMouseOutPolygon : undefined
          }
        />
        {polygonData.points.map((point: number[], index: number) => {
          const x = point[0];
          const y = point[1];
          const startPointAttr =
            index === 0
              ? {
                  onMouseOver: handleMouseOverStartPoint,
                  onMouseOut: handleMouseOutStartPoint,
                }
              : null;
          return (
            <Circle
              key={index}
              x={x}
              y={y}
              scaleX={1 / scaleX}
              scaleY={1 / scaleY}
              radius={vertexRadius}
              hitStrokeWidth={12}
              fill="white"
              stroke="black"
              strokeWidth={2}
              {...startPointAttr}
            />
          );
        })}
      </Group>
    </>
  );
};

export default PolygonAnnotation;
