import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { getAvailableAnalytics, AvailableAnalytics } from 'api/analyticsApi';

export enum AnalyticsTypes {
  FireSmokeTunnel = 'FireSmokeTunnel',
  FireSmoke = 'FireSmoke',
  FallDetection = 'FallDetection',
  PPE = 'PPE',
  FlareInspection = 'FlareInspection',
  IntruderDetection = 'IntruderDetection',
}

export type AnalyticsContextValue = {
  availableAnalytics: AvailableAnalytics;
  loading: boolean;
  fetchAvailableAnalytics: () => void;
};

export type AnalyticsProviderProps = {
  children?: ReactNode;
};

const defaultValue: AnalyticsContextValue = {
  availableAnalytics: {},
  loading: true,
  fetchAvailableAnalytics: () => {},
};

const AnalyticsContext = createContext(defaultValue);

const AnalyticsProvider: FC<AnalyticsProviderProps> = (
  props: AnalyticsProviderProps
) => {
  const [availableAnalytics, setAvailableAnalytics] =
    useState<AvailableAnalytics>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAvailableAnalytics();
  }, []);

  const fetchAvailableAnalytics = () => {
    getAvailableAnalytics()
      .then((res) => {
        setAvailableAnalytics(res.availableAnalyticsLicenses);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        availableAnalytics,
        loading,
        fetchAvailableAnalytics,
      }}
      {...props}
    />
  );
};

const useAnalytics = () => useContext(AnalyticsContext);
export { AnalyticsProvider, AnalyticsContext, useAnalytics };
