import { makeStyles } from '@material-ui/core';
import { ReactNode } from 'react';

type ActionsBarProps = {
  children: ReactNode;
};

export const ActionsBar = ({ children }: ActionsBarProps) => {
  const classes = useStyles();

  return <div className={classes.actionsBar}>{children}</div>;
};

const useStyles = makeStyles((theme) => ({
  actionsBar: {
    position: 'fixed',
    zIndex: 1000,
    bottom: 0,
    backgroundColor: theme.palette.secondary.light,
    width: 'calc(100% - 247px)',
    padding: theme.spacing(1.5),
    borderRadius: '6px 6px 0 0',
    marginLeft: '-40px',
    display: 'flex',
    justifyContent: 'flex-end',
    minWidth: '500px',
  },
}));
