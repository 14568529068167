import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SliderContinous from 'components/Slider/SliderContinuous';
import { EventTimers } from 'types/analyticsTypes';
import CheckboxWithLabel from 'components/Checkbox/CheckboxWithLabel';
import clsx from 'clsx';

type AlarmingModeSettingsProps = {
  eventTimers: EventTimers;
  setEventTimers: Dispatch<SetStateAction<EventTimers>>;
  className?: string;
};

export const AlarmingModeSettings = ({
  eventTimers,
  setEventTimers,
  className,
}: AlarmingModeSettingsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [delay, setDelay] = useState(eventTimers.delayTimeSeconds);
  const [cooldown, setCooldown] = useState(
    eventTimers.alarmingSettings.nextEventCooldownSeconds
  );

  const setAlarmingSettings = (value: number, name: string) => {
    setEventTimers({
      ...eventTimers,
      alarmingSettings: {
        ...eventTimers.alarmingSettings,
        [name]: value,
      },
    });
  };

  const setAlarmingSettingsEnabled = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setEventTimers({
      ...eventTimers,
      alarmingSettings: {
        ...eventTimers.alarmingSettings,
        isEnabled: checked,
      },
    });
  };

  const commitDelayChange = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[]
  ) => {
    if (typeof value !== 'number') return;

    setEventTimers({ ...eventTimers, delayTimeSeconds: value });
  };

  const commitCooldownChange = (
    event: ChangeEvent<unknown> | undefined,
    value: number | number[]
  ) => {
    if (typeof value !== 'number') return;

    setAlarmingSettings(value, 'nextEventCooldownSeconds');
  };

  return (
    <div className={clsx(className, classes.alarmingSettingsContainer)}>
      <Typography className={classes.heading} align="center" variant="h3">
        {t('stream-settings.alarming-mode-settings')}
      </Typography>
      <Typography variant="body2" className={classes.margin}>
        {t('stream-settings.alarming-mode-description')}
      </Typography>
      <CheckboxWithLabel
        label={t('stream-settings.enable-alarming')}
        checked={eventTimers.alarmingSettings.isEnabled}
        onChange={setAlarmingSettingsEnabled}
        className={classes.margin}
        labelClassName={classes.checkboxLabel}
        id="enable-alarming-checkbox"
      />
      <SliderContinous
        name="delayTimeSeconds"
        value={delay}
        max={30}
        setValue={setDelay}
        label={t('stream-settings.delay')}
        customValueLabel
        description={t('stream-settings.delay-description')}
        onChangeCommitted={commitDelayChange}
        id="delay-slider-alarming"
      />
      <SliderContinous
        name="nextEventCooldownSeconds"
        value={cooldown}
        max={30}
        setValue={setCooldown}
        label={t('stream-settings.cooldown')}
        customValueLabel
        description={t('stream-settings.cooldown-description')}
        onChangeCommitted={commitCooldownChange}
        id="cooldown-slider-alarming"
      />
    </div>
  );
};
const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2),
  },
  checkboxLabel: {
    fontWeight: 500,
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  alarmingSettingsContainer: {
    maxWidth: '450px',
    minWidth: '300px',
    flexGrow: 1,
  },
}));
