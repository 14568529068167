import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

export enum ColorEnum {
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  ORANGE = 'ORANGE',
  RED = 'RED',
  TRANSPARENT = 'TRANSPARENT',
}

type ColorIndicatorProps = {
  color: ColorEnum;
  className?: string;
};

export const ColorIndicator = ({ color, className }: ColorIndicatorProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.colorIndicator, classes[color], className)} />
  );
};

ColorIndicator.defaultProps = {
  className: undefined,
};

const useStyles = makeStyles((theme) => ({
  colorIndicator: {
    width: '12px',
    height: '12px',
    minWidth: '12px',
    minHeight: '12px',
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  GREEN: {
    backgroundColor: theme.palette.success.main,
  },
  YELLOW: {
    backgroundColor: theme.palette.warning.main,
  },
  ORANGE: {
    backgroundColor: theme.palette.warning.main,
  },
  RED: {
    backgroundColor: theme.palette.error.main,
  },
  TRANSPARENT: {
    backgroundColor: 'transparent',
  },
}));
