import * as Yup from 'yup';
import i18n from 'translations/i18n';
import { passReg } from 'utils/constants';

export const passChangeValidationSchema = (password: string) => {
  const validatePasswordMatch = (passwordReapeat: string | undefined) => {
    return passwordReapeat === password;
  };

  return Yup.object().shape({
    newPassword: Yup.string()
      .required(i18n.t('validation.required-password'))
      .matches(passReg, i18n.t('validation.invalid-password')),
    passwordRepeat: Yup.string()
      .required(i18n.t('validation.required-password'))
      .test(
        'Check if password matches',
        i18n.t('validation.invalid-passwordRepeat'),
        validatePasswordMatch
      ),
  });
};
