import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { CameraZone } from 'types/settingsTypes';

export enum StreamStatus {
  CONNECTING = 'CONNECTING',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTED = 'CONNECTED',
}

export type Stream = {
  enabled: boolean;
  id: string;
  vpaStreamId: string;
  name: string;
  url: string;
  username: string;
  passwordExists: boolean;
  status: StreamStatus;
  enabledAnalytics: AnalyticsTypes[];
  assignedTags: CameraZone[];
};

export type CreateStreamDTO = {
  name: string;
  url: string;
  username: string;
  password: string;
  selectedAnalytics: AnalyticsTypes[];
  assignedTagsIds: string | string[];
};

export type EditStreamDTO = {
  name: string;
  url: string;
  username: string;
  password: string | null;
  newTagsIds: string[];
  removedTagsIds: string[];
};
