import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DetectionBox } from 'views/StreamDetails/Preview/components/DetectionBox';
import { ReactComponent as Vest } from 'icons/vest.svg';
import { ReactComponent as Helmet } from 'icons/helmet.svg';
import { PPESettings, PPEStreamData } from 'types/analytics/PPE';

type PPEMetadataProps = {
  metaData: PPEStreamData | undefined;
  settings: PPESettings | undefined;
};

export const PPEMetadata = ({ metaData, settings }: PPEMetadataProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const missingVests = metaData?.numberOfMissingVests;
  const missingHelmets = metaData?.numberOfMissingHelmets;

  const isHelmetTracked = settings?.trackedItems?.isHardHatTracked;
  const isVestTracked = settings?.trackedItems?.isVestTracked;

  return (
    <div className={classes.detections}>
      {isVestTracked && (
        <DetectionBox
          label={t('stream-preview.ppe.missing-vests')}
          Icon={Vest}
          withValue
          value={missingVests}
          isDetected={Boolean(missingVests)}
        />
      )}
      {isHelmetTracked && (
        <DetectionBox
          label={t('stream-preview.ppe.missing-helmets')}
          Icon={Helmet}
          withValue
          value={missingHelmets}
          isDetected={Boolean(missingHelmets)}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  detections: {
    display: 'flex',
  },
}));
