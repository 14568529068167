import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import DialogBase from 'components/Dialog/Dialog';
import { EditValues } from 'views/StreamsView/EditStream/EditStream';
import { EditStreamForm } from 'views/StreamsView/EditStream/EditStreamForm';

type EditStreamDialogProps = {
  open: boolean;
  onClose: () => void;
  formik: FormikContextType<EditValues>;
  loading: boolean;
};

export const EditStreamDialog = ({
  onClose,
  formik,
  loading,
  ...props
}: EditStreamDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <DialogBase
      title={t('streams.edit')}
      className={classes.dialog}
      onClose={handleClose}
      id="edit-stream-dialog"
      disabled={loading}
      {...props}
    >
      <EditStreamForm
        formik={formik}
        loading={loading}
        handleClose={handleClose}
      />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    width: '400px',
    maxWidth: '400px',
  },
}));
