export const vertexRadius = 5;

export const defaultInclusionZone = {
  points: [
    {
      x: 0,
      y: 0,
    },
    {
      x: 1,
      y: 0,
    },
    {
      x: 1,
      y: 1,
    },
    {
      x: 0,
      y: 1,
    },
  ],
};
