import { ReactElement, useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { getStream } from 'api/streamApi';

const StreamDetailsBreadcrumb = ({ name }: { name: string }) => (
  <span>{name}</span>
);

export const Breadcrumbs = () => {
  const { streamId } = useParams<{ streamId: string }>();
  const classes = useStyles();
  const { t } = useTranslation();
  const [streamName, setStreamName] = useState<string>('');

  useEffect(() => {
    setStreamName('');
    if (!streamId) return;
    getStream(streamId).then((res) => {
      const capitalized = res.name.charAt(0).toUpperCase() + res.name.slice(1);
      setStreamName(capitalized);
    });
  }, [streamId]);

  const routes = [
    { path: '/streams', breadcrumb: 'Streams' },
    {
      path: '/streams/:streamId',
      breadcrumb: StreamDetailsBreadcrumb,
      props: { name: streamName },
    },
    {
      path: '/streams/video-wall',
      breadcrumb: 'Video Wall',
    },
    {
      path: '/users',
      breadcrumb: 'Users',
    },
  ];

  // @ts-ignore
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <div className={classes.breadcrumbs}>
      {breadcrumbs.map(({ match, breadcrumb }, index) => {
        if (!breadcrumb) return null;

        const breadcrumbNode = breadcrumb as ReactElement;
        const name = breadcrumbNode.props.children || breadcrumbNode.props.name;

        return (
          <span key={match.pathname}>
            {index + 1 === breadcrumbs.length ? (
              <>{t(`${name}`)}</>
            ) : (
              <span key={match.pathname}>
                <NavLink to={match.pathname} className={classes.link}>
                  {breadcrumb ? <>{t(`${name}`)}</> : null}
                </NavLink>
                {' / '}
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(1),
  },
  link: {
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
  },
}));
