import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldProps } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import TextFieldWithIcon from 'components/Input/TextField/TextFieldWithIcon';
import { ToggleVisibility } from 'components/Input/TextField/ToggleVisibility';

type PasswordInputIconProps = TextFieldProps & {
  label?: string;
  name?: string;
  maxLength?: number;
};

const PasswordInputIcon = ({
  name,
  label,
  ...props
}: PasswordInputIconProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <TextFieldWithIcon
      id="password-input"
      icon={<LockOutlinedIcon />}
      type={showPassword ? 'text' : 'password'}
      name={name || 'password'}
      label={label || t('password')}
      InputProps={{
        endAdornment: (
          <ToggleVisibility
            onToggle={handleShowPassword}
            showPassword={showPassword}
          />
        ),
      }}
      {...props}
    />
  );
};

export default PasswordInputIcon;
