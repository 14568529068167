import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';

type CircularLoaderProps = {
  className?: string;
  id?: string;
  size?: number;
};

const CircularLoader = ({ className, id, size }: CircularLoaderProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)}>
      <CircularProgress id={id} color="inherit" size={size} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default CircularLoader;
