import Konva from 'konva';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Stage, Layer } from 'react-konva';
import { isEmpty } from 'lodash';
import {
  checkIntersections,
  convertToApiFormat,
  convertToKonvaShape,
  generateNewAnnotation,
  isMouseOverShape,
} from 'components/KonvaShapes/FreeFormRectangle/utils';
import { FlareInspectionSettings } from 'types/analytics/fire/FlareInspection';
import {
  FreeFormRectangle,
  KonvaRectangle,
} from 'components/KonvaShapes/FreeFormRectangle/FreeFormRectangle';
import { FreeFormRectanglesOptions } from 'components/KonvaShapes/FreeFormRectangle/FreeFormRectangleOptions';
import { toggleSelect } from 'components/KonvaShapes/helpers';
import { ZoneModes } from 'components/KonvaShapes/types';

type FreeFormRectanglesProps = {
  width: number;
  height: number;
  settings: FlareInspectionSettings;
  setSettings: Dispatch<SetStateAction<FlareInspectionSettings>>;
};

const FreeFormRectangles = ({
  width,
  height,
  settings,
  setSettings,
}: FreeFormRectanglesProps) => {
  const originalWidth = useRef(width);
  const originalHeight = useRef(height);
  const stageRef = useRef<null | Konva.Stage>(null);
  const [annotations, setAnnotations] = useState<KonvaRectangle[]>([]);
  const [newAnnotation, setNewAnnotation] = useState<KonvaRectangle[]>([]);
  const [selectedZones, setSelectedZones] = useState<number[]>([]);
  const [mode, setMode] = useState(ZoneModes.DRAW);

  let scaleX = 1;
  let scaleY = 1;

  if (width && originalWidth.current) {
    scaleX = width / originalWidth.current;
  }

  if (height && originalHeight.current) {
    scaleY = height / originalHeight.current;
  }

  useEffect(() => {
    const savedRectangles = settings.monitoringZones.map((zone) =>
      convertToKonvaShape(zone, width, height, scaleX, scaleY)
    );

    setAnnotations(savedRectangles);
    //eslint-disable-next-line
  }, [settings]);

  const handleMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
    const { clickOnShape, shapeIndex } = isMouseOverShape(stageRef);

    if (mode === ZoneModes.SELECT && clickOnShape && shapeIndex !== undefined) {
      toggleSelect(shapeIndex, selectedZones, setSelectedZones);
    } else if (mode === ZoneModes.DRAW) {
      if (clickOnShape) return;
      if (annotations.length >= 5) return;

      const pointerPosition = event.target.getStage()!.getPointerPosition();
      if (newAnnotation.length === 0 && pointerPosition) {
        const { x, y } = pointerPosition;
        setNewAnnotation([
          { id: null, x: x / scaleX, y: y / scaleY, width: 0, height: 0 },
        ]);
      }
    }
  };

  const handleMouseUp = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (mode === ZoneModes.SELECT) return;

    const isIntersection = checkIntersections(event);

    if (isIntersection) {
      setNewAnnotation([]);
      return;
    }

    if (newAnnotation.length === 1) {
      const annotationToAdd = generateNewAnnotation(
        newAnnotation,
        event,
        scaleX,
        scaleY
      );
      if (!annotationToAdd) return;

      if (
        Math.abs(annotationToAdd.width) > 30 &&
        Math.abs(annotationToAdd.height) > 30
      ) {
        annotations.push(annotationToAdd);
        setAnnotations(annotations);
        const convertedAnnotations = annotations.map((annotation) =>
          convertToApiFormat(annotation, width, height, scaleX, scaleY)
        );
        setSettings({ ...settings, monitoringZones: convertedAnnotations });
      }
      setNewAnnotation([]);
    }
  };

  const handleMouseMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (mode === ZoneModes.SELECT) return;

    if (newAnnotation.length === 1) {
      const annotationToAdd = generateNewAnnotation(
        newAnnotation,
        event,
        scaleX,
        scaleY
      );
      if (!annotationToAdd) return;

      setNewAnnotation([annotationToAdd]);
    }
  };

  const handleRemoveAll = () => {
    setSettings({ ...settings, monitoringZones: [] });
    setSelectedZones([]);
  };

  const handleRemoveSelected = () => {
    if (isEmpty(selectedZones)) return;

    const zonesCopy = [...annotations];
    const filtered = zonesCopy.filter((zone, index) => {
      return !selectedZones.includes(index);
    });
    const convertedAnnotations = filtered.map((annotation) =>
      convertToApiFormat(annotation, width, height, scaleX, scaleY)
    );
    setSelectedZones([]);
    setSettings({ ...settings, monitoringZones: convertedAnnotations });
  };

  const annotationsToDraw = [...annotations, ...newAnnotation];

  return (
    <>
      <Stage
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        width={width}
        height={height}
        ref={stageRef}
        scaleX={scaleX}
        scaleY={scaleY}
      >
        <Layer>
          {annotationsToDraw.map((value, index) => {
            const isSelected = selectedZones.find(
              (element) => element === index
            );
            return (
              <FreeFormRectangle
                key={index}
                rect={value}
                index={index}
                stageRef={stageRef}
                settings={settings}
                setSettings={setSettings}
                annotations={annotations}
                newAnnotation={newAnnotation}
                width={width}
                height={height}
                scaleX={scaleX}
                scaleY={scaleY}
                selected={isSelected !== undefined}
                mode={mode}
              />
            );
          })}
        </Layer>
      </Stage>
      <FreeFormRectanglesOptions
        handleRemoveAll={handleRemoveAll}
        handleRemoveSelected={handleRemoveSelected}
        zones={annotations}
        selectedZones={selectedZones}
        mode={mode}
        setMode={setMode}
        setSelectedZones={setSelectedZones}
      />
    </>
  );
};

export default FreeFormRectangles;
