import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';
import { Rect } from 'react-konva';
import Konva from 'konva';
import { isEmpty } from 'lodash';
import {
  checkIntersections,
  convertToApiFormat,
  dragBoundFunction,
  updateAnnotationsOnDragEnd,
} from 'components/KonvaShapes/FreeFormRectangle/utils';
import {
  activeStrokeColor,
  fillColor,
  selectedFillColor,
  strokeColor,
} from 'components/KonvaShapes/constants';
import { shapeConfig } from 'components/KonvaShapes/FreeFormRectangle/constants';
import { FlareInspectionSettings } from 'types/analytics/fire/FlareInspection';
import { ZoneModes } from 'components/KonvaShapes/types';

export type KonvaRectangle = {
  id: string | null;
  x: number;
  y: number;
  width: number;
  height: number;
};

type FreeFormRectangleProps = {
  rect: KonvaRectangle;
  index: number;
  stageRef: MutableRefObject<Konva.Stage | null>;
  settings: FlareInspectionSettings;
  setSettings: Dispatch<SetStateAction<FlareInspectionSettings>>;
  annotations: KonvaRectangle[];
  newAnnotation: KonvaRectangle[];
  width: number;
  height: number;
  scaleX: number;
  scaleY: number;
  selected?: boolean;
  mode: ZoneModes;
};

export const FreeFormRectangle = ({
  rect,
  index,
  stageRef,
  settings,
  setSettings,
  annotations,
  newAnnotation,
  width,
  height,
  scaleX,
  scaleY,
  selected,
  mode,
}: FreeFormRectangleProps) => {
  const [originalDragPosition, setOriginalDragPosition] = useState({
    x: 0,
    y: 0,
  });

  const handleDragStart = (event: Konva.KonvaEventObject<DragEvent>) => {
    if (mode === ZoneModes.SELECT) return;
    setOriginalDragPosition(event.target.position());
  };

  const handleDragEnd = (event: Konva.KonvaEventObject<DragEvent>) => {
    if (mode === ZoneModes.SELECT) return;
    const isIntersection = checkIntersections(event);

    if (isIntersection) {
      event.target.setPosition(originalDragPosition);
      setOriginalDragPosition({ x: 0, y: 0 });
      return;
    }

    const updatedAnnotations = updateAnnotationsOnDragEnd(
      event,
      index,
      annotations
    );
    const convertedAnnotations = updatedAnnotations.map((annotation) =>
      convertToApiFormat(annotation, width, height, scaleX, scaleY)
    );
    setSettings({ ...settings, monitoringZones: convertedAnnotations });
  };

  const handleMouseOver = (id: string | null) => {
    if (id || !stageRef.current) {
      return;
    }
    if (!isEmpty(newAnnotation)) return;

    const container = stageRef.current.container();
    container.style.cursor = 'move';
  };

  const handleMouseOut = (id: string | null) => {
    if (id || !stageRef.current) {
      return;
    }
    const container = stageRef.current.container();
    container.style.cursor = 'default';
  };

  return (
    <Rect
      x={rect.x}
      y={rect.y}
      width={rect.width}
      height={rect.height}
      key={rect.x}
      draggable={!rect.id && mode === ZoneModes.DRAW}
      onDragStart={(event) => handleDragStart(event)}
      onDragEnd={(event) => handleDragEnd(event)}
      onMouseOver={() => handleMouseOver(rect.id)}
      onMouseOut={() => handleMouseOut(rect.id)}
      stroke={rect.id ? strokeColor : activeStrokeColor}
      fill={selected ? selectedFillColor : fillColor}
      {...shapeConfig}
      dragBoundFunc={(pos) => {
        return dragBoundFunction(rect, pos, width, height, scaleX, scaleY);
      }}
    />
  );
};
