import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type MenuItemProps = {
  name: string;
  path: string;
  icon: ReactNode;
  selected: boolean;
};

export const MenuItem = ({ name, path, icon, selected }: MenuItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ListItem
      // @ts-ignore
      component={RouterLink}
      className={classes.listItem}
      to={path}
      key={name}
      selected={selected}
      id={`${name}-menu-item`}
    >
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
      <ListItemText
        primary={t(`nav.${name}`)}
        classes={{ primary: classes.text }}
      />
    </ListItem>
  );
};

MenuItem.defaultProps = {
  component: RouterLink,
  target: undefined,
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    margin: theme.spacing(3.5, 0, 3.5, 0),
    height: '60px',
    color: theme.palette.primary.contrastText,
    border: `2px solid ${theme.palette.background.default}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },

    '&.Mui-selected': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
  },
  text: {
    lineHeight: 1.3,
    letterSpacing: '0.75px',
  },
  icon: {
    color: theme.palette.secondary.main,
    minWidth: '40px',
  },
}));
