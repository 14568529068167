import {
  createContext,
  useContext,
  FC,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';

export type StreamsAnalyticsContextValue = {
  savedAnalytics: { [key: string]: AnalyticsTypes };
  updateSavedAnalytics: (value: { [key: string]: AnalyticsTypes }) => void;
};

export type StreamsAnalyticsProviderProps = {
  children: ReactNode;
};

const defaultValue: StreamsAnalyticsContextValue = {
  savedAnalytics: {},
  updateSavedAnalytics: () => {},
};

const StreamsAnalyticsContext = createContext(defaultValue);

const StreamsAnalyticsProvider: FC<StreamsAnalyticsProviderProps> = (
  props: StreamsAnalyticsProviderProps
) => {
  const [savedAnalytics, setSavedAnalytics] = useState(
    defaultValue.savedAnalytics
  );

  const updateSavedAnalytics = useCallback(
    (value: { [key: string]: AnalyticsTypes }) => {
      localStorage.setItem('analyticsPerStream', JSON.stringify(value));
      setSavedAnalytics(value);
    },
    []
  );

  useEffect(() => {
    const storageAnalytics = localStorage.getItem('analyticsPerStream');
    if (storageAnalytics) {
      updateSavedAnalytics(JSON.parse(storageAnalytics));
    } else {
      updateSavedAnalytics({});
    }
  }, [updateSavedAnalytics]);

  return (
    <StreamsAnalyticsContext.Provider
      value={{
        savedAnalytics,
        updateSavedAnalytics,
      }}
      {...props}
    />
  );
};

const useStreamsAnalytics = () => useContext(StreamsAnalyticsContext);
export {
  StreamsAnalyticsProvider,
  StreamsAnalyticsContext,
  useStreamsAnalytics,
};
