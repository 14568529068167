import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SliderSteps from 'components/Slider/SliderSteps';
import { sliderMarks } from 'utils/constants';
import { PPESettings } from 'types/analytics/PPE';

type PPEThresholdsProps = {
  settings: PPESettings;
  setSettings: Dispatch<SetStateAction<PPESettings>>;
};

export const PPEThresholds = ({
  settings,
  setSettings,
}: PPEThresholdsProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [detectorThreshold, setDetectorThreshold] = useState(
    settings.detectorThreshold
  );
  const [hardHatThreshold, setHardHatThreshold] = useState(
    settings.hardHatThreshold
  );
  const [vestThreshold, setVestThreshold] = useState(settings.vestThreshold);

  const commitThreshold = (
    event: ChangeEvent<unknown>,
    value: number | number[],
    name: string
  ) => {
    if (typeof value !== 'number') return;

    setSettings({ ...settings, [name]: value });
  };

  return (
    <div className={classes.settingsContainer}>
      <div>
        <Typography className={classes.heading} align="center" variant="h3">
          {t('stream-settings.thresholds')}
        </Typography>
        <SliderSteps
          name="detectorThreshold"
          marks={sliderMarks}
          value={detectorThreshold}
          setValue={setDetectorThreshold}
          label={t('stream-settings.ppe.person-confidence')}
          onChangeCommitted={(event, value) =>
            commitThreshold(event, value, 'detectorThreshold')
          }
        />
        <SliderSteps
          name="hardHatThreshold"
          marks={sliderMarks}
          value={hardHatThreshold}
          setValue={setHardHatThreshold}
          label={t('stream-settings.ppe.helmet-confidence')}
          onChangeCommitted={(event, value) =>
            commitThreshold(event, value, 'hardHatThreshold')
          }
        />
        <SliderSteps
          name="vestThreshold"
          marks={sliderMarks}
          value={vestThreshold}
          setValue={setVestThreshold}
          label={t('stream-settings.ppe.vest-confidence')}
          onChangeCommitted={(event, value) =>
            commitThreshold(event, value, 'vestThreshold')
          }
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  settingsContainer: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(5),
    maxWidth: '400px',
    minWidth: '300px',
    flex: 1,
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}));
