import * as Yup from 'yup';
import i18n from 'translations/i18n';
import { passReg } from 'utils/constants';

export const addUserValidationSchema = (password: string) => {
  const validatePasswordMatch = (passwordReapeat: string | undefined) => {
    return passwordReapeat === password;
  };

  return Yup.object().shape({
    firstName: Yup.string().required(i18n.t('validation.required-field')),
    lastName: Yup.string().required(i18n.t('validation.required-field')),
    email: Yup.string()
      .email(i18n.t('validation.invalid-email'))
      .required(i18n.t('validation.required-field')),
    password: Yup.string()
      .matches(passReg, i18n.t('validation.invalid-password'))
      .required(i18n.t('validation.required-field')),
    passwordRepeat: Yup.string()
      .required(i18n.t('validation.required-field'))
      .test(
        'Check if password matches',
        i18n.t('validation.invalid-passwordRepeat'),
        validatePasswordMatch
      ),
  });
};
