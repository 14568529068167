import Konva from 'konva';
import { MutableRefObject } from 'react';
import { MonitoringZone } from 'types/analytics/fire/FlareInspection';
import {
  normalizeValue01,
  normalizeValuePX,
} from 'components/KonvaShapes/helpers';
import { KonvaRectangle } from 'components/KonvaShapes/FreeFormRectangle/FreeFormRectangle';
import { shapeConfig } from 'components/KonvaShapes/FreeFormRectangle/constants';
import { Vector2d } from 'konva/lib/types';
import { cloneDeep } from 'lodash';

export const isMouseOverShape = (
  stageRef: MutableRefObject<Konva.Stage | null>
) => {
  if (!stageRef.current) {
    return { clickOnShape: false, shapeIndex: undefined };
  }

  // getIntersection method needs pointerPosition without scale applied
  const pointerPosition = stageRef.current.getPointerPosition();
  const shape =
    pointerPosition && stageRef.current.getIntersection(pointerPosition);

  const isMouseOverRect = shape?.className === 'Rect';

  return {
    clickOnShape: isMouseOverRect,
    shapeIndex: shape?.index,
  };
};

export const convertToKonvaShape = (
  apiZone: MonitoringZone,
  width: number,
  height: number,
  scaleX: number,
  scaleY: number
) => {
  const topLeftX = apiZone.zoneRectangle[0].x;
  const topLeftY = apiZone.zoneRectangle[0].y;
  const bottomRightX = apiZone.zoneRectangle[1].x;
  const bottomRightY = apiZone.zoneRectangle[1].y;

  const topLeft = normalizeValuePX(
    { x: topLeftX, y: topLeftY },
    width,
    height,
    scaleX,
    scaleY
  );
  const bottomRight = normalizeValuePX(
    { x: bottomRightX, y: bottomRightY },
    width,
    height,
    scaleX,
    scaleY
  );

  const rectWidth = bottomRight.x - topLeft.x;
  const rectHeight = bottomRight.y - topLeft.y;

  return {
    id: apiZone.id,
    x: topLeft.x,
    y: topLeft.y,
    width: rectWidth,
    height: rectHeight,
  };
};

enum RectangleCorners {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

const chooseActiveCorner = (konvaRectangle: KonvaRectangle) => {
  if (konvaRectangle.width < 0 && konvaRectangle.height < 0) {
    return RectangleCorners.BOTTOM_RIGHT;
  } else if (konvaRectangle.height < 0) {
    return RectangleCorners.BOTTOM_LEFT;
  } else if (konvaRectangle.width < 0) {
    return RectangleCorners.TOP_RIGHT;
  } else {
    return RectangleCorners.TOP_LEFT;
  }
};

const calcTopLeftCorner = (
  konvaRectangle: KonvaRectangle,
  activeCorner: RectangleCorners
) => {
  switch (activeCorner) {
    case RectangleCorners.TOP_LEFT:
      return { x: konvaRectangle.x, y: konvaRectangle.y };
    case RectangleCorners.TOP_RIGHT:
      return {
        x: konvaRectangle.x - Math.abs(konvaRectangle.width),
        y: konvaRectangle.y,
      };
    case RectangleCorners.BOTTOM_LEFT:
      return {
        x: konvaRectangle.x,
        y: konvaRectangle.y - Math.abs(konvaRectangle.height),
      };
    case RectangleCorners.BOTTOM_RIGHT:
      return {
        x: konvaRectangle.x - Math.abs(konvaRectangle.width),
        y: konvaRectangle.y - Math.abs(konvaRectangle.height),
      };
  }
};

export const convertToApiFormat = (
  konvaRectangle: KonvaRectangle,
  width: number,
  height: number,
  scaleX: number,
  scaleY: number
) => {
  const activeCorner = chooseActiveCorner(konvaRectangle);

  const topLeftCorner = calcTopLeftCorner(konvaRectangle, activeCorner);
  const bottomRightCorner = {
    x: topLeftCorner.x + Math.abs(konvaRectangle.width),
    y: topLeftCorner.y + Math.abs(konvaRectangle.height),
  };

  const topLeftNormalized = normalizeValue01(
    topLeftCorner,
    width,
    height,
    scaleX,
    scaleY
  );
  const bottomRightNormalized = normalizeValue01(
    bottomRightCorner,
    width,
    height,
    scaleX,
    scaleY
  );

  return {
    id: konvaRectangle.id || null,
    zoneRectangle: [topLeftNormalized, bottomRightNormalized],
  };
};

const convertToAbsoluteRect = (rectangle: Konva.Stage | Konva.Shape) => {
  let x = rectangle.attrs.x;
  let y = rectangle.attrs.y;
  let width = rectangle.attrs.width;
  let height = rectangle.attrs.height;

  if (width < 0) {
    x = x - Math.abs(width);
    width = Math.abs(width);
  }
  if (height < 0) {
    y = y - Math.abs(height);
    height = Math.abs(height);
  }

  return {
    x,
    y,
    width,
    height,
  };
};

export const rectanglesIntersect = (
  rect1: Konva.Rect,
  target: Konva.Stage | Konva.Shape
) => {
  const rect2 = convertToAbsoluteRect(target);

  return !(
    rect2.x > rect1.attrs.x + rect1.attrs.width ||
    rect2.x + rect2.width < rect1.attrs.x ||
    rect2.y > rect1.attrs.y + rect1.attrs.height ||
    rect2.y + rect2.height < rect1.attrs.y
  );
};

export const checkIntersections = (
  event: Konva.KonvaEventObject<DragEvent> | Konva.KonvaEventObject<MouseEvent>
) => {
  let isIntersection = false;

  if (event.target.nodeType === 'Shape') {
    const target = event.target;
    const rectangles = event.target.getLayer().children;

    rectangles.forEach(function (rectangle: Konva.Rect) {
      if (rectangle === target) {
        return;
      }
      if (rectanglesIntersect(rectangle, target)) {
        isIntersection = true;
      }
    });
  }

  return isIntersection;
};

export const dragBoundFunction = (
  rect: KonvaRectangle,
  pos: Vector2d,
  width: number,
  height: number,
  scaleX: number,
  scaleY: number
) => {
  const currentWidth = rect.width * scaleX;
  const currentHeight = rect.height * scaleY;

  let x = pos.x;
  let y = pos.y;

  x = Math.max(pos.x, shapeConfig.strokeWidth);
  x = Math.min(x, width - currentWidth - shapeConfig.strokeWidth);

  y = Math.max(pos.y, shapeConfig.strokeWidth);
  y = Math.min(y, height - currentHeight - shapeConfig.strokeWidth);

  return {
    x,
    y,
  };
};

export const generateNewAnnotation = (
  newAnnotation: KonvaRectangle[],
  event: Konva.KonvaEventObject<MouseEvent>,
  scaleX: number,
  scaleY: number
) => {
  let annotationToAdd;
  const sx = newAnnotation[0].x;
  const sy = newAnnotation[0].y;
  const pointerPosition = event.target.getStage()!.getPointerPosition();
  if (pointerPosition) {
    const { x, y } = pointerPosition;
    annotationToAdd = {
      id: null,
      x: sx,
      y: sy,
      width: x / scaleX - sx,
      height: y / scaleY - sy,
    };
  }
  return annotationToAdd;
};

export const updateAnnotationsOnDragEnd = (
  event: Konva.KonvaEventObject<DragEvent>,
  index: number,
  annotations: KonvaRectangle[]
) => {
  const newX = event.target.attrs.x;
  const newY = event.target.attrs.y;

  const annotationsCopy = cloneDeep(annotations);
  const movedRectangle = cloneDeep(annotations[index]);

  annotationsCopy.splice(index, 1, { ...movedRectangle, x: newX, y: newY });

  return annotationsCopy;
};
