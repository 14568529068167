import { Tooltip, ValueLabelProps, makeStyles } from '@material-ui/core';

export const ValueLabelComponent = (props: ValueLabelProps) => {
  const { children, open, value } = props;
  const classes = useStyles();

  return (
    <Tooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={<span className={classes.tooltipValue}>{value}</span>}
      classes={{
        tooltip: classes.customTooltip,
        popper: classes.popper,
      }}
    >
      {children}
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: theme.palette.primary.main,
  },
  popper: {
    zIndex: 100,
  },
  tooltipValue: {
    fontSize: '0.75rem',
    fontWeight: 400,
  },
}));
