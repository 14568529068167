import { AnalyticsTypes } from 'context/providers/AnalyticsProvider';
import { Zone } from 'types/analyticsTypes';

export type PPESettings = {
  type: AnalyticsTypes;
  detectorThreshold: number;
  vestThreshold: number;
  hardHatThreshold: number;
  exclusionZones: Zone[];
  inclusionZones: Zone[];
  trackedItems: {
    isHardHatTracked: boolean;
    isVestTracked: boolean;
  };
};

export type PPEStreamData = {
  numberOfMissingVests: number;
  numberOfMissingHelmets: number;
  numberOfPeopleOnFrame: number;
};

export enum TrackedItems {
  VEST = 'Vest',
  HARD_HAT = 'HardHat',
}
