import clsx from 'clsx';
import { Button, makeStyles, ButtonProps } from '@material-ui/core';

const ButtonBase = ({
  children,
  className,
  onClick,
  disabled,
  ...props
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      color="primary"
      className={clsx(className, classes.button, {
        [classes.disabledButton]: disabled,
      })}
      classes={{
        label: clsx(classes.label, {
          [classes.disabled]: disabled,
        }),
      }}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 2, 1, 2),
    height: '46px',
    maxHeight: '46px',
    whiteSpace: 'nowrap',
    border: 'none',
    '&:hover': {
      border: 'none',
      backgroundColor: theme.palette.secondary.hover,
    },
  },
  disabledButton: {
    pointerEvents: 'none',
  },
  label: {
    color: theme.palette.primary.darkmode,
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '0.5px',
    lineHeight: '30px',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}));

export default ButtonBase;
