import { ConfigProvider } from 'antd';
import { ReactNode } from 'react';
import theme from 'theme';

type AntdConfigProviderProps = {
  children: ReactNode;
};

export const AntdConfigProvider = ({ children }: AntdConfigProviderProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.palette.primary.main,
          controlItemBgActive: theme.palette.secondary.background,
        },
        components: {
          DatePicker: {
            borderRadius: 4,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
