import { useState, Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import DialogBase from 'components/Dialog/Dialog';
import { AddUserForm } from 'views/UsersView/AddUser/AddUserForm';
import { createUser } from 'api/usersApi';
import { addUserValidationSchema } from 'validation/addUserValidationSchema';
import { AlertSeverity } from 'components/Alert/Toast';
import { UserRole } from 'types/authTypes';

type AddUserDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchUsers: () => void;
  setFeedback: Dispatch<
    SetStateAction<{ open: boolean; status: AlertSeverity; msg: string }>
  >;
};

export type AddUserValues = {
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  password: string;
  passwordRepeat: string;
  mustChangePassword: boolean;
};

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  role: UserRole.ADMINISTRATOR,
  password: '',
  passwordRepeat: '',
  mustChangePassword: false,
};

export const AddUserDialog = ({
  onClose,
  fetchUsers,
  setFeedback,
  ...props
}: AddUserDialogProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: AddUserValues) => {
      setFeedback({ open: false, status: AlertSeverity.SUCCESS, msg: '' });
      setLoading(true);
      const apiValues = { ...values };
      // @ts-ignore
      delete apiValues.passwordRepeat;
      createUser(apiValues)
        .then(() => {
          handleClose();
          fetchUsers();
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.SUCCESS,
            msg: 'add-user-success',
          });
        })
        .catch((error) => {
          setLoading(false);
          setFeedback({
            open: true,
            status: AlertSeverity.ERROR,
            msg: error.response.data.description
              ? error.response.data.description
              : 'general-error',
          });
        });
    },
    validationSchema: addUserValidationSchema(password),
  });

  return (
    <DialogBase
      title={t('users.add')}
      className={classes.dialog}
      onClose={handleClose}
      id="add-user-dialog"
      disabled={loading}
      {...props}
    >
      <AddUserForm
        formik={formik}
        loading={loading}
        handleClose={handleClose}
        setPassword={setPassword}
      />
    </DialogBase>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    width: '760px',
    maxWidth: '760px',
    minWidth: '300px',
  },
}));
