import { IconButton, Tooltip, makeStyles } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Stream } from 'types/streamTypes';

type PreviewButtonProps = {
  stream: Stream;
};

export const PreviewButton = ({ stream }: PreviewButtonProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    navigate(`/streams/${stream.id}`);
  };

  return (
    <>
      <Tooltip title={t('streams.preview')}>
        <IconButton
          className={classes.actionsButton}
          onClick={handleClick}
          id={`${stream.id}-preview-button`}
        >
          <VisibilityOutlinedIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsButton: {
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  },
}));
