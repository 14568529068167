import { FocusEvent, ChangeEvent, Dispatch, SetStateAction } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import DialogActions from 'components/Dialog/DialogActions';
import TextFieldBase from 'components/Input/TextField/TextFieldBase';
import PasswordInput from 'components/Input/TextField/PasswordInput';
import SelectBase from 'components/Input/Select/SelectBase';
import CheckboxWithLabel from 'components/Checkbox/CheckboxWithLabel';
import { handleBlurAndTrim, capitalizeOnChange } from 'utils/helpers';
import { UserRole } from 'types/authTypes';
import { AddUserValues } from 'views/UsersView/AddUser/AddUserDialog';

type AddUserFormType = {
  formik: FormikContextType<AddUserValues>;
  loading: boolean;
  handleClose: () => void;
  setPassword: Dispatch<SetStateAction<string>>;
};

export const AddUserForm = ({
  formik,
  loading,
  handleClose,
  setPassword,
}: AddUserFormType) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    formik.handleChange(event);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={classes.container}>
        <div className={classes.inputColumn}>
          <TextFieldBase
            required
            name="firstName"
            id="user-firstName"
            maxLength={255}
            disabled={loading}
            value={formik.values.firstName}
            label={t('users.firstName')}
            className={classes.input}
            onChange={(event) => capitalizeOnChange(event, formik)}
            onBlur={(event) => handleBlurAndTrim(event, formik)}
            error={Boolean(formik.errors.firstName && formik.touched.firstName)}
            helperText={
              formik.errors.firstName && formik.touched.firstName
                ? formik.errors.firstName
                : null
            }
          />
          <TextFieldBase
            required
            name="lastName"
            id="user-lastName"
            maxLength={255}
            disabled={loading}
            value={formik.values.lastName}
            label={t('users.lastName')}
            className={classes.input}
            onChange={(event) => capitalizeOnChange(event, formik)}
            onBlur={(event) => handleBlurAndTrim(event, formik)}
            error={Boolean(formik.errors.lastName && formik.touched.lastName)}
            helperText={
              formik.errors.lastName && formik.touched.lastName
                ? formik.errors.lastName
                : null
            }
          />
          <TextFieldBase
            required
            name="email"
            id="user-email"
            maxLength={255}
            disabled={loading}
            value={formik.values.email}
            label={t('email')}
            className={classes.input}
            onChange={formik.handleChange}
            onBlur={(event) => handleBlurAndTrim(event, formik)}
            error={Boolean(formik.errors.email && formik.touched.email)}
            helperText={
              formik.errors.email && formik.touched.email
                ? formik.errors.email
                : null
            }
          />
          <SelectBase
            name="role"
            id="user-role"
            items={Object.values(UserRole)}
            disabled={loading}
            value={formik.values.role}
            label={t('users.role')}
            className={classes.input}
            onChange={formik.handleChange}
          />
        </div>
        <div className={classes.inputColumn}>
          <PasswordInput
            required
            name="password"
            id="user-password"
            maxLength={255}
            disabled={loading}
            value={formik.values.password}
            label={t('password')}
            className={classes.input}
            onChange={handlePasswordChange}
            onBlur={(
              event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => handleBlurAndTrim(event, formik)}
            error={Boolean(formik.errors.password && formik.touched.password)}
            helperText={
              formik.errors.password && formik.touched.password
                ? formik.errors.password
                : t('validation.invalid-password')
            }
          />
          <PasswordInput
            required
            name="passwordRepeat"
            id="user-passwordRepeat"
            maxLength={255}
            disabled={loading}
            value={formik.values.passwordRepeat}
            label={t('repeat-password')}
            className={classes.input}
            onChange={formik.handleChange}
            onBlur={(
              event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => handleBlurAndTrim(event, formik)}
            error={Boolean(
              formik.errors.passwordRepeat && formik.touched.passwordRepeat
            )}
            helperText={
              formik.errors.passwordRepeat && formik.touched.passwordRepeat
                ? formik.errors.passwordRepeat
                : null
            }
          />
          <CheckboxWithLabel
            name="mustChangePassword"
            label={t('users.must-change-password')}
            disabled={loading}
            value={formik.values.mustChangePassword}
            checked={formik.values.mustChangePassword}
            onChange={formik.handleChange}
            className={classes.input}
          />
        </div>
      </div>
      <DialogActions
        onCancel={handleClose}
        isLoading={loading}
        isValid={formik.dirty && formik.isValid}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',

    '@media (max-width:600px)': {
      flexDirection: 'column',
    },
  },
  inputColumn: {
    width: '48%',

    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  input: {
    marginBottom: theme.spacing(3),
  },
}));
