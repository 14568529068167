import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SubmitButton from 'components/Button/SubmitButton';
import CancelButton from 'components/Button/CancelButton';

type DialogActionsProps = {
  isLoading: boolean;
  onCancel: () => void;
  onSubmit?: () => void;
  isValid: boolean;
};

const DialogActions = ({
  isLoading,
  onCancel,
  onSubmit,
  isValid,
}: DialogActionsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.dialogActions}>
      <CancelButton
        id="cancel-button"
        className={classes.cancelButton}
        onClick={onCancel}
        disabled={isLoading}
      >
        {t('cancel')}
      </CancelButton>
      <SubmitButton
        type="submit"
        id="submit-button"
        onClick={onSubmit ? onSubmit : undefined}
        disabled={isLoading || !isValid}
        isLoading={isLoading}
        className={classes.submitButton}
      >
        {t('submit')}
      </SubmitButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  submitButton: {
    minWidth: '113px',
  },
}));

export default DialogActions;
