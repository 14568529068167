import { Button, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'context/providers/AuthProvider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export const LogoutButton = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <>
      <Button
        startIcon={<ExitToAppIcon className={classes.icon} />}
        className={classes.logout}
        onClick={handleLogout}
        id="logout-button"
      >
        {t('logout')}
      </Button>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  logout: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.background.default,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2, 1, 2),

    '&:hover': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
  icon: {
    color: theme.palette.secondary.main,
  },
}));
