import { useTranslation } from 'react-i18next';
import CheckIcon from '@material-ui/icons/Check';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useAuth } from 'context/providers/AuthProvider';

type Item = {
  id: string;
  label: string;
  icon: JSX.Element;
  active: boolean;
  setActive: (label: string, active: boolean) => void;
};

type TrackingItemProps = {
  item: Item;
};

export const TrackingItem = ({ item }: TrackingItemProps) => {
  const { t } = useTranslation();
  const { isGuest } = useAuth();
  const classes = useStyles({ isGuest });

  const { id, active, setActive, label, icon } = item;

  const handleClick = () => {
    if (isGuest) {
      return;
    }
    setActive(id, !active);
  };

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(classes.item, { [classes.activeItem]: active })}
        onClick={handleClick}
      >
        <div className={classes.iconContainer}>{icon}</div>
        <Typography className={classes.label}>{t(label)}</Typography>
      </div>
      <div>
        {
          <CheckIcon
            className={clsx(classes.checkIcon, {
              [classes.activeIcon]: active,
            })}
          />
        }
      </div>
    </div>
  );
};

type StyleProps = {
  isGuest: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'fit-content',
    margin: theme.spacing(3),
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70px',
    height: '70px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    cursor: ({ isGuest }) => (isGuest ? 'default' : 'pointer'),
    padding: theme.spacing(1.2),
    marginBottom: theme.spacing(1),
    opacity: 0.3,
    '& svg': {
      fill: theme.palette.common.white,
      width: '24px',
      maxWidth: '24px',
    },
    '& path': {
      fill: theme.palette.common.white,
    },
  },
  activeItem: {
    opacity: 1,
  },
  label: {
    fontSize: '11px',
    lineHeight: '12px',
    marginTop: 'auto',
    textTransform: 'uppercase',
  },
  checkIcon: {
    fill: theme.palette.background.default,
  },
  activeIcon: {
    fill: theme.palette.secondary.main,
  },
  iconContainer: {
    display: 'flex',
    height: '24px',
    alignItems: 'center',
  },
}));
