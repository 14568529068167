import {
  Dispatch,
  RefObject,
  SetStateAction,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { getStreamPreview } from 'api/streamApi';
import { JPGPreview } from 'views/StreamDetails/Preview/JPGPreview';
import { intervals } from 'views/StreamDetails/Preview/constants';
import { useAnalyticsSettings } from 'context/providers/AnalyticsSettingsProvider';
import { useStreamData } from 'context/providers/StreamDataProvider';

type JPGPreviewWithFetchProps = {
  className?: string;
  ref: RefObject<HTMLDivElement>;
  previewLoading: boolean;
  setPreviewLoading: Dispatch<SetStateAction<boolean>>;
  streamId: string | undefined;
};

export const JPGPreviewWithFetch = forwardRef<
  HTMLDivElement,
  JPGPreviewWithFetchProps
>(({ setPreviewLoading, previewLoading, streamId, ...props }, ref) => {
  const { selectedAnalytics } = useStreamData();
  const [frame, setFrame] = useState('');
  const { analyticsSettings } = useAnalyticsSettings();

  const fetchConditionsMet = streamId && selectedAnalytics && analyticsSettings;

  useEffect(() => {
    if (!fetchConditionsMet) return;
    getStreamPreview(streamId, selectedAnalytics, analyticsSettings)
      .then((res) => {
        setFrame(res);
        setPreviewLoading(false);
      })
      .catch(() => {
        setPreviewLoading(false);
      });
  }, [
    selectedAnalytics,
    streamId,
    fetchConditionsMet,
    setPreviewLoading,
    analyticsSettings,
  ]);

  useEffect(() => {
    let continueRequests = true;
    const fetchPreview = () => {
      if (!fetchConditionsMet || !continueRequests) {
        return;
      }
      const startTimestamp = Date.now();
      getStreamPreview(streamId, selectedAnalytics, analyticsSettings, false)
        .then((res) => {
          setFrame(res);
        })
        .catch(() => {})
        .finally(() => {
          const currentTimestamp = Date.now();
          const timeout = Math.max(
            0,
            intervals[3].value - (currentTimestamp - startTimestamp)
          );
          window.setTimeout(fetchPreview, timeout);
        });
    };
    fetchPreview();
    return () => {
      continueRequests = false;
    };
  }, [streamId, selectedAnalytics, fetchConditionsMet, analyticsSettings]);

  return (
    <JPGPreview frame={frame} loading={previewLoading} {...props} ref={ref} />
  );
});
